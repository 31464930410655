import { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinearProgress from "@mui/material/LinearProgress";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Typography from "@mui/material/Typography";
import { getDataHouseHoldByIndex } from "../../api/DataAPI";
import { formatDateFromAPI } from "../tools/ConvertData";
import "../../css/CustomStyle.css";
const ModalComponent = ({
  open = false,
  index = null,
  setOpenModal = null,
  setIndex = null,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = () => {
    setOpenModal(false);
    if (setIndex) {
      setIndex(null);
    }
  };

  const getData = useCallback(async () => {
    setIsLoading(true);
    const [apiIndexCase] = await Promise.all([getDataHouseHoldByIndex(index)]);
    setData(apiIndexCase[0]);
    setIsLoading(false);
  }, [index]);

  useEffect(() => {
    if (index) getData(index);
  }, [getData, index]);

  const calculateProgress = (total, goal) => {
    if (goal <= 0) return 0;
    const progress = (total / goal) * 100;
    return {
      calBar: Math.min(progress, 100),
      calPercent: progress,
    };
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        keepMounted={false}
      >
        <Box className="boxModal">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <h6 id="parent-modal-title">
            <b>{index}</b>
          </h6>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            data && (
              <>
                <Accordion defaultExpanded className="accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="accordion"
                  >
                    <b>PlanSite</b>
                  </AccordionSummary>
                  <AccordionDetails className="accordion">
                    <div className="containerModal">
                      <div className="rowModal">
                        <div className="columnModal">
                          Province: {data.p_site.province_name}
                        </div>
                        <div className="columnModal">
                          District: {data.p_site.district_name}
                        </div>
                        <div className="columnModal">
                          SubDistrict: {data.p_site.subdistrict_name}
                        </div>
                      </div>
                      <div className="rowModal">
                        <div className="columnModal">
                          Moo: {data.p_site.mu_name}
                        </div>
                        <div className="columnModal">
                          Village: {data.p_site.village_name}
                        </div>
                        <div className="columnModal">
                          PeopleType: {data.people_type}
                        </div>
                      </div>
                      <div className="rowModal">
                        <div className="columnModal">Age: {data.age}</div>
                        <div className="columnModal">
                          CaseClassification: {data.case_classification}
                        </div>
                        <div className="columnModal">
                          PlanSiteArea: {data.p_site_area}
                        </div>
                      </div>
                      <div className="rowModal">
                        <div className="columnModal">
                          BloodDrawDate: <br />
                          {formatDateFromAPI(data.blood_draw_date)}
                        </div>
                      </div>
                    </div>
                    <>
                      {data?.plan_site?.plan_event_list && (
                        <>
                          {data?.plan_site?.plan_event_list?.map((item) => (
                            <Accordion key={item.event_id}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="accordion"
                              >
                                {item.event_description}(
                                {`${Math.round(
                                  calculateProgress(
                                    item.total_task,
                                    item.event_goal,
                                  ).calPercent,
                                )}%`}
                                )
                                {Math.round(
                                  calculateProgress(
                                    item.total_task,
                                    item.event_goal,
                                  ).calPercent >= 100,
                                ) ? (
                                  <CheckCircleOutlineOutlinedIcon color="success" />
                                ) : (
                                  <WarningAmberOutlinedIcon color="warning" />
                                )}
                              </AccordionSummary>
                              <AccordionDetails className="accordion">
                                <div>EventId:{item.event_id}</div>
                                <div>
                                  Task & Goal:{item.total_task}/
                                  {item.event_goal}
                                </div>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box sx={{ width: "100%", mr: 1 }}>
                                    <LinearProgress
                                      variant="determinate"
                                      value={
                                        calculateProgress(
                                          item.total_task,
                                          item.event_goal,
                                        ).calBar
                                      }
                                      sx={{
                                        height: 10,
                                        backgroundColor: "#e0e0e0",
                                        "& .MuiLinearProgress-bar": {
                                          backgroundColor: "#4caf50",
                                        },
                                      }}
                                    />
                                  </Box>
                                  <Box sx={{ minWidth: 35 }}>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                      className="accordion"
                                    >
                                      {`${Math.round(
                                        calculateProgress(
                                          item.total_task,
                                          item.event_goal,
                                        ).calPercent,
                                      )}%`}
                                    </Typography>
                                  </Box>
                                </Box>
                                <div>Status:{item.status}</div>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </>
                      )}
                    </>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="accordion"
                  >
                    <b>SourceSite</b>
                  </AccordionSummary>
                  <AccordionDetails className="accordion">
                    <div className="containerModal">
                      <div className="rowModal">
                        <div className="columnModal">
                          Province: {data.source_site.province_name}
                        </div>
                        <div className="columnModal">
                          District: {data.source_site.district_name}
                        </div>
                        <div className="columnModal">
                          SubDistrict: {data.source_site.subdistrict_name}
                        </div>
                        {/* <p>Case Classification: {data.case_classification}</p>
                        <p>People Type: {data.people_type}</p> */}
                      </div>
                      <div className="rowModal">
                        <div className="columnModal">
                          Moo: {data.source_site.mu_name}
                        </div>
                        <div className="columnModal">
                          Village: {data.source_site.village_name}
                        </div>
                        <div className="columnModal">
                          SourceSiteArea: {data.source_site_area}
                        </div>
                      </div>
                    </div>
                    <>
                      {data?.source_site?.source_event_list && (
                        <>
                          {data?.source_site?.source_event_list?.map((item) => (
                            <Accordion key={item.event_id}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="accordion"
                              >
                                {item.event_description}(
                                {`${Math.round(
                                  calculateProgress(
                                    item.total_task,
                                    item.event_goal,
                                  ).calPercent,
                                )}%`}
                                )
                                {Math.round(
                                  calculateProgress(
                                    item.total_task,
                                    item.event_goal,
                                  ).calPercent >= 100,
                                ) ? (
                                  <CheckCircleOutlineOutlinedIcon color="success" />
                                ) : (
                                  <WarningAmberOutlinedIcon color="warning" />
                                )}
                              </AccordionSummary>
                              <AccordionDetails className="accordion">
                                <div>EventId:{item.event_id}</div>
                                {/* <p>event_description:{item.event_description}</p> */}
                                <div>
                                  Task & Goal:{item.total_task}/
                                  {item.event_goal}
                                </div>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box sx={{ width: "100%", mr: 1 }}>
                                    <LinearProgress
                                      variant="determinate"
                                      value={
                                        calculateProgress(
                                          item.total_task,
                                          item.event_goal,
                                        ).calBar
                                      }
                                      sx={{
                                        height: 10,
                                        backgroundColor: "#e0e0e0",
                                        "& .MuiLinearProgress-bar": {
                                          backgroundColor: "#4caf50",
                                        },
                                      }}
                                    />
                                  </Box>
                                  <Box sx={{ minWidth: 35 }}>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                      className="accordion"
                                    >
                                      {`${Math.round(
                                        calculateProgress(
                                          item.total_task,
                                          item.event_goal,
                                        ).calPercent,
                                      )}%`}
                                    </Typography>
                                  </Box>
                                </Box>
                                <div>Status:{item.status}</div>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </>
                      )}
                    </>
                  </AccordionDetails>
                </Accordion>
              </>
            )
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ModalComponent;
