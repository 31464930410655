import React from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Drawer from "@mui/material/Drawer";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import DrawerMenu from "../tools/DrawerMenu";
import CircularProgress from "@mui/material/CircularProgress";
import { CheckBoxChange } from "../tools/Tools";
const FilterMainComponent = ({
  showMenu,
  setShowMenu,
  showFilter,
  handleToggle,
  selectedYear,
  handleYearChange,
  years,
  minMonthCaption,
  maxMonthCaption,
  dataType,
  TypeValue,
  setTypeValue,
  dataAge,
  AgeValues,
  setAgeValues,
  dataArea,
  AreaValues,
  setAreaValues,
  dataFilter,
  dataLineage,
  setDataLineage,
  dataOrgType,
  OrgValue,
  setOrgValue,
  warning,
  handleConfirm,
  FilterLoading,
  handleClose,
  OpenSnackbar,
  errLocation,
  loadLocation,
}) => {
  return (
    <>
      <div>
        <DrawerMenu showMenu={showMenu} setShowMenu={setShowMenu} />
        <Drawer
          anchor="left"
          open={showFilter}
          onClose={handleToggle}
          // hideBackdrop={true}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              width: 380,
              backgroundColor: "rgba(245, 245, 245, 1)",
            },
          }}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CloseOutlinedIcon
                onClick={handleToggle}
                sx={{ fontSize: 40, color: "rgba(59,113,202, 1)" }}
              />
            </div>
            <div className="d-flex justify-content-center p-2">
              <div className="Filter">
                <div>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="yearSelect">Year</InputLabel>
                      <Select
                        label="Year"
                        value={selectedYear}
                        onChange={handleYearChange}
                        inputProps={{
                          name: "year",
                          id: "yearSelect",
                        }}
                      >
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>

                <Typography variant="subtitle1" gutterBottom>
                  <div>StartDate : {minMonthCaption}</div>
                  <div>EndDate : {maxMonthCaption}</div>
                </Typography>
                <div>
                  <b>Type</b>
                  <FormGroup row={true}>
                    {dataType.map((option) => (
                      <FormControlLabel
                        name={option.label}
                        label={option.label}
                        key={option.value}
                        control={
                          <Checkbox
                            defaultChecked={true}
                            onChange={(event) =>
                              CheckBoxChange(
                                event,
                                TypeValue,
                                setTypeValue,
                                dataType,
                              )
                            }
                            value={option.value}
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                </div>
                <div>
                  <b>Age</b>
                  <FormGroup row={true}>
                    {dataAge.map((option) => (
                      <FormControlLabel
                        name={option.label}
                        label={option.label}
                        key={option.value}
                        control={
                          <Checkbox
                            defaultChecked={true}
                            onChange={(event) =>
                              CheckBoxChange(
                                event,
                                AgeValues,
                                setAgeValues,
                                dataAge,
                              )
                            }
                            value={option.value}
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                </div>
                <div>
                  <b>Case Classification</b>
                  <FormGroup row={true}>
                    {dataArea.map((option) => (
                      <FormControlLabel
                        name={option.label}
                        label={option.label}
                        key={option.value}
                        control={
                          <Checkbox
                            defaultChecked={true}
                            onChange={(event) =>
                              CheckBoxChange(
                                event,
                                AreaValues,
                                setAreaValues,
                                dataArea,
                              )
                            }
                            value={option.value}
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                </div>
                {dataFilter && (
                  <div>
                    <b>Species</b>
                    <FormGroup row={true}>
                      {dataFilter.map((option) => (
                        <FormControlLabel
                          name={option.result_code_detail}
                          label={option.result_code_detail}
                          key={option.result_code_detail}
                          control={
                            <Checkbox
                              defaultChecked={true}
                              onChange={(event) =>
                                CheckBoxChange(
                                  event,
                                  dataLineage,
                                  setDataLineage,
                                  dataLineage,
                                )
                              }
                              value={option.result_code_detail}
                            />
                          }
                        />
                      ))}
                    </FormGroup>
                  </div>
                )}
                <div hidden={global.org_filter_hidden}>
                  <b>Org</b>
                  <FormGroup row={true}>
                    {dataOrgType.map((option) => (
                      <FormControlLabel
                        name={option.label}
                        label={option.label}
                        key={option.value}
                        control={
                          <Checkbox
                            disabled={true}
                            defaultChecked={option.check}
                            onChange={(event) =>
                              CheckBoxChange(
                                event,
                                OrgValue,
                                setOrgValue,
                                dataOrgType,
                              )
                            }
                            value={option.value}
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    id="search"
                    variant="contained"
                    color="success"
                    onClick={handleConfirm}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="Warning">
                {warning && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="warning">
                      This is a warning alert — Please select at least 1 option.
                    </Alert>
                  </Stack>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {FilterLoading && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              )}
            </div>
          </div>
        </Drawer>
      </div>
      <div className="Snackbar">
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={FilterLoading}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              sx={{ width: "100%" }}
              icon={<CircularProgress size={20} />}
              severity="info"
            >
              Loading...
            </Alert>
          </Snackbar>
        </Stack>
      </div>
      <div className="Snackbar">
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={OpenSnackbar}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Loading complete!!
            </Alert>
          </Snackbar>
        </Stack>
      </div>
      <div className="Snackbar">
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={errLocation}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              User denied Geolocation!!
            </Alert>
          </Snackbar>
        </Stack>
      </div>
      <div className="Snackbar">
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={loadLocation}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              sx={{ width: "100%" }}
              icon={<CircularProgress size={20} />}
              severity="info"
            >
              Loading your location...
            </Alert>
          </Snackbar>
        </Stack>
      </div>
    </>
  );
};

export default FilterMainComponent;
