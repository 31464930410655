import React from "react";
import Drawer from "@mui/material/Drawer";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NavbarComponent from "../../NavbarComponent";

const DrawerMenu = ({ showMenu, setShowMenu }) => {
  const handleToggle = () => {
    setShowMenu(!showMenu);
  };
  return (
    <Drawer
      anchor="top"
      open={showMenu}
      onClose={handleToggle}
      // hideBackdrop={true}
      ModalProps={{ keepMounted: true }}
      PaperProps={{
        sx: {
          backgroundColor: "rgba(245, 245, 245, 1)",
        },
      }}
    >
      <div
        style={{
          padding: 5,
          display: "flex",
          justifyContent: "center", // Center items horizontally
          alignItems: "center",
        }}
      >
        <NavbarComponent style={{ flexGrow: 1 }} />

        <div
          style={{
            display: "flex",
            marginLeft: "auto",
          }}
        >
          <CloseOutlinedIcon
            onClick={handleToggle}
            sx={{ fontSize: 40, color: "rgba(59,113,202, 1)" }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerMenu;
