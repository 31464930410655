import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";

const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#DC143C"];
const colors_case = ["#0055fe", "#00c45f", "#FFBB28", "#FF8042", "#DC143C"];

const Example = ({ data, year, Map, Side }) => {
  let colorShow;
  let SideChart;
  let revers = false;
  if (Side === "left") {
    colorShow = colors[0];
    SideChart = "right";
    revers = true;
  } else {
    colorShow = colors[1];
    SideChart = "left";
    revers = false;
  }

  const legendItems = [
    {
      value: "Year: " + year,
      type: "square",
      color: colorShow, // Assuming you want to use the first color from your colors array
    },
  ];
  const handleTooltipClick = (data, index) => {
    // Do something when tooltip is clicked
    // console.log("Tooltip clicked:", data, index);
    Map.flyTo({
      center: [data.Longitude, data.Latitude],
      zoom: 15,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion media query
    });
  };
  const margin =
    Side === "left"
      ? { top: 10, left: 30, right: 0, bottom: 5 }
      : { top: 10, left: 0, right: 30, bottom: 5 };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={250}
        height={250}
        data={data}
        layout="vertical"
        margin={margin}
      >
        <XAxis
          type="number"
          domain={[0, "dataMax"]}
          reversed={revers}
          hide={true}
        />
        <YAxis dataKey="village_name" type="category" orientation={SideChart} />
        <Tooltip />

        <Bar
          dataKey="case_sum"
          name="Case"
          fill="#8884d8"
          label={{ position: "right" }}
          onClick={handleTooltipClick}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors_case[index % 20]} />
          ))}
        </Bar>
        <Legend payload={legendItems} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Example;
