import Link from "@mui/material/Link";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
export const CheckBoxChange = (event, CheckboxValue, setValue, data) => {
  const { value } = event.target;
  const selectedIndex = CheckboxValue.indexOf(value);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = [...CheckboxValue, value];
  } else if (selectedIndex === 0) {
    newSelected = [...CheckboxValue.slice(1)];
  } else if (selectedIndex === data.length - 1) {
    newSelected = [...CheckboxValue.slice(0, -1)];
  } else if (selectedIndex > 0) {
    newSelected = [
      ...CheckboxValue.slice(0, selectedIndex),
      ...CheckboxValue.slice(selectedIndex + 1),
    ];
  }
  setValue(newSelected);
};
export const actionsMenu = (
  showInfoSum,
  showInfo,
  showMarkerInfo,
  switchYear,
  IsCompare,
) => {
  const actions = [
    {
      icon: <FunctionsOutlinedIcon />,
      name: "Case Summary",
      value: "caseSum",
      colorBG: showInfoSum ? global.color_sd_bg1 : global.color_gray,
      color: showInfoSum ? global.color_white : global.color_black,
      colorHoverBG: showInfoSum
        ? global.color_sd_bg_hover1
        : global.color_sd_bg_hover2,
      colorHover: showInfoSum ? global.color_black : global.color_white,
    },
    {
      icon: <InsertChartOutlinedOutlinedIcon />,
      name: "Top 5 Charts",
      value: "top5",
      colorBG: showInfo ? global.color_sd_bg1 : global.color_gray,
      color: showInfo ? global.color_white : global.color_black,
      colorHoverBG: showInfo
        ? global.color_sd_bg_hover1
        : global.color_sd_bg_hover2,
      colorHover: showInfo ? global.color_black : global.color_white,
    },
    {
      icon: <RoomOutlinedIcon />,
      name: "Marker Info",
      value: "marker",
      colorBG: showMarkerInfo ? global.color_sd_bg1 : global.color_gray,
      color: showMarkerInfo ? global.color_white : global.color_black,
      colorHoverBG: showMarkerInfo
        ? global.color_sd_bg_hover1
        : global.color_sd_bg_hover2,
      colorHover: showMarkerInfo ? global.color_black : global.color_white,
    },
  ];
  if (IsCompare) {
    actions.push({
      icon: <LabelOutlinedIcon />,
      name: "Label Year",
      value: "year",
      colorBG: switchYear ? global.color_sd_bg1 : global.color_gray,
      color: switchYear ? global.color_white : global.color_black,
      colorHoverBG: switchYear
        ? global.color_sd_bg_hover1
        : global.color_sd_bg_hover2,
      colorHover: switchYear ? global.color_black : global.color_white,
    });
  }
  return actions.reverse();
};

export const BreadCrumbsMenu = (setShowMenu, mapName) => {
  const breadcrumbs = [
    <Tooltip title="Home" TransitionComponent={Zoom} key="1">
      <div style={{ display: "flex", alignItems: "center" }}>
        <HomeOutlinedIcon
          className="btnHome"
          onClick={() => {
            setShowMenu(true);
          }}
        />
        <Link
          className="btnHome"
          component="button"
          onClick={() => {
            setShowMenu(true);
          }}
          style={{ marginLeft: 8 }}
        >
          <Typography
            color="inherit"
            sx={{
              textDecoration: "underline",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            Menu
          </Typography>
        </Link>
      </div>
    </Tooltip>,
    <Typography
      key="2"
      color="inherit"
      sx={{
        textDecoration: "underline",
        color: global.color_main,
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      {mapName}
    </Typography>,
  ];

  return breadcrumbs;
};
// export const mapStyle = {
//   position: "absolute",
// };
export const setDataSourceMaps = (mapContent, data, mapSource) => {
  if (mapContent && mapContent.getSource(mapSource)) {
    mapContent.getSource(mapSource).setData(data);
  }
};
export const CheckApiDown = (api, setFail) => {
  if (api === "error") {
    setFail(false);
  }
};
