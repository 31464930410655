import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";

const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#DC143C"];
const CustomYAxisTick = ({ x, y, payload, index }) => {
  return (
    <text x={x} y={y} fill={colors[index % colors.length]} dy={3}>
      {payload.value}
    </text>
  );
};
const Example = ({ year, caseSum, Map }) => {
  let dataNew = [
    {
      name: year,
      case: caseSum,
    },
  ].reverse(); // Reverse the order of the data

  const handleTooltipClick = () => {
    // Do something when tooltip is clicked
    // console.log("Tooltip clicked:", data, index);
    Map.flyTo({
      center: [global.longitude, global.latitude],
      zoom: global.zoom_start,
      essential: true,
    });
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={250} // Adjusted width
        height={250} // Adjusted height
        data={dataNew}
        layout="vertical" // Added layout prop for vertical orientation
        margin={{
          top: 20,
          left: 60,
          right: 20,
          bottom: 20,
        }}
      >
        <XAxis
          type="number"
          domain={[0, "dataMax"]}
          reversed={true}
          hide={true}
        />
        {/* Reversed XAxis */}
        <YAxis
          dataKey="name"
          type="category"
          orientation="right"
          label={{ position: "right" }}
          tick={<CustomYAxisTick />}
          hide={true}
        />
        <Tooltip />
        <Bar
          dataKey="case"
          fill="#8884d8"
          label={{ position: "right" }}
          onClick={handleTooltipClick}
        >
          {dataNew.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          ))}
        </Bar>
        <Legend
          payload={dataNew.map((entry) => ({
            //value: entry.side + ":" + entry.name,
            value: `Year:` + entry.name, // Changed to display 'name'
            type: "rect",
            id: entry.side,
            color: colors[dataNew.indexOf(entry) % 20],
          }))}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Example;
