import { ResponsiveBar } from "@nivo/bar";
// import { colorChart } from "../Global.jsx";
import { scaleLinear } from "d3-scale";
const NivoChart = ({ data, year, Map, Side = "left" }) => {
  const keys = ["case"]; // Define the data key for the bar values
  const handleClick = (value) => {
    Map.flyTo({
      center: [value.data.lng, value.data.lat],
      zoom: 15,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion media query
    });
  };

  const formattedData = data.map((item) => ({
    name: `${item.village_name} `, // Combine for label
    case: item.case_sum,
    lat: item.Latitude,
    lng: item.Longitude,
    year: year,
    rank: item.Rank,
  }));
  const maxCase = Math.max(...formattedData.map((item) => item.case));
  const tickValues = scaleLinear().domain([0, maxCase]).ticks(4);
  return (
    <ResponsiveBar
      data={formattedData.reverse()}
      keys={keys}
      indexBy="name"
      colors={({ index }) =>
        global.colorChart[index % global.colorChart.length]
      }
      // margin={{ top: 0, right: 50, bottom: 50, left: 50 }}
      margin={
        Side === "left"
          ? { top: 0, right: 70, bottom: 50, left: 20 }
          : { top: 0, right: 20, bottom: 50, left: 70 }
      } // Conditional margin
      padding={0.2}
      groupMode="grouped"
      layout="horizontal"
      reverse={Side === "right" ? false : true}
      onClick={handleClick}
      tooltip={({ value, color, indexValue }) => (
        <strong
          style={{
            color,
            backgroundColor: "white",
            padding: "5px",
            borderRadius: 8,
          }}
        >
          {indexValue}: {value}
        </strong>
      )}
      axisTop={null}
      axisRight={
        Side === "right"
          ? null
          : {
              // Conditional axisRight
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -40,
              truncateTickAt: 0,
            }
      }
      axisLeft={
        Side === "left"
          ? null
          : {
              // Conditional axisRight
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -40,
              truncateTickAt: 0,
            }
      }
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: `Year :${year}`,
        legendPosition: "middle",
        tickValues: tickValues,
        legendOffset: 40,
        truncateTickAt: 0,
      }}
      labelTextColor="#ffffff"
      labelSkipWidth={15} // Adjust to fit the size of your label
      labelSkipHeight={15}
      labelStyle={{ fontSize: 15, fill: "#555" }}
      role="application"
      isFocusable={true}
      // enableGridX={true}
      enableGridY={false}
    />
  );
};

export default NivoChart;
