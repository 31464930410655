import React, { useState, useEffect, useCallback } from "react";
import "../../css/App.css";
import "../../css/Style.css";
import {
  getDataVillage,
  getDataFilterType,
  getDataVillageSum,
} from "../../api/DataAPI";
import { CheckBoxChange } from "../tools/Tools";
import {
  getTop5Villages,
  convertDataRank,
  convertDataVillageToGeoJSON,
  getDayOfYear,
  getDayAgo,
  formatDateAPI,
  formatDate,
  yearReduce,
} from "../tools/ConvertData";
import {
  enableDisableButton,
  area,
  dataArea,
  age_group,
  dataAge,
  type,
  dataType,
  orgType,
  dataOrgType,
  currentYear,
  years,
  monthNames,
} from "../../data/FilterData";
import "../../global";
import MultiRangeSlider from "multi-range-slider-react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Drawer from "@mui/material/Drawer";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import DrawerMenu from "../tools/DrawerMenu";
import CircularProgress from "@mui/material/CircularProgress";
import {
  loadDataApiLayerCompare,
  loadDataApiHouseHoldCompare,
} from "../tools/LayerData";

const FilterCompareComponent = ({
  setLoading,
  setDataVillage,
  setDataVillageRight,
  setCaseSum,
  setCaseRightSum,
  setYearLeft,
  setYearRight,
  setShowFilter,
  showFilter,
  setShowMenu,
  showMenu,
  dataSchool,
  setDataSchool,
  setGeoPolygonA1,
  setGeoPolygonA2,
  setGeoPolygonB1,
  setGeoPolygonB2,
  setGeoPolygonNA,
  setGeoPolygonA1_R,
  setGeoPolygonA2_R,
  setGeoPolygonB1_R,
  setGeoPolygonB2_R,
  setGeoPolygonNA_R,
  setSumA1,
  setSumA2,
  setSumB1,
  setSumB2,
  setSumNA,
  setSumA1_R,
  setSumA2_R,
  setSumB1_R,
  setSumB2_R,
  setSumNA_R,
  SetTop5Left,
  SetTop5Right,
  setLoadApiLayers,
  loadApiLayers,
  loadApiLayerHouseHold,
  errLocation,
  setErrLocation,
  loadLocation,
  setDataHouseHoldLeft,
  setDataHouseHoldRight,
  Confirm,
  setConfirm,
}) => {
  const [stateGo, setStateGo] = useState(true);
  const [btnSearch, setBtnSearch] = useState(true);
  const [minMonthCaption, set_minMonthCaption] = useState("");
  const [maxMonthCaption, set_maxMonthCaption] = useState("");
  const [minSelect, set_minSelected] = useState(
    formatDateAPI(getDayAgo(new Date(), global.start_date)),
  );
  const [maxSelect, set_maxSelected] = useState(
    formatDateAPI(getDayAgo(new Date(), global.end_date)),
  );
  const [minSelect_old, set_minSelected_old] = useState(
    formatDateAPI(getDayAgo(new Date(), global.start_date)),
  );
  const [maxSelect_old, set_maxSelected_old] = useState(
    formatDateAPI(getDayAgo(new Date(), global.end_date)),
  );
  const [minStart_filter, set_minStart_filter] = useState(null);
  const [maxStart_filter, set_maxStart_filter] = useState(null);
  const [minStart, setMinStart] = useState(
    getDayOfYear(getDayAgo(new Date(), global.start_date)),
  );
  const [maxStart] = useState(getDayOfYear(new Date()));
  const [minRightMonthCaption, set_minRightMonthCaption] = useState("");
  const [maxRightMonthCaption, set_maxRightMonthCaption] = useState("");
  const [minRightSelect, set_minRightSelected] = useState(
    yearReduce(formatDateAPI(getDayAgo(new Date(), global.start_date)), 1),
  );
  const [maxRightSelect, set_maxRightSelected] = useState(
    yearReduce(formatDateAPI(getDayAgo(new Date(), global.end_date)), 1),
  );
  const [openLeft, setOpenLeft] = useState(true);
  const [openRight, setOpenRight] = useState(false);
  const [OpenSnackbar, setOpenSnackbar] = useState(false);
  const [FilterLoading, setFilterLoading] = useState(true);
  const [warning, setWarning] = useState(false);
  const [dataFilter, setDataFilter] = useState(null);
  const [dataLineage, setDataLineage] = useState(null);
  const [dataLineageRight, setDataLineageRight] = useState(null);
  const [TypeValue, setTypeValue] = useState(type);
  const [TypeRightValue, setTypeRightValue] = useState(type);
  const [OrgValue, setOrgValue] = useState(orgType);
  const [OrgRightValue, setOrgRightValue] = useState(orgType);
  const [AgeValues, setAgeValues] = useState(age_group);
  const [AgeRightValues, setAgeRightValues] = useState(age_group);
  const [AreaValues, setAreaValues] = useState(area);
  const [AreaRightValue, setAreaRightValue] = useState(area);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedYearRight, setSelectedYearRight] = useState(currentYear - 1);
  const [YearOld, setYearOld] = useState(currentYear);
  const [YearOld_R, setYearOld_R] = useState(currentYear - 1);
  const [yearDays, setYearDays] = useState(
    365 + (selectedYear % 4 === 0 ? 1 : 0),
  );
  const handleClose = (reason) => {
    if (reason === "clickAway") {
      return;
    }
    setOpenSnackbar(false);
    setErrLocation(false);
  };
  const handleFilter = (val, setVal) => {
    setVal(!val);
  };
  const [minCurrent] = useState(0);
  const [maxCurrent] = useState(yearDays - 1);

  const handleYearChange = (side, event) => {
    if (side === "left") {
      let year = event.target.value;
      let min = year + minSelect.substring(4, 8);
      let max = year + maxSelect.substring(4, 8);
      setSelectedYear(year);
      setYearDays(365 + (year % 4 === 0 ? 1 : 0));
      set_minSelected(min);
      set_maxSelected(max);
      set_minSelected_old(min);
      set_maxSelected_old(max);
    } else {
      let year = event.target.value;
      let min = year + minSelect.substring(4, 8);
      let max = year + maxSelect.substring(4, 8);
      setSelectedYearRight(year);
      set_minRightSelected(min);
      set_maxRightSelected(max);
    }
    setStateGo(false);
  };
  const handleConfirm = () => {
    if (
      AgeValues.length !== 0 &&
      dataLineage.length !== 0 &&
      TypeValue.length !== 0 &&
      AreaValues.length !== 0
    ) {
      setBtnSearch(true);
      setShowFilter(!showFilter);
      setConfirm(true);
      setStateGo(true);
    }
  };

  const handleDateChange = (e) => {
    let dd1 = new Date(selectedYear, 0, 1);
    let dd2 = new Date(selectedYear, 0, 1);
    dd1.setDate(e.minValue + 1);
    dd2.setDate(e.maxValue + 1);
    set_minMonthCaption(formatDate(dd1));
    set_maxMonthCaption(formatDate(dd2));

    let dr1 = new Date(selectedYearRight, 0, 1);
    let dr2 = new Date(selectedYearRight, 0, 1);
    dr1.setDate(e.minValue + 1);
    dr2.setDate(e.maxValue + 1);
    set_minRightMonthCaption(formatDate(dr1));
    set_maxRightMonthCaption(formatDate(dr2));
  };

  const DateChanged = (e) => {
    let dd1 = new Date(selectedYear, 0, 1);
    let dd2 = new Date(selectedYear, 0, 1);

    dd1.setDate(e.minValue + 1);
    dd2.setDate(e.maxValue + 1);
    set_minSelected(formatDateAPI(dd1));
    set_maxSelected(formatDateAPI(dd2));

    let dr1 = new Date(selectedYearRight, 0, 1);
    let dr2 = new Date(selectedYearRight, 0, 1);

    dr1.setDate(e.minValue + 1);
    dr2.setDate(e.maxValue + 1);
    set_minRightSelected(formatDateAPI(dr1));
    set_maxRightSelected(formatDateAPI(dr2));
  };

  const loadDataApi = useCallback(
    async (abortControl) => {
      if (dataSchool === null && dataFilter === null) {
        const apiFilterType = await getDataFilterType(
          global.start_date_year,
          maxSelect,
          AgeValues,
          TypeValue,
          AreaValues,
          OrgValue,
        );

        // if (global.call_api) {
        //   const apiSchool = await getDataSchool();
        //   const apiPolygonA1 = await getDataPolygon(selectedYear, "A1");
        //   const apiPolygonA2 = await getDataPolygon(selectedYear, "A2");
        //   const apiPolygonB1 = await getDataPolygon(selectedYear, "B1");
        //   const apiPolygonB2 = await getDataPolygon(selectedYear, "B2");
        //   const apiPolygonNA = await getDataPolygon(selectedYear, "NA");

        //   setGeoPolygonA1(apiPolygonA1);
        //   setGeoPolygonA2(apiPolygonA2);
        //   setGeoPolygonB1(apiPolygonB1);
        //   setGeoPolygonB2(apiPolygonB2);
        //   setGeoPolygonNA(apiPolygonNA);
        //   setDataSchool(
        //     convertDataToFeatureCollection(apiSchool, convertDataToSchool),
        //   );
        // }
        setDataFilter(apiFilterType);
        setOpenSnackbar(true);
      }

      if (dataLineage !== null) {
        if (
          AgeValues.length !== 0 &&
          dataLineage.length !== 0 &&
          TypeValue.length !== 0 &&
          AreaValues.length !== 0 &&
          OrgValue.length !== 0 &&
          AgeRightValues.length !== 0 &&
          dataLineageRight.length !== 0 &&
          TypeRightValue.length !== 0 &&
          AreaRightValue.length !== 0 &&
          OrgRightValue.length !== 0
        ) {
          enableDisableButton(document.getElementById("search"), true);
          if (
            btnSearch === true ||
            minSelect !== minSelect_old ||
            maxSelect !== maxSelect_old
          ) {
            setFilterLoading(true);
            set_minSelected_old(minSelect);
            set_maxSelected_old(maxSelect);

            if (selectedYear !== YearOld) {
              setYearOld(selectedYear);
            }

            if (selectedYearRight !== YearOld_R) {
              setYearOld_R(selectedYearRight);
            }
            try {
              // Fetch data concurrently (if possible)
              const [
                apiVillage,
                apiVillageSum,
                apiVillageRight,
                apiVillageRightSum,
              ] = await Promise.all([
                getDataVillage(
                  minSelect,
                  maxSelect,
                  AgeValues,
                  dataLineage,
                  TypeValue,
                  AreaValues,
                  OrgValue,
                ),
                getDataVillageSum(
                  minSelect,
                  maxSelect,
                  AgeValues,
                  dataLineage,
                  TypeValue,
                  AreaValues,
                  OrgValue,
                ),
                getDataVillage(
                  minRightSelect,
                  maxRightSelect,
                  AgeRightValues,
                  dataLineageRight,
                  TypeRightValue,
                  AreaRightValue,
                  OrgRightValue,
                ),
                getDataVillageSum(
                  minRightSelect,
                  maxRightSelect,
                  AgeRightValues,
                  dataLineageRight,
                  TypeRightValue,
                  AreaRightValue,
                  OrgRightValue,
                ),
              ]);
              setCaseSum(Number(apiVillageSum[0].case_sum));
              setDataVillage(
                convertDataVillageToGeoJSON(convertDataRank(apiVillage)),
              );
              SetTop5Left(getTop5Villages(convertDataRank(apiVillage)));

              setCaseRightSum(Number(apiVillageRightSum[0].case_sum));
              setDataVillageRight(
                convertDataVillageToGeoJSON(convertDataRank(apiVillageRight)),
              );
              SetTop5Right(getTop5Villages(convertDataRank(apiVillageRight)));
              setYearLeft(selectedYear);
              setYearRight(selectedYearRight);
              setBtnSearch(false);
              if (!loadApiLayerHouseHold.apiHouseHold) {
                setFilterLoading(false);
                setOpenSnackbar(true);
              }
            } catch (err) {
              console.log(err);
            }
          }
          setWarning(false);
        } else {
          setWarning(true);
          enableDisableButton(document.getElementById("search"), false);
        }
      }
      setLoading(false);
    },
    [
      dataSchool,
      dataFilter,
      dataLineage,
      setLoading,
      maxSelect,
      AgeValues,
      TypeValue,
      AreaValues,
      OrgValue,
      AgeRightValues,
      dataLineageRight,
      TypeRightValue,
      AreaRightValue,
      OrgRightValue,
      btnSearch,
      minSelect,
      minSelect_old,
      maxSelect_old,
      selectedYear,
      YearOld,
      selectedYearRight,
      YearOld_R,
      minRightSelect,
      maxRightSelect,
      setCaseSum,
      setDataVillage,
      SetTop5Left,
      setCaseRightSum,
      setDataVillageRight,
      SetTop5Right,
      setYearLeft,
      setYearRight,
      loadApiLayerHouseHold.apiHouseHold,
    ],
  );

  useEffect(() => {
    const abortControl = new AbortController();
    if (dataFilter !== null && dataLineage === null) {
      loadDataApi(abortControl);
      const lineage = dataFilter.map((obj) => obj.result_code_detail).join(",");
      setDataLineage(lineage.split(","));
      setDataLineageRight(lineage.split(","));
    }
  }, [dataFilter, dataLineage, loadDataApi]);

  useEffect(() => {
    const abortControl = new AbortController();
    loadDataApi(abortControl);
    return () => {
      abortControl.abort();
    };
  }, [loadDataApi]);

  const loadDataApiHouseHoldCallback = useCallback(() => {
    const abortControl = new AbortController();
    loadDataApiHouseHoldCompare(
      loadApiLayerHouseHold,
      selectedYear,
      YearOld,
      minSelect,
      minStart_filter,
      maxSelect,
      maxStart_filter,
      btnSearch,
      AgeValues,
      dataLineage,
      TypeValue,
      AreaValues,
      OrgValue,
      minRightSelect,
      maxRightSelect,
      AgeRightValues,
      dataLineageRight,
      TypeRightValue,
      AreaRightValue,
      OrgRightValue,
      setDataHouseHoldLeft,
      setDataHouseHoldRight,
      setFilterLoading,
      set_minStart_filter,
      set_maxStart_filter,
      setOpenSnackbar,
    );
    return () => {
      abortControl.abort();
    };
  }, [
    loadApiLayerHouseHold,
    selectedYear,
    YearOld,
    minSelect,
    minStart_filter,
    maxSelect,
    maxStart_filter,
    btnSearch,
    AgeValues,
    dataLineage,
    TypeValue,
    AreaValues,
    OrgValue,
    minRightSelect,
    maxRightSelect,
    AgeRightValues,
    dataLineageRight,
    TypeRightValue,
    AreaRightValue,
    OrgRightValue,
    setDataHouseHoldLeft,
    setDataHouseHoldRight,
  ]);

  useEffect(() => {
    return loadDataApiHouseHoldCallback();
  }, [loadDataApiHouseHoldCallback]);

  const loadDataApiLayerCallback = useCallback(() => {
    const abortControl = new AbortController();

    if (stateGo) {
      loadDataApiLayerCompare(
        loadApiLayers,
        selectedYear,
        YearOld,
        setLoadApiLayers,
        setDataSchool,
        setGeoPolygonA1,
        setGeoPolygonA2,
        setGeoPolygonB1,
        setGeoPolygonB2,
        setGeoPolygonNA,
        setFilterLoading,
        selectedYearRight,
        YearOld_R,
        setGeoPolygonA1_R,
        setGeoPolygonA2_R,
        setGeoPolygonB1_R,
        setGeoPolygonB2_R,
        setGeoPolygonNA_R,
        Confirm,
        setConfirm,
        setSumA1,
        setSumA2,
        setSumB1,
        setSumB2,
        setSumNA,
        setSumA1_R,
        setSumA2_R,
        setSumB1_R,
        setSumB2_R,
        setSumNA_R,
      );
    }

    return () => {
      abortControl.abort();
    };
  }, [
    stateGo,
    loadApiLayers,
    selectedYear,
    YearOld,
    setLoadApiLayers,
    setDataSchool,
    setGeoPolygonA1,
    setGeoPolygonA2,
    setGeoPolygonB1,
    setGeoPolygonB2,
    setGeoPolygonNA,
    selectedYearRight,
    YearOld_R,
    setGeoPolygonA1_R,
    setGeoPolygonA2_R,
    setGeoPolygonB1_R,
    setGeoPolygonB2_R,
    setGeoPolygonNA_R,
    Confirm,
    setConfirm,
    setSumA1,
    setSumA2,
    setSumB1,
    setSumB2,
    setSumNA,
    setSumA1_R,
    setSumA2_R,
    setSumB1_R,
    setSumB2_R,
    setSumNA_R,
  ]);

  useEffect(() => {
    return loadDataApiLayerCallback();
  }, [loadDataApiLayerCallback]);

  const handleToggle = () => {
    setShowFilter(!showFilter);
  };
  if (minStart > maxStart) {
    setMinStart(0);
    set_minSelected(formatDateAPI(new Date(new Date().getFullYear(), 0, 1)));
    // set_minSelected((formatDateAPI(getDayAgo(new Date(), global.end_date))).toString())
  }
  return (
    <div>
      <div>
        {/* <Button onClick={handleToggle}>Filter</Button> */}
        <DrawerMenu showMenu={showMenu} setShowMenu={setShowMenu} />
        <Drawer
          anchor="left"
          open={showFilter}
          onClose={handleToggle}
          // hideBackdrop={true}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              width: 500,
              backgroundColor: "rgba(245, 245, 245, 1)",
            },
          }}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CloseOutlinedIcon
                onClick={handleToggle}
                sx={{ fontSize: 40, color: "rgba(59,113,202, 1)" }}
              />
            </div>
            <div className="d-flex justify-content-center p-2">
              <div className="Filter">
                <Divider>MapLeft</Divider>
                <List
                  sx={{
                    width: 500,
                    maxWidth: 500,
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton
                    onClick={() => handleFilter(openLeft, setOpenLeft)}
                  >
                    <ListItemIcon>
                      <TuneOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Filter MapLeft" />
                    {openLeft ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openLeft} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <div>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="yearSelect">Year</InputLabel>
                            <Select
                              label="Year"
                              value={selectedYear}
                              onChange={(event) =>
                                handleYearChange("left", event)
                              }
                              inputProps={{
                                name: "year",
                                id: "yearSelect",
                              }}
                            >
                              {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                  {year}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                      <Typography variant="subtitle1" gutterBottom>
                        <div>StartDate : {minMonthCaption}</div>
                        <div>EndDate : {maxMonthCaption}</div>
                      </Typography>
                      <div>
                        <b>Type</b>
                        <FormGroup row={true}>
                          {dataType.map((option) => (
                            <FormControlLabel
                              name={option.label}
                              label={option.label}
                              key={option.value}
                              control={
                                <Checkbox
                                  defaultChecked={true}
                                  onChange={(event) =>
                                    CheckBoxChange(
                                      event,
                                      TypeValue,
                                      setTypeValue,
                                      dataType,
                                    )
                                  }
                                  value={option.value}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                      <div>
                        <b>Age</b>
                        <FormGroup row={true}>
                          {dataAge.map((option) => (
                            <FormControlLabel
                              name={option.label}
                              label={option.label}
                              key={option.value}
                              control={
                                <Checkbox
                                  defaultChecked={true}
                                  onChange={(event) =>
                                    CheckBoxChange(
                                      event,
                                      AgeValues,
                                      setAgeValues,
                                      dataAge,
                                    )
                                  }
                                  value={option.value}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                      <div>
                        <b>Case Classification</b>
                        <FormGroup row={true}>
                          {dataArea.map((option) => (
                            <FormControlLabel
                              name={option.label}
                              label={option.label}
                              key={option.value}
                              control={
                                <Checkbox
                                  defaultChecked={true}
                                  onChange={(event) =>
                                    CheckBoxChange(
                                      event,
                                      AreaValues,
                                      setAreaValues,
                                      dataArea,
                                    )
                                  }
                                  value={option.value}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                      {dataFilter && (
                        <div>
                          <b>Lineage</b>
                          <FormGroup row={true}>
                            {dataFilter.map((option) => (
                              <FormControlLabel
                                name={option.result_code_detail}
                                label={option.result_code_detail}
                                key={option.result_code_detail}
                                control={
                                  <Checkbox
                                    defaultChecked={true}
                                    onChange={(event) =>
                                      CheckBoxChange(
                                        event,
                                        dataLineage,
                                        setDataLineage,
                                        dataLineage,
                                      )
                                    }
                                    value={option.result_code_detail}
                                  />
                                }
                              />
                            ))}
                          </FormGroup>
                        </div>
                      )}
                      <div hidden={global.org_filter_hidden}>
                        <b>Org</b>
                        <FormGroup row={true}>
                          {dataOrgType.map((option) => (
                            <FormControlLabel
                              name={option.label}
                              label={option.label}
                              key={option.value}
                              control={
                                <Checkbox
                                  disabled={true}
                                  defaultChecked={option.check}
                                  onChange={(event) =>
                                    CheckBoxChange(
                                      event,
                                      OrgValue,
                                      setOrgValue,
                                      dataOrgType,
                                    )
                                  }
                                  value={option.value}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </List>
                  </Collapse>
                </List>
                <Divider>MapRight</Divider>
                <List
                  sx={{
                    width: 500,
                    maxWidth: 500,
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton
                    onClick={() => handleFilter(openRight, setOpenRight)}
                  >
                    <ListItemIcon>
                      <TuneOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Filter MapRight" />
                    {openRight ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openRight} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <div>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="yearSelect_right">
                              Year
                            </InputLabel>
                            <Select
                              label="Year_right"
                              value={selectedYearRight}
                              onChange={(event) =>
                                handleYearChange("right", event)
                              }
                              inputProps={{
                                name: "year_right",
                                id: "yearSelect_right",
                              }}
                            >
                              {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                  {year}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                      <Typography variant="subtitle1" gutterBottom>
                        <div>StartDate : {minRightMonthCaption}</div>
                        <div>EndDate : {maxRightMonthCaption}</div>
                      </Typography>
                      <div>
                        <b>Type</b>
                        <FormGroup row={true}>
                          {dataType.map((option) => (
                            <FormControlLabel
                              name={option.label}
                              label={option.label}
                              key={option.value}
                              control={
                                <Checkbox
                                  defaultChecked={true}
                                  onChange={(event) => {
                                    CheckBoxChange(
                                      event,
                                      TypeRightValue,
                                      setTypeRightValue,
                                      dataType,
                                    );
                                  }}
                                  value={option.value}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                      <div>
                        <b>Age</b>
                        <FormGroup row={true}>
                          {dataAge.map((option) => (
                            <FormControlLabel
                              name={option.label}
                              label={option.label}
                              key={option.value}
                              control={
                                <Checkbox
                                  defaultChecked={true}
                                  onChange={(event) =>
                                    CheckBoxChange(
                                      event,
                                      AgeRightValues,
                                      setAgeRightValues,
                                      dataAge,
                                    )
                                  }
                                  value={option.value}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                      <div>
                        <b>Area</b>
                        <FormGroup row={true}>
                          {dataArea.map((option) => (
                            <FormControlLabel
                              name={option.label}
                              label={option.label}
                              key={option.value}
                              control={
                                <Checkbox
                                  defaultChecked={true}
                                  onChange={(event) =>
                                    CheckBoxChange(
                                      event,
                                      AreaRightValue,
                                      setAreaRightValue,
                                      dataArea,
                                    )
                                  }
                                  value={option.value}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                      {dataFilter && (
                        <div>
                          <b>Lineage</b>
                          <FormGroup row={true}>
                            {dataFilter.map((option) => (
                              <FormControlLabel
                                name={option.result_code_detail}
                                label={option.result_code_detail}
                                key={option.result_code_detail}
                                control={
                                  <Checkbox
                                    defaultChecked={true}
                                    onChange={(event) =>
                                      CheckBoxChange(
                                        event,
                                        dataLineageRight,
                                        setDataLineageRight,
                                        dataLineageRight,
                                      )
                                    }
                                    value={option.result_code_detail}
                                  />
                                }
                              />
                            ))}
                          </FormGroup>
                        </div>
                      )}
                      <div hidden={global.org_filter_hidden}>
                        <b>Org</b>
                        <FormGroup row={true}>
                          {dataOrgType.map((option) => (
                            <FormControlLabel
                              name={option.label}
                              label={option.label}
                              key={option.value}
                              control={
                                <Checkbox
                                  disabled={true}
                                  defaultChecked={option.check}
                                  onChange={(event) =>
                                    CheckBoxChange(
                                      event,
                                      OrgRightValue,
                                      setOrgRightValue,
                                      dataOrgType,
                                    )
                                  }
                                  value={option.value}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </List>
                  </Collapse>
                </List>

                <div className="d-flex justify-content-center">
                  <Button
                    id="search"
                    variant="contained"
                    color="success"
                    onClick={handleConfirm}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="Warning">
                {warning && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="warning">
                      This is a warning alert — Please select at least 1 option.
                    </Alert>
                  </Stack>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {FilterLoading && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              )}
            </div>
          </div>
        </Drawer>
      </div>
      <div className="Snackbar">
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={FilterLoading}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              sx={{ width: "100%" }}
              icon={<CircularProgress size={20} />}
              severity="info"
            >
              Loading...
            </Alert>
          </Snackbar>
        </Stack>
      </div>
      <div className="Snackbar">
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={OpenSnackbar}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Loading complete!!
            </Alert>
          </Snackbar>
        </Stack>
      </div>
      <div className="Snackbar">
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={errLocation}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              User denied Geolocation!!
            </Alert>
          </Snackbar>
        </Stack>
      </div>
      <div className="Snackbar">
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={loadLocation}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              sx={{ width: "100%" }}
              icon={<CircularProgress size={20} />}
              severity="info"
            >
              Loading your location...
            </Alert>
          </Snackbar>
        </Stack>
      </div>
      <div className="multi-range-slider-container-new">
        <MultiRangeSlider
          labels={monthNames}
          min={minCurrent}
          max={maxCurrent}
          minValue={minStart - 1}
          maxValue={maxStart - 1}
          step={1}
          minCaption={minMonthCaption}
          maxCaption={maxMonthCaption}
          onInput={handleDateChange}
          onChange={DateChanged}
          baseClassName="multi-range-slider-custom"
        />
      </div>
      <div className="LoadingProgress">
        {FilterLoading && (
          <Box sx={{ width: "100%", zIndex: 5, position: "sticky" }}>
            <LinearProgress />
          </Box>
        )}
      </div>
    </div>
  );
};

export default FilterCompareComponent;
