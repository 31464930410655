import { ResponsiveBar } from "@nivo/bar";
// import { colorChart, latitude, longitude, zoom_start } from "../Global.jsx";
import { scaleLinear } from "d3-scale";

const NivoChart = ({ caseSum = 0, year = "2024", Map }) => {
  let dataNew = [
    {
      name: year,
      case: caseSum,
    },
  ];
  const tickValues = scaleLinear().domain([0, caseSum]).ticks(4);

  const handleClick = () => {
    Map.flyTo({
      center: [global.longitude, global.latitude],
      zoom: global.zoom_start,
      essential: true,
    });
  };

  return (
    <ResponsiveBar
      data={dataNew}
      keys={["case"]}
      indexBy="name"
      colors={({ index }) =>
        global.colorChart[index % global.colorChart.length]
      }
      margin={{ top: 20, right: 40, bottom: 50, left: 20 }}
      padding={0.3}
      groupMode="grouped"
      layout="horizontal"
      reverse={true}
      enableGridY={false}
      onClick={handleClick}
      tooltip={({ value, color, indexValue }) => (
        <strong
          style={{
            color,
            backgroundColor: "white",
            padding: "5px",
            borderRadius: 8,
          }}
        >
          {indexValue}: {value}
        </strong>
      )}
      axisTop={{
        legend: `Case Summary`,
        legendPosition: "middle",
        tickValues: [],
      }}
      axisRight={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: -40,
        truncateTickAt: 0,
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: `Year :${year}`,
        legendPosition: "middle",
        tickValues: tickValues,
        legendOffset: 35, // Adjust if the legend overlaps
      }}
      labelTextColor="#ffffff"
      axisLeft={null}
      role="application"
      isFocusable={true}
    />
  );
};

export default NivoChart;
