import React, { useState } from "react";
import { actionsMenu } from "../tools/Tools";
import ChartBarTop5 from "../tools/ChartBarTop5";
import ChartBar from "../tools/ChartBarSum";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ActionMainComponent from "./ActionMainComponent";
import MarkerInfo from "../tools/MarkerInfo";
const ActionMapComponent = ({
  mapRender,
  open,
  setOpen,
  draw,
  distance,
  setDistance,
  setShowFilter,
  setShowMenu,
  layerShow,
  setLayerSchool,
  setLayerHouseHold,
  setLayerArea,
  setLayerRank,
  setLayerCase,
  caseSum,
  mapName,
  year,
  top5,
  setLoadApiLayers,
  setLoadApiLayerHouseHold,
  loadApiLayers,
  loadApiLayerHouseHold,
  setErrLocation,
  setLoadLocation,
  sumA1,
  sumA2,
  sumB1,
  sumB2,
  sumNA,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showInfoSum, setShowSum] = useState(true);
  const [showInfo, setShowInfo] = useState(true);
  const [showMarkerInfo, setShowMarkerInfo] = useState(true);
  const [checked, setChecked] = useState(layerShow);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleActionClick = (actionName) => {
    if (actionName === "caseSum") {
      setShowSum((prevState) => !prevState);
    } else if (actionName === "top5") {
      setShowInfo((prevState) => !prevState);
    } else if (actionName === "marker") {
      setShowMarkerInfo((prevState) => !prevState);
    }
  };
  return (
    <div>
      <ActionMainComponent
        setShowMenu={setShowMenu}
        mapName={mapName}
        setShowFilter={setShowFilter}
        openMenu={openMenu}
        handleClick={handleClick}
        anchorEl={anchorEl}
        handleClose={handleClose}
        mapRender={mapRender}
        open={open}
        distance={distance}
        setChecked={setChecked}
        checked={checked}
        setLayerSchool={setLayerSchool}
        setLayerHouseHold={setLayerHouseHold}
        setLayerArea={setLayerArea}
        setLayerRank={setLayerRank}
        setLayerCase={setLayerCase}
        loadApiLayers={loadApiLayers}
        loadApiLayerHouseHold={loadApiLayerHouseHold}
        setLoadApiLayers={setLoadApiLayers}
        setLoadApiLayerHouseHold={setLoadApiLayerHouseHold}
        setErrLocation={setErrLocation}
        setLoadLocation={setLoadLocation}
        setOpen={setOpen}
        draw={draw}
        setDistance={setDistance}
      />
      <div className="chartPosition">
        {showInfoSum && caseSum && (
          <div className="chartSum">
            <ChartBar year={year} caseSum={caseSum} Map={mapRender} />
          </div>
        )}

        {showInfo && caseSum && (
          <div className="chartTop5">
            <h5 className="cardText">{`Top5`}</h5>
            <div className="cardChartCompare">
              <div className="chartTop5One">
                <ChartBarTop5
                  year={year}
                  data={top5}
                  Map={mapRender}
                  Side={"left"}
                />
              </div>
            </div>
          </div>
        )}
        {showMarkerInfo && caseSum && (
          <div className="markerInfo">
            <MarkerInfo
              checked={checked}
              sumA1={sumA1}
              sumA2={sumA2}
              sumB1={sumB1}
              sumB2={sumB2}
              sumNA={sumNA}
            />
          </div>
        )}
      </div>
      {caseSum && (
        <Box className="speedDial">
          <SpeedDial
            className="speedDialBtn"
            ariaLabel="SpeedDial"
            icon={<SpeedDialIcon />}
            FabProps={{
              size: "medium",
              style: { backgroundColor: global.color_main },
            }}
          >
            {actionsMenu(showInfoSum, showInfo, showMarkerInfo).map(
              (action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() => handleActionClick(action.value)} // onClick added
                  // sx={{ bgcolor: action.color, hover: "green" }}
                  sx={{
                    color: action.color, // Default color
                    bgcolor: action.colorBG, // Default text color
                    "&:hover": {
                      // Hover style
                      color: action.colorHover,
                      bgcolor: action.colorHoverBG,
                    },
                  }}
                />
              ),
            )}
          </SpeedDial>
        </Box>
      )}
    </div>
  );
};

export default ActionMapComponent;
