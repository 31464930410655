import React, { useEffect } from "react";
import { ResponsiveCalendar } from "@nivo/calendar";
import { useMediaQuery } from "react-responsive";
import { setDateYear } from "../tools/ConvertData";

const NivoCalendar = ({
  data,
  minDate,
  maxDate,
  year,
  setSelectedRange,
  setRangeShow,
  range,
  setRange,
}) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 640px)" });

  const handleClick = (event) => {
    if (event.data) {
      let day = event.data;
      if (range.length === 0) {
        setRange([day.day]);
        setRangeShow([day.day]);
      } else if (range.length === 1) {
        setRange([range[0], day.day]);
        setRangeShow([range[0], day.day]);
      } else {
        setRange([range[1], day.day]);
        setRangeShow([range[1], day.day]);
      }
    } else {
      setRange([setDateYear(year, "start"), setDateYear(year, "end")]);
      setRangeShow([setDateYear(year, "start"), setDateYear(year, "end")]);
    }
  };

  useEffect(() => {
    if (range.length === 2) {
      setSelectedRange(range);
      setRange([]);
    }
  }, [range, setRange, setSelectedRange]);

  const commonProps = {
    data: data,
    from: minDate,
    to: maxDate,
    minValue: "auto",
    emptyColor: "#eeeeee",
    colors: ["#97e3d5", "#61cdbb", "#e8c1a0", "#f47560", "#EF3718"],
    dayBorderWidth: 2,
    dayBorderColor: "#ffffff",
    onClick: handleClick,
  };

  const largeScreenProps = {
    ...commonProps,
    daySpacing: 1,
    yearSpacing: 0,
    monthSpacing: 40,
    margin: { top: 30, right: 0, bottom: 5, left: 30 },
    monthLegendOffset: 10,
    monthBorderColor: global.color_main,
    monthLegendPosition: "before",
  };

  const smallScreenProps = {
    ...commonProps,
    daySpacing: 1,
    yearSpacing: 0,
    monthSpacing: 20,
    margin: { top: 15, right: 0, bottom: 5, left: 15 },
    monthLegendOffset: 5,
    monthBorderColor: global.color_main,
    monthLegendPosition: "before",
  };

  return (
    <ResponsiveCalendar
      {...(isSmallScreen ? smallScreenProps : largeScreenProps)}
    />
  );
};

export default NivoCalendar;
