import HouseMarker from "../../image/marker-icons/house-marker32.png";
import VillagePlan from "../../image/marker-icons/village-marker-yellow.png";
import School from "../../image/marker-icons/school-marker.png";
import Top3 from "../../image/marker-icons/focus.png";
import Case from "../../image/marker-icons/case-marker.png";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HexagonTwoToneIcon from "@mui/icons-material/HexagonTwoTone";

const MarkerInfo = ({
  checked,
  sumA1,
  sumA2,
  sumB1,
  sumB2,
  sumNA,
  sumA1_R = null,
  sumA2_R = null,
  sumB1_R = null,
  sumB2_R = null,
  sumNA_R = null,
  compare = null,
}) => {
  return (
    <>
      {checked.length > 0 && (
        <>
          <div className="divInfo">Marker Info</div>
          <div className="markerDiv">
            {checked.indexOf("HouseHold") >= 0 && (
              <>
                <Tooltip title="IndexCase">
                  <Stack direction="row" spacing={2} className="stackMarker">
                    <img
                      src={HouseMarker}
                      width="32"
                      height="32"
                      alt="HouseMarker"
                    />
                    IndexCase
                  </Stack>
                </Tooltip>
                <Tooltip title="PendingPlan">
                  <Stack direction="row" spacing={2} className="stackMarker">
                    <img
                      src={VillagePlan}
                      width="32"
                      height="32"
                      alt="VillagePlan"
                    />
                    PendingPlan
                  </Stack>
                </Tooltip>
              </>
            )}
            {checked.indexOf("Case") >= 0 && (
              <Tooltip title="Case In Village">
                <Stack direction="row" spacing={2} className="stackMarker">
                  <img src={Case} width="32" height="32" alt="Top3" />
                  CaseInVillage
                </Stack>
              </Tooltip>
            )}
            {checked.indexOf("Rank") >= 0 && (
              <Tooltip title="Case Top3">
                <Stack direction="row" spacing={2} className="stackMarker">
                  <img src={Top3} width="32" height="32" alt="Top3" />
                  Top3
                </Stack>
              </Tooltip>
            )}
            {checked.indexOf("School") >= 0 && (
              <Tooltip title="School">
                <Stack direction="row" spacing={2} className="stackMarker">
                  <img src={School} width="32" height="32" alt="School" />
                  School
                </Stack>
              </Tooltip>
            )}
            {checked.indexOf("A1") >= 0 && (
              <Tooltip title="Polygon A1:(Number)">
                <Box display="flex" alignItems="center">
                  <HexagonTwoToneIcon
                    sx={{ color: global.color_a1 }}
                    fontSize="large"
                  />
                  <span style={{ color: global.color_a1 }}>
                    A1{sumA1 ? `(${sumA1})` : `(Loading...)`}
                  </span>
                  {compare && (
                    <span style={{ color: global.color_a1 }}>
                      {sumA1_R ? `/(${sumA1_R})` : `/(Loading...)`}
                    </span>
                  )}
                </Box>
              </Tooltip>
            )}
            {checked.indexOf("A2") >= 0 && (
              <Tooltip title="Polygon A2:(Number)">
                <Box display="flex" alignItems="center">
                  <HexagonTwoToneIcon
                    sx={{ color: global.color_a2 }}
                    fontSize="large"
                  />
                  <span style={{ color: global.color_a2 }}>
                    A2{sumA2 ? `(${sumA2})` : `(Loading...)`}
                  </span>
                  {compare && (
                    <span style={{ color: global.color_a2 }}>
                      {sumA2_R ? `(${sumA2_R})` : `/(Loading...)`}
                    </span>
                  )}
                </Box>
              </Tooltip>
            )}
            {checked.indexOf("B1") >= 0 && (
              <Tooltip title="Polygon B1:(Number)">
                <Box display="flex" alignItems="center">
                  <HexagonTwoToneIcon
                    sx={{ color: global.color_b1 }}
                    fontSize="large"
                  />
                  <span style={{ color: global.color_b1 }}>
                    B1{sumB1 ? `(${sumB1})` : `(Loading...)`}
                  </span>
                  {compare && (
                    <span style={{ color: global.color_b1 }}>
                      {sumB1_R ? `/(${sumB1_R})` : `/(Loading...)`}
                    </span>
                  )}
                </Box>
              </Tooltip>
            )}
            {checked.indexOf("B2") >= 0 && (
              <Tooltip title="Polygon B2:(Number)">
                <Box display="flex" alignItems="center">
                  <HexagonTwoToneIcon
                    sx={{ color: global.color_b2 }}
                    fontSize="large"
                  />
                  <span style={{ color: global.color_b2 }}>
                    B2{sumB2 ? `(${sumB2})` : `(Loading...)`}
                  </span>
                  {compare && (
                    <span style={{ color: global.color_b2 }}>
                      {sumB2_R ? `/(${sumB2_R})` : `/(Loading...)`}
                    </span>
                  )}
                </Box>
              </Tooltip>
            )}
            {checked.indexOf("NA") >= 0 && (
              <Tooltip title="Polygon NA:(Number)">
                <Box display="flex" alignItems="center">
                  <HexagonTwoToneIcon
                    sx={{ color: global.color_na }}
                    fontSize="large"
                  />
                  <span style={{ color: global.color_na }}>
                    NA{sumNA ? `(${sumNA})` : `(Loading...)`}
                  </span>
                  {compare && (
                    <span style={{ color: global.color_na }}>
                      {sumNA_R ? `/(${sumNA_R})` : `/(Loading...)`}
                    </span>
                  )}
                </Box>
              </Tooltip>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MarkerInfo;
