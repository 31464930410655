import React, { useState, useEffect, useCallback, useRef } from "react";
import maplibreGL from "maplibre-gl";
import MaplibreGeocoder from "@maplibre/maplibre-gl-geocoder";
import "maplibre-gl/dist/maplibre-gl.css";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxCompare from "mapbox-gl-compare";
import "mapbox-gl-compare/dist/mapbox-gl-compare.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "../../css/App.css";
import "../../global.jsx";
import FilterCompareComponent from "./FilterCompareComponent.jsx";
import LoadingSpinner from "../tools/LoadingSpinner.jsx";
import ActionMapCompareComponent from "./ActionMapCompareComponent.jsx";
import MapsAddSource from "../tools/MapsAddSource.jsx";
import { MapsControlStyle } from "../tools/MapsControlStyle.jsx";
import { MapsMeasure } from "../tools/MapsMeasure.jsx";
import { BaseMap } from "../tools/BaseMaps.jsx";
import { setDataSourceMaps } from "../tools/Tools.jsx";
import ModalComponent from "./ModalComponent.jsx";

const MapCompareComponent = () => {
  const mapName = global.map_swipe_compare;
  const mapLayer = "MapSwipe";
  const [loadApiLayers, setLoadApiLayers] = useState({
    apiSchoolCheck: false,
    apiSchool: false,
    apiA1Check: false,
    apiA1: false,
    apiA2Check: false,
    apiA2: false,
    apiB1Check: false,
    apiB1: false,
    apiB2Check: false,
    apiB2: false,
    apiNACheck: false,
    apiNA: false,
    apiA1Check_R: false,
    apiA1_R: false,
    apiA2Check_R: false,
    apiA2_R: false,
    apiB1Check_R: false,
    apiB1_R: false,
    apiB2Check_R: false,
    apiB2_R: false,
    apiNACheck_R: false,
    apiNA_R: false,
  });
  const [loadApiLayerHouseHold, setLoadApiLayerHouseHold] = useState({
    apiHouseHoldCheck: false,
    apiHouseHold: false,
  });
  const [errLocation, setErrLocation] = useState(false);
  const [loadLocation, setLoadLocation] = useState(false);
  const [switchYear, setSwitchYear] = useState(true);
  const [mapLeftContent, setMapLeftContent] = useState(null);
  const [mapRightContent, setMapRightContent] = useState(null);
  const [top5Left, SetTop5Left] = useState(null);
  const [top5Right, SetTop5Right] = useState(null);
  const [dataSchool, setDataSchool] = useState(null);
  const [dataHouseHoldLeft, setDataHouseHoldLeft] = useState({
    geojson_no: null,
    geojson_yes: null,
  });
  const [dataHouseHoldRight, setDataHouseHoldRight] = useState({
    geojson_no: null,
    geojson_yes: null,
  });
  const [geoPolygonA1, setGeoPolygonA1] = useState(null);
  const [geoPolygonA2, setGeoPolygonA2] = useState(null);
  const [geoPolygonB1, setGeoPolygonB1] = useState(null);
  const [geoPolygonB2, setGeoPolygonB2] = useState(null);
  const [geoPolygonNA, setGeoPolygonNA] = useState(null);
  const [geoPolygonA1_R, setGeoPolygonA1_R] = useState(null);
  const [geoPolygonA2_R, setGeoPolygonA2_R] = useState(null);
  const [geoPolygonB1_R, setGeoPolygonB1_R] = useState(null);
  const [geoPolygonB2_R, setGeoPolygonB2_R] = useState(null);
  const [geoPolygonNA_R, setGeoPolygonNA_R] = useState(null);
  const [sumA1, setSumA1] = useState(null);
  const [sumA2, setSumA2] = useState(null);
  const [sumB1, setSumB1] = useState(null);
  const [sumB2, setSumB2] = useState(null);
  const [sumNA, setSumNA] = useState(null);
  const [sumA1_R, setSumA1_R] = useState(null);
  const [sumA2_R, setSumA2_R] = useState(null);
  const [sumB1_R, setSumB1_R] = useState(null);
  const [sumB2_R, setSumB2_R] = useState(null);
  const [sumNA_R, setSumNA_R] = useState(null);
  const [dataVillage, setDataVillage] = useState(null);
  const [caseSum, setCaseSum] = useState(null);
  const [yearLeft, setYearLeft] = useState(null);
  const [dataVillageRight, setDataVillageRight] = useState(null);
  const [caseRightSum, setCaseRightSum] = useState(null);
  const [yearRight, setYearRight] = useState(null);
  const [loading, setLoading] = useState(true);
  const [draw, setDraw] = useState(null);
  const [Distance, setDistance] = useState(null);
  const [open, setOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [newStyle, setNewStyle] = useState(false);
  const [Confirm, setConfirm] = useState(false);
  const layerShow = ["Rank", "Case"];
  const [layerCase, setLayerCase] = useState("visible");
  // const infoShow = ["Sum"];
  const [layerSchool, setLayerSchool] = useState("none");
  const [layerHouseHold, setLayerHouseHold] = useState("none");
  const [layerArea, setLayerArea] = useState("none");
  const [layerRank, setLayerRank] = useState("visible");
  const [baseMaps] = useState(BaseMap);
  const MapLeftRef = useRef(null);
  const MapRightRef = useRef(null);
  const geocoderContainerRef = useRef(null);
  const comparisonContainerRef = useRef(null);
  const [index, setIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const initMap = useCallback(() => {
    const initBaseLeft = Object.keys(baseMaps)[0];
    const initBaseRight = Object.keys(baseMaps)[1];
    const MapLeft = new maplibreGL.Map({
      container: MapLeftRef.current,
      style: `${process.env.REACT_APP_MAPTILER_URL_START}${initBaseLeft}${process.env.REACT_APP_MAPTILER_URL_END}${process.env.REACT_APP_MAPTILER_APIKEY}`,
      center: [global.longitude, global.latitude], // starting position [lng, lat]
      zoom: global.zoom_start, // starting zoom
    });

    const MapRight = new maplibreGL.Map({
      container: MapRightRef.current,
      style: `${process.env.REACT_APP_MAPTILER_URL_START}${initBaseRight}${process.env.REACT_APP_MAPTILER_URL_END}${process.env.REACT_APP_MAPTILER_APIKEY}`,
      center: [global.longitude, global.latitude], // starting position [lng, lat]
      zoom: global.zoom_start, // starting zoom
    });

    let Draw = new MapboxDraw({
      displayControlsDefault: false,
    });
    setDraw(Draw);

    const geocoderApi = {
      forwardGeocode: async (config) => {
        const features = [];
        try {
          const request = `https://nominatim.openstreetmap.org/search?q=${config.query}&format=geojson&polygon_geojson=1&addressdetails=1`;
          const response = await fetch(request);
          const geojson = await response.json();
          for (const feature of geojson.features) {
            const center = [
              feature.bbox[0] + (feature.bbox[2] - feature.bbox[0]) / 2,
              feature.bbox[1] + (feature.bbox[3] - feature.bbox[1]) / 2,
            ];
            const point = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: center,
              },
              place_name: feature.properties.display_name,
              properties: feature.properties,
              text: feature.properties.display_name,
              place_type: ["place"],
              center,
            };
            features.push(point);
          }
        } catch (e) {
          console.error(`Failed to forwardGeocode with error: ${e}`);
        }
        return {
          features,
        };
      },
    };

    const Measure = (e) => {
      MapsMeasure(e, setDistance, setOpen);
    };
    // MapLeft.addControl(
    //   new maplibreGL.GeolocateControl({
    //     fitBoundsOptions: {
    //       zoom: 10,
    //     },
    //     positionOptions: {
    //       enableHighAccuracy: true,
    //     },
    //     trackUserLocation: true,
    //     showUserLocation: true,
    //     showAccuracyCircle: true,
    //     showUserHeading: true,
    //   }),
    //   "bottom-left",
    // );
    MapLeft.addControl(new maplibreGL.ScaleControl(), "bottom-left");
    MapLeft.addControl(Draw, "top-left");
    MapLeft.addControl(
      new MapsControlStyle({
        basemap: baseMaps,
        initialBasemap: initBaseLeft,
        setNewStyle: setNewStyle,
      }),
      "bottom-left",
    );
    // MapLeft.addControl(
    //   new MaplibreGeocoder(geocoderApi, {
    //     maplibreGL,
    //     marker: false,
    //     zoom: 13,
    //   }),
    //   "top-left",
    // );
    const geocoder = new MaplibreGeocoder(geocoderApi, {
      maplibreGL,
      marker: false,
      zoom: 13,
    });

    if (geocoderContainerRef.current) {
      geocoderContainerRef.current.appendChild(geocoder.onAdd(MapLeft));
    }
    MapLeft.on("draw.create", Measure);
    MapLeft.on("draw.update", Measure);
    MapLeft.on(
      "load",
      MapsAddSource(
        MapLeft,
        dataSchool,
        dataHouseHoldLeft.geojson_yes,
        dataHouseHoldLeft.geojson_no,
        dataVillage,
        layerSchool,
        layerHouseHold,
        layerArea,
        layerRank,
        layerCase,
        mapLayer,
        geoPolygonA1,
        geoPolygonA2,
        geoPolygonB1,
        geoPolygonB2,
        geoPolygonNA,
        setIndex,
      ),
    );

    // MapRight.addControl(Draw, "top-left");
    MapRight.on("draw.create", Measure);
    MapRight.on("draw.update", Measure);
    // MapRight.addControl(
    //   new maplibreGL.GeolocateControl({
    //     fitBoundsOptions: {
    //       zoom: 10,
    //     },
    //     positionOptions: {
    //       enableHighAccuracy: true,
    //     },
    //     trackUserLocation: true,
    //     showUserLocation: true,
    //     showAccuracyCircle: true,
    //     showUserHeading: true,
    //   }),
    //   "bottom-right",
    // );
    MapRight.addControl(new maplibreGL.ScaleControl(), "bottom-right");
    MapRight.addControl(
      new MapsControlStyle({
        basemap: baseMaps,
        initialBasemap: initBaseRight,
        setNewStyle: setNewStyle,
      }),
      "bottom-right",
    );
    MapRight.on(
      "load",
      MapsAddSource(
        MapRight,
        dataSchool,
        dataHouseHoldRight.geojson_yes,
        dataHouseHoldRight.geojson_no,
        dataVillageRight,
        layerSchool,
        layerHouseHold,
        layerArea,
        layerRank,
        layerCase,
        mapLayer,
        geoPolygonA1_R,
        geoPolygonA2_R,
        geoPolygonB1_R,
        geoPolygonB2_R,
        geoPolygonNA_R,
        setIndex,
      ),
    );

    setMapLeftContent(MapLeft);
    setMapRightContent(MapRight);
    const mapComparison = new MapboxCompare(
      MapLeft,
      MapRight,
      comparisonContainerRef.current,
      {
        // Set this to enable comparing two maps by mouse movement:
        // mousemove: true
      },
    );

    return () => {
      MapLeft.remove();
      MapRight.remove();
      mapComparison.remove();
    };
  }, [
    baseMaps,
    dataSchool,
    dataHouseHoldLeft.geojson_yes,
    dataHouseHoldLeft.geojson_no,
    dataVillage,
    layerSchool,
    layerHouseHold,
    layerArea,
    layerRank,
    layerCase,
    geoPolygonA1,
    geoPolygonA2,
    geoPolygonB1,
    geoPolygonB2,
    geoPolygonNA,
    dataHouseHoldRight.geojson_yes,
    dataHouseHoldRight.geojson_no,
    dataVillageRight,
    geoPolygonA1_R,
    geoPolygonA2_R,
    geoPolygonB1_R,
    geoPolygonB2_R,
    geoPolygonNA_R,
  ]);
  const loadData = useCallback(
    async (abortControl) => {
      global.pointLocation.features = [];
      global.geojson.features = [];
      setDataSourceMaps(mapLeftContent, dataVillage, "village");
      setDataSourceMaps(mapLeftContent, dataSchool, "school");
      setDataSourceMaps(
        mapLeftContent,
        dataHouseHoldLeft.geojson_yes,
        "HouseHold_Y",
      );
      setDataSourceMaps(
        mapLeftContent,
        dataHouseHoldLeft.geojson_no,
        "HouseHold_N",
      );
      setDataSourceMaps(mapLeftContent, geoPolygonA1, "poly_a1");
      setDataSourceMaps(mapLeftContent, geoPolygonA2, "poly_a2");
      setDataSourceMaps(mapLeftContent, geoPolygonB1, "poly_b1");
      setDataSourceMaps(mapLeftContent, geoPolygonB2, "poly_b2");
      setDataSourceMaps(mapLeftContent, geoPolygonNA, "poly_na");

      setDataSourceMaps(mapRightContent, dataVillageRight, "village");
      setDataSourceMaps(mapRightContent, dataSchool, "school");
      setDataSourceMaps(
        mapRightContent,
        dataHouseHoldRight.geojson_yes,
        "HouseHold_Y",
      );
      setDataSourceMaps(
        mapRightContent,
        dataHouseHoldRight.geojson_no,
        "HouseHold_N",
      );
      setDataSourceMaps(mapRightContent, geoPolygonA1_R, "poly_a1");
      setDataSourceMaps(mapRightContent, geoPolygonA2_R, "poly_a2");
      setDataSourceMaps(mapRightContent, geoPolygonB1_R, "poly_b1");
      setDataSourceMaps(mapRightContent, geoPolygonB2_R, "poly_b2");
      setDataSourceMaps(mapRightContent, geoPolygonNA_R, "poly_na");
    },
    [
      mapLeftContent,
      dataVillage,
      dataSchool,
      dataHouseHoldLeft.geojson_yes,
      dataHouseHoldLeft.geojson_no,
      geoPolygonA1,
      geoPolygonA2,
      geoPolygonB1,
      geoPolygonB2,
      geoPolygonNA,
      mapRightContent,
      dataVillageRight,
      dataHouseHoldRight.geojson_yes,
      dataHouseHoldRight.geojson_no,
      geoPolygonA1_R,
      geoPolygonA2_R,
      geoPolygonB1_R,
      geoPolygonB2_R,
      geoPolygonNA_R,
    ],
  );

  useEffect(() => {
    if (!mapLeftContent && !mapRightContent && dataVillage) {
      initMap();
    }
  }, [initMap, mapRightContent, mapLeftContent, dataVillage]);

  useEffect(() => {
    if ((mapLeftContent, mapRightContent)) {
      const abortControl = new AbortController();
      loadData(abortControl);
      return () => {
        abortControl.abort();
      };
    }
  }, [loadData, mapLeftContent, mapRightContent]);

  useEffect(() => {
    if (newStyle) {
      mapLeftContent.on(
        "style.load",
        MapsAddSource(
          mapLeftContent,
          dataSchool,
          dataHouseHoldLeft.geojson_yes,
          dataHouseHoldLeft.geojson_no,
          dataVillage,
          layerSchool,
          layerHouseHold,
          layerArea,
          layerRank,
          layerCase,
          mapLayer,
          geoPolygonA1,
          geoPolygonA2,
          geoPolygonB1,
          geoPolygonB2,
          geoPolygonNA,
          setIndex,
        ),
      );
      mapRightContent.on(
        "style.load",
        MapsAddSource(
          mapRightContent,
          dataSchool,
          dataHouseHoldRight.geojson_yes,
          dataHouseHoldRight.geojson_no,
          dataVillageRight,
          layerSchool,
          layerHouseHold,
          layerArea,
          layerRank,
          layerCase,
          mapLayer,
          geoPolygonA1_R,
          geoPolygonA2_R,
          geoPolygonB1_R,
          geoPolygonB2_R,
          geoPolygonNA_R,
          setIndex,
        ),
      );
      setNewStyle(false);
    }
  }, [
    dataSchool,
    dataHouseHoldLeft,
    dataHouseHoldRight,
    dataVillage,
    layerSchool,
    layerHouseHold,
    layerArea,
    layerRank,
    layerCase,
    mapLeftContent,
    mapRightContent,
    newStyle,
    geoPolygonA1,
    geoPolygonA2,
    geoPolygonB1,
    geoPolygonB2,
    geoPolygonNA,
    dataVillageRight,
    geoPolygonA1_R,
    geoPolygonA2_R,
    geoPolygonB1_R,
    geoPolygonB2_R,
    geoPolygonNA_R,
  ]);

  useEffect(() => {
    if (index) setOpenModal(true);
  }, [index]);

  return (
    <div>
      <div>
        <FilterCompareComponent
          setLoading={setLoading}
          setDataVillage={setDataVillage}
          setCaseSum={setCaseSum}
          setDataVillageRight={setDataVillageRight}
          setCaseRightSum={setCaseRightSum}
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
          dataSchool={dataSchool}
          setDataSchool={setDataSchool}
          setDataHouseHoldLeft={setDataHouseHoldLeft}
          setDataHouseHoldRight={setDataHouseHoldRight}
          setGeoPolygonA1={setGeoPolygonA1}
          setGeoPolygonA2={setGeoPolygonA2}
          setGeoPolygonB1={setGeoPolygonB1}
          setGeoPolygonB2={setGeoPolygonB2}
          setGeoPolygonNA={setGeoPolygonNA}
          setGeoPolygonA1_R={setGeoPolygonA1_R}
          setGeoPolygonA2_R={setGeoPolygonA2_R}
          setGeoPolygonB1_R={setGeoPolygonB1_R}
          setGeoPolygonB2_R={setGeoPolygonB2_R}
          setGeoPolygonNA_R={setGeoPolygonNA_R}
          setSumA1={setSumA1}
          setSumA2={setSumA2}
          setSumB1={setSumB1}
          setSumB2={setSumB2}
          setSumNA={setSumNA}
          setSumA1_R={setSumA1_R}
          setSumA2_R={setSumA2_R}
          setSumB1_R={setSumB1_R}
          setSumB2_R={setSumB2_R}
          setSumNA_R={setSumNA_R}
          setYearLeft={setYearLeft}
          setYearRight={setYearRight}
          SetTop5Left={SetTop5Left}
          SetTop5Right={SetTop5Right}
          setLoadApiLayers={setLoadApiLayers}
          loadApiLayers={loadApiLayers}
          loadApiLayerHouseHold={loadApiLayerHouseHold}
          errLocation={errLocation}
          setErrLocation={setErrLocation}
          loadLocation={loadLocation}
          Confirm={Confirm}
          setConfirm={setConfirm}
        />
        <div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div id="comparison-container" ref={comparisonContainerRef}>
              <ActionMapCompareComponent
                MapLeft={mapLeftContent}
                MapRight={mapRightContent}
                draw={draw}
                open={open}
                setOpen={setOpen}
                distance={Distance}
                setDistance={setDistance}
                setShowFilter={setShowFilter}
                setShowMenu={setShowMenu}
                layerShow={layerShow}
                setLayerSchool={setLayerSchool}
                setLayerHouseHold={setLayerHouseHold}
                setLayerArea={setLayerArea}
                setLayerRank={setLayerRank}
                setLayerCase={setLayerCase}
                caseSum={caseSum}
                caseRightSum={caseRightSum}
                yearLeft={yearLeft}
                yearRight={yearRight}
                top5Left={top5Left}
                top5Right={top5Right}
                mapName={mapName}
                setLoadApiLayers={setLoadApiLayers}
                setLoadApiLayerHouseHold={setLoadApiLayerHouseHold}
                loadApiLayers={loadApiLayers}
                loadApiLayerHouseHold={loadApiLayerHouseHold}
                setSwitchYear={setSwitchYear}
                switchYear={switchYear}
                setErrLocation={setErrLocation}
                setLoadLocation={setLoadLocation}
                setConfirm={setConfirm}
                sumA1={sumA1}
                sumA2={sumA2}
                sumB1={sumB1}
                sumB2={sumB2}
                sumNA={sumNA}
                sumA1_R={sumA1_R}
                sumA2_R={sumA2_R}
                sumB1_R={sumB1_R}
                sumB2_R={sumB2_R}
                sumNA_R={sumNA_R}
              />
              <div id="before" className="map" ref={MapLeftRef}>
                {switchYear && yearLeft && (
                  <div className="yearBefore">Year:{yearLeft}</div>
                )}
              </div>

              <div id="after" className="map" ref={MapRightRef}>
                {switchYear && yearRight && (
                  <div className="yearAfter">Year:{yearRight}</div>
                )}
              </div>

              <ModalComponent
                open={openModal}
                index={index}
                setOpenModal={setOpenModal}
                setIndex={setIndex}
              />
              <div ref={geocoderContainerRef} className="geocoder-container" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MapCompareComponent;
