import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./css/MultiRangeSliderCustom.css";
import reportWebVitals from "./reportWebVitals";
import MyRoute from "./MyRoute";
import { ThemeProvider } from "./ThemeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeProvider>
    <MyRoute />
  </ThemeProvider>,
  //</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
