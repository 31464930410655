import MapVillageComponent from "./components/main/MapVillageComponent";
import "./css/App.css";
import "./css/CustomStyle.css";

function App() {
  return (
    <div>
      <MapVillageComponent />
    </div>
  );
}
export default App;
