import React from "react";
import { NavLink } from "react-router-dom";
import "./global.jsx";

const NavbarComponent = () => {
  return (
    <nav>
      <ul className="nav nav-tabs">
        <li className="nav-item pr-3 pt-3 pb-3">
          <NavLink to="/" className="nav-link">
            {global.map_village}
          </NavLink>
        </li>
        <li className="nav-item pr-3 pt-3 pb-3">
          <NavLink to="/MapTrends" className="nav-link">
            {global.map_trends}
          </NavLink>
        </li>
        <li className="nav-item pr-3 pt-3 pb-3">
          <NavLink to="/MapCluster" className="nav-link">
            {global.map_cluster}
          </NavLink>
        </li>
        <li className="nav-item pr-3 pt-3 pb-3">
          <NavLink to="/MapCompare" className="nav-link">
            {global.map_swipe_compare}
          </NavLink>
        </li>
        <li className="nav-item pr-3 pt-3 pb-3">
          <NavLink to="/MapCalendar" className="nav-link">
            {global.map_calendar}
          </NavLink>
        </li>
        {/* <li className="nav-item pr-3 pt-3 pb-3">
          <NavLink to="/TestComponent" className="nav-link">
            {global.map_dev_test}
          </NavLink>
        </li> */}
      </ul>
    </nav>
  );
};

export default NavbarComponent;
