import React from "react";
import "../../css/LoadingSpinner.css"; // Create this CSS file for styling

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      {/* Add your loading animation here */}
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingSpinner;
