import {
  getDataSchool,
  getDataPolygon,
  getDataHouseHold,
} from "../../api/DataAPI";
import {
  convertDataToFeatureCollection,
  convertDataToSchool,
  convertToGeoJSON,
} from "./ConvertData";

export const loadDataApiHouseHoldCompare = async (
  loadApiLayerHouseHold,
  selectedYear,
  YearOld,
  minSelect,
  minStart_filter,
  maxSelect,
  maxStart_filter,
  btnSearch,
  AgeValues,
  dataLineage,
  TypeValue,
  AreaValues,
  OrgValue,
  minRightSelect,
  maxRightSelect,
  AgeRightValues,
  dataLineageRight,
  TypeRightValue,
  AreaRightValue,
  OrgRightValue,
  setDataHouseHoldLeft,
  setDataHouseHoldRight,
  setFilterLoading,
  set_minStart_filter,
  set_maxStart_filter,
  setOpenSnackbar,
) => {
  const loadHouseHoldData = async (loadFlag) => {
    if (!loadFlag || selectedYear !== YearOld) return;

    const shouldFetchData =
      minSelect !== minStart_filter ||
      maxSelect !== maxStart_filter ||
      (btnSearch &&
        minSelect === minStart_filter &&
        maxSelect === maxStart_filter);

    if (!shouldFetchData) return;

    setFilterLoading(true);

    try {
      const [apiData, apiDataRight] = await Promise.all([
        getDataHouseHold(
          minSelect,
          maxSelect,
          AgeValues,
          dataLineage,
          TypeValue,
          AreaValues,
          OrgValue,
        ),
        getDataHouseHold(
          minRightSelect,
          maxRightSelect,
          AgeRightValues,
          dataLineageRight,
          TypeRightValue,
          AreaRightValue,
          OrgRightValue,
        ),
      ]);

      const [dataConvertLeft, dataConvertRight] = await Promise.all([
        convertToGeoJSON(apiData),
        convertToGeoJSON(apiDataRight),
      ]);

      setDataHouseHoldLeft(dataConvertLeft);
      setDataHouseHoldRight(dataConvertRight);
      set_minStart_filter(minSelect);
      set_maxStart_filter(maxSelect);
    } catch (error) {
      console.error("Error converting to GeoJSON or fetching data:", error);
    } finally {
      setFilterLoading(false);
      setOpenSnackbar(true);
    }
  };
  await Promise.all([loadHouseHoldData(loadApiLayerHouseHold.apiHouseHold)]);
};

export const loadDataApiHouseHold = async (
  loadApiLayers,
  selectedYear,
  YearOld,
  minSelect,
  minStart_filter,
  maxSelect,
  maxStart_filter,
  AgeValues,
  dataLineage,
  TypeValue,
  AreaValues,
  OrgValue,
  setDataHouseHold,
  setFilterLoading,
  setOpenSnackbar,
  set_minStart_filter,
  set_maxStart_filter,
  btnSearch,
) => {
  const loadHouseHoldData = async (loadFlag) => {
    if (!loadFlag || selectedYear !== YearOld) return;

    const shouldFetchData =
      minSelect !== minStart_filter ||
      maxSelect !== maxStart_filter ||
      (btnSearch &&
        minSelect === minStart_filter &&
        maxSelect === maxStart_filter);

    if (!shouldFetchData) return;

    setFilterLoading(true);

    try {
      const apiData = await getDataHouseHold(
        minSelect,
        maxSelect,
        AgeValues,
        dataLineage,
        TypeValue,
        AreaValues,
        OrgValue,
      );

      const dataConvert = await convertToGeoJSON(apiData);
      setDataHouseHold(dataConvert);
      set_minStart_filter(minSelect);
      set_maxStart_filter(maxSelect);
    } catch (error) {
      console.error("Error converting to GeoJSON:", error);
    } finally {
      setFilterLoading(false);
      setOpenSnackbar(true);
    }
  };
  await Promise.all([loadHouseHoldData(loadApiLayers.apiHouseHold)]);
};

export const loadDataApiLayer = async (
  loadApiLayers,
  selectedYear,
  YearOld,
  setLoadApiLayers,
  setDataSchool,
  setGeoPolygonA1,
  setGeoPolygonA2,
  setGeoPolygonB1,
  setGeoPolygonB2,
  setGeoPolygonNA,
  setSumA1,
  setSumA2,
  setSumB1,
  setSumB2,
  setSumNA,
  setFilterLoading,
) => {
  const loadSchoolData = async (
    loadCheck,
    loadFlag,
    getDataFn,
    setDataFn,
    convertFn,
  ) => {
    if (!loadCheck) {
      if (loadFlag) {
        setFilterLoading(true);
        const apiData = await getDataFn();
        setDataFn(convertFn(apiData, convertDataToSchool));
        setLoadApiLayers((prevState) => ({
          ...prevState,
          apiSchoolCheck: true,
        }));
        setFilterLoading(false);
      }
    }
  };
  const loadLayerData = async (
    loadCheck,
    loadFlag,
    getDataFn,
    setDataFn,
    checkKey,
    setSum,
  ) => {
    if (selectedYear !== YearOld) {
      setLoadApiLayers((prevState) => ({
        ...prevState,
        [checkKey]: false,
      }));
      setSum(null);
    }
    if (selectedYear === YearOld) {
      if (!loadCheck) {
        if (loadFlag) {
          setFilterLoading(true);
          const apiData = await getDataFn();
          setSum(apiData.features.length);
          setDataFn(apiData);
          setLoadApiLayers((prevState) => ({
            ...prevState,
            [checkKey]: true,
          }));
          setFilterLoading(false);
        }
      }
    }
  };

  await Promise.all([
    loadSchoolData(
      loadApiLayers.apiSchoolCheck,
      loadApiLayers.apiSchool,
      getDataSchool,
      setDataSchool,
      convertDataToFeatureCollection,
    ),
    loadLayerData(
      loadApiLayers.apiA1Check,
      loadApiLayers.apiA1,
      () => getDataPolygon(selectedYear, "A1"),
      setGeoPolygonA1,
      "apiA1Check",
      setSumA1,
    ),
    loadLayerData(
      loadApiLayers.apiA2Check,
      loadApiLayers.apiA2,
      () => getDataPolygon(selectedYear, "A2"),
      setGeoPolygonA2,
      "apiA2Check",
      setSumA2,
    ),
    loadLayerData(
      loadApiLayers.apiB1Check,
      loadApiLayers.apiB1,
      () => getDataPolygon(selectedYear, "B1"),
      setGeoPolygonB1,
      "apiB1Check",
      setSumB1,
    ),
    loadLayerData(
      loadApiLayers.apiB2Check,
      loadApiLayers.apiB2,
      () => getDataPolygon(selectedYear, "B2"),
      setGeoPolygonB2,
      "apiB2Check",
      setSumB2,
    ),
    loadLayerData(
      loadApiLayers.apiNACheck,
      loadApiLayers.apiNA,
      () => getDataPolygon(selectedYear, "NA"),
      setGeoPolygonNA,
      "apiNACheck",
      setSumNA,
    ),
  ]);
};

export const loadDataApiLayerCompare = async (
  loadApiLayers,
  selectedYear,
  YearOld,
  setLoadApiLayers,
  setDataSchool,
  setGeoPolygonA1,
  setGeoPolygonA2,
  setGeoPolygonB1,
  setGeoPolygonB2,
  setGeoPolygonNA,
  setFilterLoading,
  selectedYear_R,
  YearOld_R,
  setGeoPolygonA1_R,
  setGeoPolygonA2_R,
  setGeoPolygonB1_R,
  setGeoPolygonB2_R,
  setGeoPolygonNA_R,
  Confirm,
  setConfirm,
  setSumA1,
  setSumA2,
  setSumB1,
  setSumB2,
  setSumNA,
  setSumA1_R,
  setSumA2_R,
  setSumB1_R,
  setSumB2_R,
  setSumNA_R,
) => {
  const loadSchoolData = async (
    loadCheck,
    loadFlag,
    getDataFn,
    setDataFn,
    convertFn,
  ) => {
    if (!loadCheck) {
      if (loadFlag) {
        setFilterLoading(true);
        const apiData = await getDataFn();
        setDataFn(convertFn(apiData, convertDataToSchool));
        setLoadApiLayers((prevState) => ({
          ...prevState,
          apiSchoolCheck: true,
        }));
        setFilterLoading(false);
      }
    }
  };

  const loadLayerData = async (
    loadCheck,
    loadFlag,
    getDataFn,
    setDataFn,
    checkKey,
    year,
    old,
    setSum,
  ) => {
    if (year !== old) {
      setLoadApiLayers((prevState) => ({
        ...prevState,
        [checkKey]: false,
      }));
      setSum(null);
    }
    if (!loadCheck && Confirm) {
      if (loadFlag) {
        if (year === old) {
          setFilterLoading(true);
          const apiData = await getDataFn();
          setDataFn(apiData);
          setSum(apiData.features.length);
          setLoadApiLayers((prevState) => ({
            ...prevState,
            [checkKey]: true,
          }));
          setFilterLoading(false);
          setConfirm(false);
        }
      }
    }
  };

  await Promise.all([
    loadSchoolData(
      loadApiLayers.apiSchoolCheck,
      loadApiLayers.apiSchool,
      getDataSchool,
      setDataSchool,
      convertDataToFeatureCollection,
    ),
    loadLayerData(
      loadApiLayers.apiA1Check,
      loadApiLayers.apiA1,
      () => getDataPolygon(selectedYear, "A1"),
      setGeoPolygonA1,
      "apiA1Check",
      selectedYear,
      YearOld,
      setSumA1,
    ),
    loadLayerData(
      loadApiLayers.apiA1Check_R,
      loadApiLayers.apiA1_R,
      () => getDataPolygon(selectedYear_R, "A1"),
      setGeoPolygonA1_R,
      "apiA1Check_R",
      selectedYear_R,
      YearOld_R,
      setSumA1_R,
    ),
    loadLayerData(
      loadApiLayers.apiA2Check,
      loadApiLayers.apiA2,
      () => getDataPolygon(selectedYear, "A2"),
      setGeoPolygonA2,
      "apiA2Check",
      selectedYear,
      YearOld,
      setSumA2,
    ),
    loadLayerData(
      loadApiLayers.apiA2Check_R,
      loadApiLayers.apiA2_R,
      () => getDataPolygon(selectedYear_R, "A2"),
      setGeoPolygonA2_R,
      "apiA2Check_R",
      selectedYear_R,
      YearOld_R,
      setSumA2_R,
    ),
    loadLayerData(
      loadApiLayers.apiB1Check,
      loadApiLayers.apiB1,
      () => getDataPolygon(selectedYear, "B1"),
      setGeoPolygonB1,
      "apiB1Check",
      selectedYear,
      YearOld,
      setSumB1,
    ),
    loadLayerData(
      loadApiLayers.apiB1Check_R,
      loadApiLayers.apiB1_R,
      () => getDataPolygon(selectedYear_R, "B1"),
      setGeoPolygonB1_R,
      "apiB1Check_R",
      selectedYear_R,
      YearOld_R,
      setSumB1_R,
    ),
    loadLayerData(
      loadApiLayers.apiB2Check,
      loadApiLayers.apiB2,
      () => getDataPolygon(selectedYear, "B2"),
      setGeoPolygonB2,
      "apiB2Check",
      selectedYear,
      YearOld,
      setSumB2,
    ),
    loadLayerData(
      loadApiLayers.apiB2Check_R,
      loadApiLayers.apiB2_R,
      () => getDataPolygon(selectedYear_R, "B2"),
      setGeoPolygonB2_R,
      "apiB2Check_R",
      selectedYear_R,
      YearOld_R,
      setSumB2_R,
    ),
    loadLayerData(
      loadApiLayers.apiNACheck,
      loadApiLayers.apiNA,
      () => getDataPolygon(selectedYear, "NA"),
      setGeoPolygonNA,
      "apiNACheck",
      selectedYear,
      YearOld,
      setSumNA,
    ),
    loadLayerData(
      loadApiLayers.apiNACheck_R,
      loadApiLayers.apiNA_R,
      () => getDataPolygon(selectedYear_R, "NA"),
      setGeoPolygonNA_R,
      "apiNACheck_R",
      selectedYear_R,
      YearOld_R,
      setSumNA_R,
    ),
  ]);
};
