import * as turf from "@turf/turf";

export const MapsMeasure = (e, setDistance, setOpen) => {
  // console.log(e);
  if (e.features[0].geometry.type === "LineString") {
    try {
      let line = turf.lineString(e.features[0].geometry.coordinates);
      let lenght = turf.length(line, { unit: "kilometers" });
      // alert(`${parseFloat(lenght.toFixed(2))} km`);
      setDistance(`${parseFloat(lenght.toFixed(2))} km`);
      // setOpen(false);
    } catch (error) {
      return console.log("error", error);
    }
  } else {
    try {
      let line = turf.polygon(e.features[0].geometry.coordinates);
      let lenght = turf.length(line, { unit: "kilometers" });
      // alert(`${parseFloat(lenght.toFixed(2))} km`);
      setDistance(`${parseFloat(lenght.toFixed(2))} km`);
      // setOpen(false);
    } catch (error) {
      return console.log("error", error);
    }
  }
};
