import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import App from "./App";
import MapClusterComponent from "./components/main/MapClusterComponent";
import MapCompareComponent from "./components/main/MapCompareComponent";
import MapTrendsComponent from "./components/main/MapTrendsComponent";
import MapCalendarComponent from "./components/main/MapCalendarComponent";

const MyRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/MapTrends" element={<MapTrendsComponent />} />
        <Route path="/MapCluster" element={<MapClusterComponent />} />
        <Route path="/MapCompare" element={<MapCompareComponent />} />
        <Route path="/MapCalendar" element={<MapCalendarComponent />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoute;
