
import map_base from "../../image/base.png";
import map_dark from "../../image/dark.png";
import map_hybrid from "../../image/hybrid.png";
import map_backdrop from "../../image/backdrop.png";
export const BaseMap = {
  "streets-v2": {
    img: map_base,
  },
  hybrid: {
    img: map_hybrid,
  },
  "streets-v2-dark": {
    img: map_dark,
  },
  "backdrop": {
    img: map_backdrop,
  },

}