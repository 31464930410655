import { useState, useEffect, useCallback, useRef } from "react";
import maplibreGL from "maplibre-gl";
import MaplibreGeocoder from "@maplibre/maplibre-gl-geocoder";
import "maplibre-gl/dist/maplibre-gl.css";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import FilterCalendarComponent from "./FilterCalendarComponent.jsx";
import LoadingSpinner from "../tools/LoadingSpinner.jsx";
import ActionMapCalendarComponent from "./ActionMapCalendarComponent.jsx";
import MapsAddSource from "../tools/MapsAddSource.jsx";
import { MapsControlStyle } from "../tools/MapsControlStyle.jsx";
import { MapsMeasure } from "../tools/MapsMeasure.jsx";
import { BaseMap } from "../tools/BaseMaps.jsx";
import { setDataSourceMaps } from "../tools/Tools.jsx";
import ModalComponent from "./ModalComponent.jsx";

const MapCalendar = () => {
  const mapLayer = global.map_calendar;
  const [map, setMap] = useState(null);
  const [errLocation, setErrLocation] = useState(false);
  const [loadLocation, setLoadLocation] = useState(false);
  const [dataVillage, setDataVillage] = useState(null);
  const [dataSchool, setDataSchool] = useState(global.geojson);
  const [dataHouseHold, setDataHouseHold] = useState({
    geojson_no: null,
    geojson_yes: null,
  });
  const [geoPolygonA1, setGeoPolygonA1] = useState(global.geojson);
  const [geoPolygonA2, setGeoPolygonA2] = useState(global.geojson);
  const [geoPolygonB1, setGeoPolygonB1] = useState(global.geojson);
  const [geoPolygonB2, setGeoPolygonB2] = useState(global.geojson);
  const [geoPolygonNA, setGeoPolygonNA] = useState(global.geojson);
  const [sumA1, setSumA1] = useState(null);
  const [sumA2, setSumA2] = useState(null);
  const [sumB1, setSumB1] = useState(null);
  const [sumB2, setSumB2] = useState(null);
  const [sumNA, setSumNA] = useState(null);
  const [loadApiLayers, setLoadApiLayers] = useState({
    apiSchoolCheck: false,
    apiSchool: false,
    apiA1Check: false,
    apiA1: false,
    apiA2Check: false,
    apiA2: false,
    apiB1Check: false,
    apiB1: false,
    apiB2Check: false,
    apiB2: false,
    apiNACheck: false,
    apiNA: false,
  });
  const [loadApiLayerHouseHold, setLoadApiLayerHouseHold] = useState({
    apiHouseHoldCheck: false,
    apiHouseHold: false,
  });
  const [loading, setLoading] = useState(true);
  const [draw, setDraw] = useState(null);
  const [Distance, setDistance] = useState(null);
  const [open, setOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [newStyle, setNewStyle] = useState(false);
  const layerShow = ["Rank", "Case"];
  const [layerCase, setLayerCase] = useState("visible");
  const [layerSchool, setLayerSchool] = useState("none");
  const [layerHouseHold, setLayerHouseHold] = useState("none");
  const [layerArea, setLayerArea] = useState("none");
  const [layerRank, setLayerRank] = useState("visible");
  const [baseMaps] = useState(BaseMap);
  const [caseSum, setCaseSum] = useState(null);
  const geocoderContainerRef = useRef(null);
  const [year, setYear] = useState(null);
  const [top5, setTop5] = useState(null);
  const [rangeShow, setRangeShow] = useState(null);
  const [range, setRange] = useState([]);
  const [index, setIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const initMap = useCallback(() => {
    const initBase = Object.keys(baseMaps)[0];
    const mapRender = new maplibreGL.Map({
      container: "MalariaMaps", // container id
      style: `${process.env.REACT_APP_MAPTILER_URL_START}${initBase}${process.env.REACT_APP_MAPTILER_URL_END}${process.env.REACT_APP_MAPTILER_APIKEY}`,
      center: [global.longitude, global.latitude], // starting position [lng, lat]
      zoom: global.zoom_start, // starting zoom
    });

    let Draw = new MapboxDraw({
      displayControlsDefault: false,
    });
    setDraw(Draw);

    const geocoderApi = {
      forwardGeocode: async (config) => {
        const features = [];
        try {
          const request = `https://nominatim.openstreetmap.org/search?q=${config.query}&format=geojson&polygon_geojson=1&addressdetails=1`;
          const response = await fetch(request);
          const geojson = await response.json();
          for (const feature of geojson.features) {
            const center = [
              feature.bbox[0] + (feature.bbox[2] - feature.bbox[0]) / 2,
              feature.bbox[1] + (feature.bbox[3] - feature.bbox[1]) / 2,
            ];
            const point = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: center,
              },
              place_name: feature.properties.display_name,
              properties: feature.properties,
              text: feature.properties.display_name,
              place_type: ["place"],
              center,
            };
            features.push(point);
          }
        } catch (e) {
          console.error(`Failed to forwardGeocode with error: ${e}`);
        }
        return {
          features,
        };
      },
    };

    mapRender.addControl(Draw, "top-left");
    mapRender.addControl(
      new MapsControlStyle({
        basemap: baseMaps,
        initialBasemap: initBase,
        setNewStyle: setNewStyle,
      }),
      "bottom-right",
    );
    // mapRender.addControl(
    //   new maplibreGL.GeolocateControl({
    //     fitBoundsOptions: {
    //       zoom: 10,
    //     },
    //     positionOptions: {
    //       enableHighAccuracy: true,
    //     },
    //     trackUserLocation: true,
    //     showUserLocation: true,
    //     showAccuracyCircle: true,
    //     showUserHeading: true,
    //   }),
    //   "bottom-left",
    // );
    const geocoder = new MaplibreGeocoder(geocoderApi, {
      maplibreGL,
      marker: false,
      zoom: 13,
    });

    if (geocoderContainerRef.current) {
      const geocoderElement = geocoder.onAdd(mapRender);
      // Append the geocoder element to the container
      geocoderContainerRef.current.appendChild(geocoderElement);
      // Find the input element within the geocoder and add id and name attributes
      const geocoderInput = geocoderElement.querySelector(
        "input.mapboxgl-ctrl-geocoder--input.maplibregl-ctrl-geocoder--input",
      );
      if (geocoderInput) {
        geocoderInput.id = "geocoder-input";
        geocoderInput.name = "geocoder-input";
      }
    }

    const Measure = (e) => {
      MapsMeasure(e, setDistance, setOpen);
    };

    mapRender.on("draw.create", Measure);
    mapRender.on("draw.update", Measure);
    mapRender.on(
      "load",
      MapsAddSource(
        mapRender,
        dataSchool,
        dataHouseHold.geojson_yes,
        dataHouseHold.geojson_no,
        dataVillage,
        layerSchool,
        layerHouseHold,
        layerArea,
        layerRank,
        layerCase,
        mapLayer,
        geoPolygonA1,
        geoPolygonA2,
        geoPolygonB1,
        geoPolygonB2,
        geoPolygonNA,
        setIndex,
      ),
    );
    // mapRender.on(
    //   "style.load",
    //   MapsAddSourceVillage(mapRender, geojson_school, geojson_village, layer),
    // );

    setMap(mapRender);
  }, [
    baseMaps,
    dataSchool,
    dataHouseHold,
    dataVillage,
    layerSchool,
    layerHouseHold,
    layerArea,
    layerRank,
    layerCase,
    mapLayer,
    geoPolygonA1,
    geoPolygonA2,
    geoPolygonB1,
    geoPolygonB2,
    geoPolygonNA,
  ]);

  const loadData = useCallback(async () => {
    global.pointLocation.features = [];
    global.geojson.features = [];
    setDataSourceMaps(map, dataVillage, "village");
    setDataSourceMaps(map, dataSchool, "school");
    setDataSourceMaps(map, dataHouseHold.geojson_yes, "HouseHold_Y");
    setDataSourceMaps(map, dataHouseHold.geojson_no, "HouseHold_N");
    setDataSourceMaps(map, geoPolygonA1, "poly_a1");
    setDataSourceMaps(map, geoPolygonA2, "poly_a2");
    setDataSourceMaps(map, geoPolygonB1, "poly_b1");
    setDataSourceMaps(map, geoPolygonB2, "poly_b2");
    setDataSourceMaps(map, geoPolygonNA, "poly_na");
  }, [
    dataSchool,
    dataHouseHold,
    dataVillage,
    geoPolygonA1,
    geoPolygonA2,
    geoPolygonB1,
    geoPolygonB2,
    geoPolygonNA,
    map,
  ]);

  useEffect(() => {
    if (!map && dataVillage) {
      initMap();
    }
  }, [initMap, map, dataVillage]);

  useEffect(() => {
    if (map) {
      const abortControl = new AbortController();
      loadData(abortControl);
      return () => {
        abortControl.abort();
      };
    }
  }, [loadData, map]);

  useEffect(() => {
    if (newStyle) {
      map.on(
        "style.load",
        MapsAddSource(
          map,
          dataSchool,
          dataHouseHold.geojson_yes,
          dataHouseHold.geojson_no,
          dataVillage,
          layerSchool,
          layerHouseHold,
          layerArea,
          layerRank,
          layerCase,
          mapLayer,
          geoPolygonA1,
          geoPolygonA2,
          geoPolygonB1,
          geoPolygonB2,
          geoPolygonNA,
          setIndex,
        ),
      );

      setNewStyle(false);
    }
  }, [
    dataSchool,
    dataHouseHold,
    dataVillage,
    layerSchool,
    layerHouseHold,
    layerArea,
    layerRank,
    layerCase,
    map,
    newStyle,
    geoPolygonA1,
    geoPolygonA2,
    geoPolygonB1,
    geoPolygonB2,
    geoPolygonNA,
    mapLayer,
  ]);

  useEffect(() => {
    if (index) setOpenModal(true);
  }, [index]);
  return (
    <div>
      <div>
        <FilterCalendarComponent
          setLoading={setLoading}
          setDataVillage={setDataVillage}
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
          dataSchool={dataSchool}
          setDataSchool={setDataSchool}
          setDataHouseHold={setDataHouseHold}
          setGeoPolygonA1={setGeoPolygonA1}
          setGeoPolygonA2={setGeoPolygonA2}
          setGeoPolygonB1={setGeoPolygonB1}
          setGeoPolygonB2={setGeoPolygonB2}
          setGeoPolygonNA={setGeoPolygonNA}
          setSumA1={setSumA1}
          setSumA2={setSumA2}
          setSumB1={setSumB1}
          setSumB2={setSumB2}
          setSumNA={setSumNA}
          setCaseSum={setCaseSum}
          setYear={setYear}
          setTop5={setTop5}
          setLoadApiLayers={setLoadApiLayers}
          loadApiLayers={loadApiLayers}
          loadApiLayerHouseHold={loadApiLayerHouseHold}
          setRangeShow={setRangeShow}
          setRange={setRange}
          range={range}
          errLocation={errLocation}
          setErrLocation={setErrLocation}
          loadLocation={loadLocation}
        />
        <div className="col-md-12 col-sm-12">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <div id="MalariaMaps" className="map" />
              <ActionMapCalendarComponent
                mapRender={map}
                draw={draw}
                open={open}
                setOpen={setOpen}
                distance={Distance}
                setDistance={setDistance}
                setShowFilter={setShowFilter}
                setShowMenu={setShowMenu}
                showFilter={showFilter}
                layerShow={layerShow}
                setLayerSchool={setLayerSchool}
                setLayerHouseHold={setLayerHouseHold}
                setLayerArea={setLayerArea}
                setLayerRank={setLayerRank}
                setLayerCase={setLayerCase}
                caseSum={caseSum}
                mapName={mapLayer}
                year={year}
                top5={top5}
                setLoadApiLayers={setLoadApiLayers}
                setLoadApiLayerHouseHold={setLoadApiLayerHouseHold}
                loadApiLayers={loadApiLayers}
                loadApiLayerHouseHold={loadApiLayerHouseHold}
                setRangeShow={setRangeShow}
                rangeShow={rangeShow}
                setRange={setRange}
                setErrLocation={setErrLocation}
                setLoadLocation={setLoadLocation}
                sumA1={sumA1}
                sumA2={sumA2}
                sumB1={sumB1}
                sumB2={sumB2}
                sumNA={sumNA}
              />
              <ModalComponent
                open={openModal}
                index={index}
                setOpenModal={setOpenModal}
                setIndex={setIndex}
              />
              <div ref={geocoderContainerRef} className="geocoder-container" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MapCalendar;
