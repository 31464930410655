global.call_api = true;
global.org_filter_hidden = false;
global.icon_size = 0.8;
global.color_uncluster = "#FF9999";
global.lbl_text_size_village = 5;
global.lbl_text_size_school = 12;
global.lbl_text_size_case = 14;
global.lbl_text_size_village = 10;
global.latitude = 14.556601;
global.longitude = 101.51864;
global.zoom_start = 4.9;
global.map_calendar = "MapCalendarDaily";
global.map_village = "MapVillage";
global.map_trends = "MapTrends";
global.map_cluster = "MapCluster";
global.map_swipe = "MapSwipe";
global.map_swipe_compare = "MapCompare";
global.map_dev_test = "DevTest";
global.year_start = 2012;
global.start_date_year = "20120101";
global.end_date_year = "20231231";
global.start_date = 14;
global.end_date = 0;
global.color_main = "#00A2FF";
global.color_sd_bg1 = "#00A2FF";
global.color_gray = "#808080";
global.color_white = "#FFFFFF";
global.color_btnZero = "#E1E5EA";
global.color_black = "#000000";
global.color_sd_bg_hover1 = "#70d8ff";
global.color_sd_bg_hover2 = "#4dbeff";
global.color_a1 = "#E74C3C";
global.color_a2 = "#FFA900";
global.color_b1 = "#16A085";
global.color_b2 = "#0088FE";
global.color_na = "#A5A6FF";
global.color_warning = "#FF8040";
global.rank_top = 3;
global.rank_top_text = "Top 3";
global.geojson = {
  type: "FeatureCollection",
  features: [],
};
global.pointLocation = {
  type: "FeatureCollection",
  features: [],
};
global.provinceSchool = [50, 57, 58, 63, 70, 71, 76, 77, 85, 86];
global.colorChart = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#DC143C"];
global.linestring = {
  type: "Feature",
  geometry: {
    type: "LineString",
    coordinates: [],
  },
};
