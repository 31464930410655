import { weekDays, monthsOfYear } from "../../data/FilterData";
import { format, parse } from "date-fns";
// import { subDays } from "date-fns";
export const convertDataToSchool = (dataSchool) => {
  const geometry = {
    type: "Point",
    coordinates: [dataSchool["Longitude"], dataSchool["Latitude"]],
  };
  const properties = {
    latitude: dataSchool["Latitude"],
    longitude: dataSchool["Longitude"],
    district_id: dataSchool["district_id"],
    district_name: dataSchool["district_name"],
    province_id: dataSchool["province_id"],
    province_name: dataSchool["province_name"],
    school_id: dataSchool["school_id"],
    school_name: dataSchool["school_name"],
    subdistrict_id: dataSchool["subdistrict_id"],
    subdistrict_name: dataSchool["subdistrict_name"],
  };
  return {
    type: "Feature",
    geometry: geometry,
    properties: properties,
  };
};
export const getTop5Villages = (data) => {
  // Sort villages by case_sum in descending order and take the top 10
  const sortedVillages = [...data]
    .sort((a, b) => b.case_sum - a.case_sum)
    .slice(0, 5);
  return sortedVillages;
};
// export const convertDataToVillage = (dataVillage) => {
//   const geometry = {
//     type: "Point",
//     coordinates: [dataVillage["Longitude"], dataVillage["Latitude"]],
//   };
//   const properties = {
//     village_id: dataVillage["village_id"],
//     village_name: dataVillage["village_name"],
//     case_sum: Number(dataVillage["case_sum"]),
//     Latitude: dataVillage["Latitude"],
//     Longitude: dataVillage["Longitude"],
//   };
//   return {
//     type: "Feature",
//     geometry: geometry,
//     properties: properties,
//   };
// };

export const setDateYear = (year, from) => {
  let str;
  if (from === "start") {
    str = "-01-01";
  } else {
    str = "-12-31";
  }
  return `${year}${str}`;
};
export const sortDateApi = (state) => {
  const sortedDates = [...state].sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB; // Ascending sort based on date objects
  });
  return sortedDates;
};
export const convertDataToChartCalendar = (data) => {
  const parsedDate = parse(data.date, "yyyyMMdd", new Date());

  // Format the parsed date to 'yyyy-MM-dd'
  const formattedDate = format(parsedDate, "yyyy-MM-dd");

  // Format the date_lable to 'EEE dd-MMM-yyyy'
  // const formattedDateLabel = format(parsedDate, "EEE dd-MMM-yyyy");

  return {
    day: formattedDate,
    date_api: data.date,
    date_lable: data.date_lable,
    value: Number(data.value),
  };
};

export const getMinMax = (data, setMinDate, setMaxDate) => {
  const dates = data.map((item) => parse(item.day, "yyyy-MM-dd", new Date()));
  setMinDate(new Date(Math.min(...dates)));
  setMaxDate(new Date(Math.max(...dates)));
};

export const convertDataToFeatureCollection = (data, convertData) => {
  const features = data.map(convertData);
  return {
    type: "FeatureCollection",
    features: features,
  };
};
export const convertDataVillageToGeoJSON = (data) => {
  return {
    type: "FeatureCollection",
    features: data.map((item) => ({
      type: "Feature",
      properties: {
        village_id: item.village_id,
        village_name: item.village_name,
        case_sum: item.case_sum,
        Rank: item.Rank,
        Latitude: item.Latitude,
        Longitude: item.Longitude,
      },
      geometry: {
        type: "Point",
        coordinates: [Number(item.Longitude), Number(item.Latitude)], // Ensure coordinates are numbers
      },
    })),
  };
};
export const convertDataRank = (data) => {
  // Convert case_sum to number and sort in descending order
  const sortedData = data.sort(
    (a, b) => Number(b.case_sum) - Number(a.case_sum),
  );

  // Add rank field
  let currentRank = 0;
  let previousCaseSum = 0;
  if (!sortedData) {
    previousCaseSum = Number(sortedData[0].case_sum);
  }
  return sortedData.map((item) => {
    if (Number(item.case_sum) !== previousCaseSum) {
      currentRank++;
      previousCaseSum = Number(item.case_sum);
    }
    return { ...item, case_sum: Number(item.case_sum), Rank: currentRank };
  });
};

function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}
// export const formatDate = (date) => {
//   let dateStr = "";
//   let date_check;
//   let y = date.getFullYear();
//   let d = date.getDate();
//   let m = date.getMonth();
//   let w = date.getDay();
//   if (isLeapYear(y) && m === 11 && d === 31) {
//     // Moving from leap year to standard year
//     date.setDate(0);
//   } else if (!isLeapYear(y) && m === 0 && d === 1) {
//     // Moving from standard year to leap year
//     date.setDate(2);
//   }

//   if (365 + (y % 4 === 0 ? 1 : 0) === 365) {
//     date_check = subDays(date, 1);
//     y = date_check.getFullYear();
//     d = date_check.getDate();
//     m = date_check.getMonth();
//     w = date_check.getDay();
//     dateStr = weekDays[w] + " " + d + "-" + monthNames[m] + "-" + y;
//   } else {
//     dateStr = weekDays[w] + " " + d + "-" + monthNames[m] + "-" + y;
//   }
//   return dateStr;
// };
export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const dayOfWeek = weekDays[date.getDay()];
  const day = String(date.getDate()).padStart(2, "0");
  const month = monthsOfYear[date.getMonth()];
  const year = date.getFullYear();

  return `${dayOfWeek} ${day}-${month}-${year}`;
};

export const convertValueToInt = (data) => {
  return data.map((item) => ({
    ...item,
    value: parseInt(item.value, 10), // Convert value to integer
  }));
};
export const formatDateAPI = (inputDate) => {
  let date = new Date(inputDate);
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // Add 1 to the month because it's zero-based
  let day = date.getDate();

  // Adjust date if necessary
  if (month === 2 && day > 28 && !isLeapYear(year)) {
    // Non-leap year, February has 28 days
    day = 28;
  }

  // Format the date string
  let dateStr = `${year}${String(month).padStart(2, "0")}${String(day).padStart(
    2,
    "0",
  )}`;

  return dateStr;
};

// export const formatDateAPI = (inputDate) => {
//   let dateStr = "";
//   let date_check;
//   let date = new Date(inputDate);
//   let year = date.getFullYear();
//   let month = date.getMonth(); // Add 1 to the month because it's zero-based
//   let day = date.getDate();
//   if (isLeapYear(year) && month === 11 && day === 31) {
//     // Moving from leap year to standard year
//     date.setDate(0);
//     console.log("first");
//   } else if (!isLeapYear(year) && month === 0 && day === 1) {
//     // Moving from standard year to leap year
//     date.setDate(2);
//     console.log("2");
//   }
//   if (365 + (year % 4 === 0 ? 1 : 0) === 365) {
//     date_check = subDays(date, 1);
//     year = date_check.getFullYear();
//     month = date_check.getMonth();
//     day = date_check.getDate();
//     dateStr = `${year}${String(month + 1).padStart(2, "0")}${String(
//       day,
//     ).padStart(2, "0")}`;
//   } else {
//     dateStr = `${year}${String(month + 1).padStart(2, "0")}${String(
//       day,
//     ).padStart(2, "0")}`;
//   }
//   return dateStr;
// };

// export const formatDateAPI = (inputDate) => {
//   let date_check;
//   let dateStr = "";
//   let date = new Date(inputDate);
//   let year = date.getFullYear();
//   let month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to the month because it's zero-based
//   let day = String(date.getDate()).padStart(2, "0");

//   if (365 + (year % 4 === 0 ? 1 : 0) === 365) {
//     console.log(date);
//     date_check = subDays(date, 1);
//     year = date_check.getFullYear();
//     month = String(date_check.getMonth() + 1).padStart(2, "0"); // Add 1 to the month because it's zero-based
//     day = String(date_check.getDate()).padStart(2, "0");
//     dateStr = `${year}${month}${day}`;
//   } else {
//     dateStr = `${year}${month}${day}`;
//   }
//   return dateStr;
// };
export const getDayAgo = (date, day) => {
  const currentDate = date;
  currentDate.setDate(currentDate.getDate() - day);
  const formattedDate = currentDate.toISOString().split("T")[0];
  return formattedDate;
};
export const getDayOfYear = (date) => {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const startOfYear = new Date(year, 0, 1);
  const timeDifference = inputDate - startOfYear;
  const dayOfYear = Math.floor(timeDifference / (24 * 60 * 60 * 1000)) + 1;
  return dayOfYear;
};

export const formatDateFromAPI = (inputDate) => {
  const dateObject = new Date(
    `${inputDate.slice(0, 4)}-${inputDate.slice(4, 6)}-${inputDate.slice(
      6,
      8,
    )}`,
  );
  const dayOfWeek = weekDays[dateObject.getUTCDay()];
  const dayOfMonth = dateObject.getUTCDate().toString().padStart(2, "0");
  const month = monthsOfYear[dateObject.getUTCMonth()];
  const year = dateObject.getUTCFullYear();
  return `${dayOfWeek} ${dayOfMonth}-${month}-${year}`;
};

export const checkDate = (min, max) => {
  // 1. Convert date strings to YYYYMMDD format for easy comparison
  const minYYYYMMDD = min.replace(/-/g, "");
  const maxYYYYMMDD = max.replace(/-/g, "");

  // 2. Validate date format (you might want a more robust validation)
  if (!/^\d{8}$/.test(minYYYYMMDD) || !/^\d{8}$/.test(maxYYYYMMDD)) {
    throw new Error("Invalid date format. Please use YYYY-MM-DD.");
  }

  // 3. Logic to check and subtract a year
  if (parseInt(minYYYYMMDD) > parseInt(maxYYYYMMDD)) {
    const minDate = new Date(
      minYYYYMMDD.slice(0, 4),
      minYYYYMMDD.slice(4, 6) - 1,
      minYYYYMMDD.slice(6),
    );
    minDate.setFullYear(minDate.getFullYear() - 1);

    return false;
  } else {
    return true;
  }
};

export const checkDateFormatAndFuture = (dateStr) => {
  // console.log(dateStr);
  // Basic format validation (using regular expression)
  const isValidFormat = /^\d{8}$/.test(dateStr);
  if (!isValidFormat) {
    return { isValid: false, inFuture: false }; // Return early if format is incorrect
  }

  // Extract components for comparison
  const year = parseInt(dateStr.substring(0, 4), 10);
  const month = parseInt(dateStr.substring(4, 6), 10) - 1; // Adjust for zero-based month indexing
  const day = parseInt(dateStr.substring(6, 8), 10);

  const inputDate = new Date(year, month, day);
  const today = new Date();
  // console.log("inputDate:" + inputDate);
  // console.log("today:" + today);
  today.setHours(0, 0, 0, 0); // Ignore time for comparison

  return {
    isValid: true,
    inFuture: inputDate > today,
  };
};
export const yearReduce = (date, y) => {
  const originalDateString = date;

  // Extract year, month, and day from the original string
  const year = originalDateString.substring(0, 4);
  const month = originalDateString.substring(4, 6);
  const day = originalDateString.substring(6, 8);

  // Decrement the year by 1 to get "2023"
  const modifiedYear = parseInt(year) - y;

  // Construct the new date string "20230509"
  const modifiedDateString = `${modifiedYear}${month}${day}`;

  return modifiedDateString;
};

export const convertToGeoJSON = async (data) => {
  const geojson_no = {
    type: "FeatureCollection",
    features: [],
  };
  const geojson_yes = {
    type: "FeatureCollection",
    features: [],
  };
  data.forEach((item) => {
    const feature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [parseFloat(item.Longitude), parseFloat(item.Latitude)],
      },
      properties: {
        ...item, // เพิ่มข้อมูลทั้งหมดจาก item
        case_sum: parseInt(item.case_sum, 10),
      },
    };

    if (item.is_confirm_index_case === "N") {
      geojson_no.features.push(feature);
    } else if (item.is_confirm_index_case === "Y") {
      geojson_yes.features.push(feature);
    }
  });
  return { geojson_no, geojson_yes };
};
