import React, { useState, useEffect, useCallback } from "react";
import "../../css/App.css";
import "../../css/Style.css";
import {
  getDataVillage,
  getDataFilterType,
  getDataVillageSum,
  getDataChartWeekly,
} from "../../api/DataAPI";
import {
  getTop5Villages,
  convertDataRank,
  convertDataVillageToGeoJSON,
  convertValueToInt,
  checkDate,
  checkDateFormatAndFuture,
  // convertDataToVillage,
  getDayOfYear,
  getDayAgo,
  formatDate,
  formatDateAPI,
  formatDateFromAPI,
} from "../tools/ConvertData";
import {
  enableDisableButton,
  area,
  dataArea,
  age_group,
  dataAge,
  type,
  dataType,
  currentYear,
  years,
  orgType,
  dataOrgType,
} from "../../data/FilterData";
// import { dataAge, dataType, currentYear, years, weekDays, monthNames, data_chart } from "../../data/dataDate";
import "../../global";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import RangeSelectorChart from "./RangeSelectorChart";
import FilterMainComponent from "./FilterMainComponent";
import { loadDataApiLayer, loadDataApiHouseHold } from "../tools/LayerData";

const FilterChartComponent = ({
  setLoading,
  setDataVillage,
  setShowFilter,
  showFilter,
  setShowMenu,
  showMenu,
  dataSchool,
  setDataSchool,
  setGeoPolygonA1,
  setGeoPolygonA2,
  setGeoPolygonB1,
  setGeoPolygonB2,
  setGeoPolygonNA,
  setSumA1,
  setSumA2,
  setSumB1,
  setSumB2,
  setSumNA,
  setCaseSum,
  setYear,
  setTop5,
  setLoadApiLayers,
  loadApiLayers,
  loadApiLayerHouseHold,
  errLocation,
  setErrLocation,
  loadLocation,
  setDataHouseHold,
}) => {
  const [stateGo, setStateGo] = useState(true);
  const [indexStart, setIndexStart] = useState(0);
  const [indexEnd, setIndexEnd] = useState(null);
  const [btnSearch, setBtnSearch] = useState(true);
  const [minMonthCaption, set_minMonthCaption] = useState("");
  const [maxMonthCaption, set_maxMonthCaption] = useState("");
  const [dataCharts, setDataCharts] = useState(null);

  const [minSelect, set_minSelected] = useState(
    formatDateAPI(new Date(new Date().getFullYear(), 0, 1)),
  );
  // const [maxSelect, set_maxSelected] = useState(
  //     formatDateAPI(new Date(new Date().getFullYear(), 11, 31)),
  // );

  const [maxSelect, set_maxSelected] = useState(
    formatDateAPI(getDayAgo(new Date(), global.end_date)),
  );

  const [minSelect_old, set_minSelected_old] = useState(
    formatDateAPI(getDayAgo(new Date(), global.start_date)),
  );
  const [maxSelect_old, set_maxSelected_old] = useState(
    formatDateAPI(getDayAgo(new Date(), global.end_date)),
  );

  const [minStart_filter, set_minStart_filter] = useState(null);
  const [maxStart_filter, set_maxStart_filter] = useState(null);
  const [minStart, setMinStart] = useState(
    getDayOfYear(getDayAgo(new Date(), global.start_date)),
  );
  const [maxStart] = useState(getDayOfYear(new Date()));
  const [OpenSnackbar, setOpenSnackbar] = useState(false);
  const [FilterLoading, setFilterLoading] = useState(true);
  const [warning, setWarning] = useState(false);
  const [dataFilter, setDataFilter] = useState(null);
  const [dataLineage, setDataLineage] = useState(null);
  const [TypeValue, setTypeValue] = useState(type);
  const [AgeValues, setAgeValues] = useState(age_group);
  const [AreaValues, setAreaValues] = useState(area);
  const [OrgValue, setOrgValue] = useState(orgType);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [YearOld, setYearOld] = useState(currentYear);
  const handleClose = (reason) => {
    if (reason === "clickAway") {
      return;
    }
    setOpenSnackbar(false);
    setErrLocation(false);
  };

  const handleYearChange = (event) => {
    let year = event.target.value;
    setSelectedYear(year);
    let min = year + minSelect.substring(4, 8);
    let max = year + maxSelect.substring(4, 8);
    setStateGo(false);
    // let min = year + `0101`
    // let max = year + `1231`
    // set_minSelected(year + min);
    // set_maxSelected(year + max);
    // set_minSelected_old(year + min);
    // set_maxSelected_old(year + max);

    if (
      checkDate(year + min.substring(4, 8), year + max.substring(4, 8)) ===
        true &&
      checkDateFormatAndFuture(year + min.substring(4, 8)).inFuture === false &&
      checkDateFormatAndFuture(year + max.substring(4, 8)).inFuture === false
    ) {
      set_minSelected(year + min.substring(4, 8));
      set_maxSelected(year + max.substring(4, 8));
      set_minSelected_old(year + min.substring(4, 8));
      set_maxSelected_old(year + max.substring(4, 8));
    } else {
      if (
        checkDateFormatAndFuture(year + min.substring(4, 8)).inFuture === true
      ) {
        set_minSelected(
          formatDateAPI(new Date(new Date().getFullYear(), 0, 1)),
        );
        set_minSelected_old(
          formatDateAPI(new Date(new Date().getFullYear(), 0, 1)),
        );
      } else {
        set_minSelected(year - 1 + min.substring(4, 8));
        set_minSelected_old(year - 1 + min.substring(4, 8));
      }

      if (
        checkDateFormatAndFuture(year + 1 + max.substring(4, 8)).inFuture ===
        true
      ) {
        set_maxSelected(formatDateAPI(getDayAgo(new Date(), global.end_date)));
        set_maxSelected_old(
          formatDateAPI(getDayAgo(new Date(), global.end_date)),
        );
      } else {
        set_maxSelected(year + 1 + max.substring(4, 8));
        set_maxSelected_old(year + 1 + max.substring(4, 8));
      }
    }
  };

  const handleConfirm = () => {
    if (
      AgeValues.length !== 0 &&
      dataLineage.length !== 0 &&
      TypeValue.length !== 0 &&
      AreaValues.length !== 0
    ) {
      setBtnSearch(true);
      setShowFilter(!showFilter);
      setStateGo(true);
    }
  };

  const loadDataApi = useCallback(
    async (abortControl) => {
      if (dataSchool === null && dataFilter === null) {
        const apiFilterType = await getDataFilterType(
          global.start_date_year,
          maxSelect,
          AgeValues,
          TypeValue,
          AreaValues,
          OrgValue,
        );
        const apiChart = await getDataChartWeekly(
          selectedYear + `0101`,
          selectedYear + `1231`,
          [1, 2, 3, 4, 5],
          ["F", "K", "M", "Mix", "O", "V"],
          ["01", "02", "03", "04"],
          AreaValues,
          OrgValue,
        );

        set_minMonthCaption(formatDateFromAPI(selectedYear + `0101`));
        set_maxMonthCaption(formatDateFromAPI(selectedYear + `1231`));
        setDataCharts(convertValueToInt(apiChart));
        setDataFilter(apiFilterType);
        setOpenSnackbar(true);
      }
      if (dataLineage !== null) {
        if (
          AgeValues.length !== 0 &&
          dataLineage.length !== 0 &&
          TypeValue.length !== 0 &&
          AreaValues.length !== 0 &&
          OrgValue.length !== 0
        ) {
          enableDisableButton(document.getElementById("search"), true);
          if (
            btnSearch === true ||
            minSelect !== minSelect_old ||
            maxSelect !== maxSelect_old
          ) {
            setFilterLoading(true);
            set_minSelected_old(minSelect);
            set_maxSelected_old(maxSelect);

            if (selectedYear !== YearOld) {
              set_minMonthCaption(formatDateFromAPI(selectedYear + `0101`));
              set_maxMonthCaption(formatDateFromAPI(selectedYear + `1231`));
              const apiChart = await getDataChartWeekly(
                selectedYear + `0101`,
                selectedYear + `1231`,
                AgeValues,
                dataLineage,
                TypeValue,
                OrgValue,
              );

              setDataCharts(convertValueToInt(apiChart));
              setYearOld(selectedYear);
            }
            if (minSelect === minSelect_old && maxSelect === maxSelect_old) {
              const apiChart = await getDataChartWeekly(
                selectedYear + `0101`,
                selectedYear + `1231`,
                AgeValues,
                dataLineage,
                TypeValue,
                AreaValues,
                OrgValue,
              );
              setDataCharts(convertValueToInt(apiChart));
            }
            try {
              // Fetch data concurrently (if possible)
              const [apiVillage, apiVillageSum] = await Promise.all([
                getDataVillage(
                  minSelect,
                  maxSelect,
                  AgeValues,
                  dataLineage,
                  TypeValue,
                  AreaValues,
                  OrgValue,
                ),
                getDataVillageSum(
                  minSelect,
                  maxSelect,
                  AgeValues,
                  dataLineage,
                  TypeValue,
                  AreaValues,
                  OrgValue,
                ),
              ]);

              setCaseSum(Number(apiVillageSum[0].case_sum));
              setDataVillage(
                convertDataVillageToGeoJSON(convertDataRank(apiVillage)),
              );
              setTop5(getTop5Villages(convertDataRank(apiVillage)));
              setYear(selectedYear);
              setBtnSearch(false);
              if (!loadApiLayerHouseHold.apiHouseHold) {
                setFilterLoading(false);
                setOpenSnackbar(true);
              }
            } catch (err) {
              console.log(err);
            }
          }
          setWarning(false);
        } else {
          setWarning(true);
          enableDisableButton(document.getElementById("search"), false);
        }
      }
      setLoading(false);
    },
    [
      dataSchool,
      dataFilter,
      dataLineage,
      setLoading,
      maxSelect,
      AgeValues,
      TypeValue,
      AreaValues,
      OrgValue,
      selectedYear,
      btnSearch,
      minSelect,
      minSelect_old,
      maxSelect_old,
      YearOld,
      setCaseSum,
      setDataVillage,
      setTop5,
      setYear,
      loadApiLayerHouseHold.apiHouseHold,
    ],
  );

  useEffect(() => {
    const abortControl = new AbortController();
    if (dataFilter !== null && dataLineage === null) {
      loadDataApi(abortControl);
      const lineage = dataFilter.map((obj) => obj.result_code_detail).join(",");
      setDataLineage(lineage.split(","));
    }
  }, [dataFilter, dataLineage, loadDataApi]);

  useEffect(() => {
    const abortControl = new AbortController();
    loadDataApi(abortControl);
    return () => {
      abortControl.abort();
    };
  }, [loadDataApi]);

  const loadDataApiHouseHoldCallback = useCallback(() => {
    const abortControl = new AbortController();
    loadDataApiHouseHold(
      loadApiLayerHouseHold,
      selectedYear,
      YearOld,
      minSelect,
      minStart_filter,
      maxSelect,
      maxStart_filter,
      AgeValues,
      dataLineage,
      TypeValue,
      AreaValues,
      OrgValue,
      setDataHouseHold,
      setFilterLoading,
      setOpenSnackbar,
      set_minStart_filter,
      set_maxStart_filter,
      btnSearch,
    );
    return () => {
      abortControl.abort();
    };
  }, [
    loadApiLayerHouseHold,
    selectedYear,
    YearOld,
    minSelect,
    minStart_filter,
    maxSelect,
    maxStart_filter,
    AgeValues,
    dataLineage,
    TypeValue,
    AreaValues,
    OrgValue,
    setDataHouseHold,
    setFilterLoading,
    setOpenSnackbar,
    set_minStart_filter,
    set_maxStart_filter,
    btnSearch,
  ]);

  useEffect(() => {
    return loadDataApiHouseHoldCallback();
  }, [loadDataApiHouseHoldCallback]);

  const loadDataApiLayerCallback = useCallback(() => {
    const abortControl = new AbortController();
    if (stateGo) {
      loadDataApiLayer(
        loadApiLayers,
        selectedYear,
        YearOld,
        setLoadApiLayers,
        setDataSchool,
        setGeoPolygonA1,
        setGeoPolygonA2,
        setGeoPolygonB1,
        setGeoPolygonB2,
        setGeoPolygonNA,
        setSumA1,
        setSumA2,
        setSumB1,
        setSumB2,
        setSumNA,
        setFilterLoading,
      );
    }
    return () => {
      abortControl.abort();
    };
  }, [
    stateGo,
    loadApiLayers,
    selectedYear,
    YearOld,
    setLoadApiLayers,
    setDataSchool,
    setGeoPolygonA1,
    setGeoPolygonA2,
    setGeoPolygonB1,
    setGeoPolygonB2,
    setGeoPolygonNA,
    setSumA1,
    setSumA2,
    setSumB1,
    setSumB2,
    setSumNA,
  ]);

  useEffect(() => {
    return loadDataApiLayerCallback();
  }, [loadDataApiLayerCallback]);

  const handleToggle = () => {
    setShowFilter(!showFilter);
  };
  if (minStart > maxStart) {
    setMinStart(0);
    set_minSelected(formatDateAPI(new Date(new Date().getFullYear(), 0, 1)));
    set_maxSelected(formatDateAPI(new Date()));
    set_minMonthCaption(formatDate(new Date(new Date().getFullYear(), 0, 1)));
    set_maxMonthCaption(formatDate(new Date()));
  }
  if (dataCharts && dataCharts.length > 0) {
    if (!indexEnd || indexEnd > dataCharts.length - 1) {
      setIndexEnd(dataCharts.length - 1);
    }
    if (indexStart > dataCharts.length - 1) {
      setIndexStart(0);
    }
  }

  return (
    <div>
      <FilterMainComponent
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showFilter={showFilter}
        handleToggle={handleToggle}
        selectedYear={selectedYear}
        handleYearChange={handleYearChange}
        years={years}
        minMonthCaption={minMonthCaption}
        maxMonthCaption={maxMonthCaption}
        dataType={dataType}
        TypeValue={TypeValue}
        setTypeValue={setTypeValue}
        dataAge={dataAge}
        AgeValues={AgeValues}
        setAgeValues={setAgeValues}
        dataArea={dataArea}
        AreaValues={AreaValues}
        setAreaValues={setAreaValues}
        dataFilter={dataFilter}
        dataLineage={dataLineage}
        setDataLineage={setDataLineage}
        dataOrgType={dataOrgType}
        OrgValue={OrgValue}
        setOrgValue={setOrgValue}
        warning={warning}
        handleConfirm={handleConfirm}
        FilterLoading={FilterLoading}
        handleClose={handleClose}
        OpenSnackbar={OpenSnackbar}
        errLocation={errLocation}
        loadLocation={loadLocation}
      />
      <div className="RangeDate">
        {dataCharts && (
          <RangeSelectorChart
            data={dataCharts}
            set_minMonthCaption={set_minMonthCaption}
            set_maxMonthCaption={set_maxMonthCaption}
            set_minSelected={set_minSelected}
            set_maxSelected={set_maxSelected}
            indexStart={indexStart}
            indexEnd={indexEnd}
            setIndexStart={setIndexStart}
            setIndexEnd={setIndexEnd}
          />
        )}
      </div>

      <div className="LoadingProgress">
        {FilterLoading && (
          <Box sx={{ width: "100%", zIndex: 5, position: "sticky" }}>
            <LinearProgress />
          </Box>
        )}
      </div>
    </div>
  );
};

export default FilterChartComponent;
