import { actionsMenu } from "../tools/Tools.jsx";
import NivoChartTop5 from "../tools/NivoChartTop5.jsx";
import NivoChartSum from "../tools/NivoChartSum.jsx";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { useState } from "react";
import { sortDateApi, formatDate, setDateYear } from "../tools/ConvertData";
import ActionMainComponent from "./ActionMainComponent";
import MarkerInfo from "../tools/MarkerInfo";

const ActionMapCalendarComponent = ({
  mapRender,
  open,
  setOpen,
  draw,
  distance,
  setDistance,
  setShowFilter,
  setShowMenu,
  layerShow,
  setLayerSchool,
  setLayerHouseHold,
  setLayerArea,
  setLayerRank,
  setLayerCase,
  caseSum,
  mapName,
  year,
  top5,
  setLoadApiLayers,
  setLoadApiLayerHouseHold,
  loadApiLayers,
  loadApiLayerHouseHold,
  setRangeShow,
  rangeShow,
  setRange,
  setErrLocation,
  setLoadLocation,
  sumA1,
  sumA2,
  sumB1,
  sumB2,
  sumNA,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showInfoSum, setShowSum] = useState(true);
  const [showInfo, setShowInfo] = useState(true);
  const [checked, setChecked] = useState(layerShow);
  const [showMarkerInfo, setShowMarkerInfo] = useState(true);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleActionClick = (actionName) => {
    if (actionName === "caseSum") {
      setShowSum((prevState) => !prevState);
    } else if (actionName === "top5") {
      setShowInfo((prevState) => !prevState);
    } else if (actionName === "marker") {
      setShowMarkerInfo((prevState) => !prevState);
    }
  };
  return (
    <div>
      {mapRender && (
        <>
          <ActionMainComponent
            setShowMenu={setShowMenu}
            mapName={mapName}
            setShowFilter={setShowFilter}
            openMenu={openMenu}
            handleClick={handleClick}
            anchorEl={anchorEl}
            handleClose={handleClose}
            mapRender={mapRender}
            open={open}
            distance={distance}
            setChecked={setChecked}
            checked={checked}
            setLayerSchool={setLayerSchool}
            setLayerHouseHold={setLayerHouseHold}
            setLayerArea={setLayerArea}
            setLayerRank={setLayerRank}
            setLayerCase={setLayerCase}
            loadApiLayers={loadApiLayers}
            loadApiLayerHouseHold={loadApiLayerHouseHold}
            setLoadApiLayers={setLoadApiLayers}
            setLoadApiLayerHouseHold={setLoadApiLayerHouseHold}
            setErrLocation={setErrLocation}
            setLoadLocation={setLoadLocation}
            setOpen={setOpen}
            draw={draw}
            setDistance={setDistance}
          />
          <div className="chartPosition">
            {rangeShow && (
              <div className="chartCalender">
                <div className="cardText">
                  {rangeShow.length === 1
                    ? formatDate(rangeShow[0])
                    : formatDate(sortDateApi(rangeShow)[0]) +
                      " ~ " +
                      formatDate(sortDateApi(rangeShow)[1])}
                  <Tooltip title="Clear Date" TransitionComponent={Zoom}>
                    <EventBusyOutlinedIcon
                      variant="contained"
                      className="btnCalendar"
                      onClick={() => {
                        setRange([
                          setDateYear(year, "start"),
                          setDateYear(year, "end"),
                        ]);
                        setRangeShow([
                          setDateYear(year, "start"),
                          setDateYear(year, "end"),
                        ]);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            )}
            {showInfoSum && caseSum && (
              <div className="chartCalendarSum">
                {/* <ChartJs year={year} caseSum={caseSum} /> */}
                <NivoChartSum year={year} caseSum={caseSum} Map={mapRender} />
                {/* <ChartBarSum year={year} caseSum={caseSum} /> */}
                {/* <MuiBarSum year={year} caseSum={caseSum} /> */}
                {/* <MuiTest /> */}
              </div>
            )}

            {showInfo && caseSum && (
              <div className="chartCalendarTop5">
                <h5 className="cardText">{`Top5`}</h5>
                <div className="cardChartCompare">
                  <div className="chartTop5One">
                    <NivoChartTop5
                      year={year}
                      data={top5}
                      Map={mapRender}
                      Side={"left"}
                    />
                  </div>
                </div>
              </div>
            )}
            {showMarkerInfo && caseSum && (
              <div className="markerInfoCalendar">
                <MarkerInfo
                  checked={checked}
                  sumA1={sumA1}
                  sumA2={sumA2}
                  sumB1={sumB1}
                  sumB2={sumB2}
                  sumNA={sumNA}
                />
              </div>
            )}
          </div>
          {caseSum && (
            <Box className="speedDial">
              <SpeedDial
                className="speedDialBtn"
                ariaLabel="SpeedDial"
                icon={<SpeedDialIcon />}
                FabProps={{
                  size: "medium",
                  style: { backgroundColor: global.color_main },
                }}
              >
                {actionsMenu(showInfoSum, showInfo, showMarkerInfo).map(
                  (action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => handleActionClick(action.value)} // onClick added
                      // sx={{ bgcolor: action.color, hover: "green" }}
                      sx={{
                        color: action.color, // Default color
                        bgcolor: action.colorBG, // Default text color
                        "&:hover": {
                          // Hover style
                          color: action.colorHover,
                          bgcolor: action.colorHoverBG,
                        },
                      }}
                    />
                  ),
                )}
              </SpeedDial>
            </Box>
          )}
        </>
      )}
    </div>
  );
};
export default ActionMapCalendarComponent;
