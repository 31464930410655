import getData from "./FetchData";
export const getDataSchool = () => {
  return getData(
    `https://malaria-api.ddc-malaria.org/school/getListSchoolLocation/province/${global.provinceSchool}`,
  );
};

export const getDataVillage = (start, end, age, lineage, type, area, org) => {
  return getData(
    `https://malaria-api.ddc-malaria.org/groupage/getListGroupAgeTotal/all/0/${start}/${end}/${age}/${lineage}/${type}/${area}/${org}`,
  );
};

export const getDataVillageSum = (
  start,
  end,
  age,
  lineage,
  type,
  area,
  org,
) => {
  return getData(
    `https://malaria-api.ddc-malaria.org/groupage/getGroupAgeTotal/all/0/${start}/${end}/${age}/${lineage}/${type}/${area}/${org}`,
  );
};

export const getDataFilterType = (start, end, age, type, area, org) => {
  return getData(
    `https://malaria-api.ddc-malaria.org/groupage/getListGroupAgeSliderResult/all/0/${start}/${end}/${age}/${type}/${area}/${org}`,
  );
};

// export const getDataChart = () => {
//   return getData(`https://6567faf09927836bd973f6ca.mockapi.io/dateChart`);
// };

export const getDataChartDaily = (
  start,
  end,
  age,
  lineage,
  type,
  area,
  org,
) => {
  return getData(
    `
    https://malaria-api.ddc-malaria.org/groupage/getGroupAgeTotalDaily/all/0/${start}/${end}/${age}/${lineage}/${type}/${area}/${org}`,
  );
};

export const getDataChartWeekly = (
  start,
  end,
  age,
  lineage,
  type,
  area,
  org,
) => {
  return getData(
    `https://malaria-api.ddc-malaria.org/groupage/getGroupAgeTotalWeekly/all/0/${start}/${end}/${age}/${lineage}/${type}/${area}/${org}`,
  );
};

export const getDataChartMonthly = (
  start,
  end,
  age,
  lineage,
  type,
  area,
  org,
) => {
  return getData(
    `
    https://malaria-api.ddc-malaria.org/groupage/getGroupAgeTotalMonthly/all/0/${start}/${end}/${age}/${lineage}/${type}/${area}/${org}`,
  );
};

export const getDataPolygon = (year, area) => {
  return getData(
    `https://malaria-api.ddc-malaria.org/poly/getPolyOnly/${year}/all/0/${area}`,
  );
};

export const getDataHouseHold = (start, end, age, lineage, type, area, org) => {
  return getData(
    `https://malaria-api.ddc-malaria.org/groupage/getListGroupAgePeople/all/0/${start}/${end}/${age}/${lineage}/${type}/${area}/${org}`,
  );
};

export const getDataHouseHoldByIndex = (index) => {
  return getData(
    `https://malaria-api.ddc-malaria.org/mhealth/plan/detail/indexcase/${index}`,
  );
};
