import React, { useState, useRef } from "react";
import * as turf from "@turf/turf";
import { BreadCrumbsMenu } from "../tools/Tools";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TimelineIcon from "@mui/icons-material/Timeline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import PentagonOutlinedIcon from "@mui/icons-material/PentagonOutlined";
import SensorOccupiedOutlinedIcon from "@mui/icons-material/SensorOccupiedOutlined";
import LocationSearchingOutlinedIcon from "@mui/icons-material/LocationSearchingOutlined";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import HolidayVillageOutlinedIcon from "@mui/icons-material/HolidayVillageOutlined";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Box from "@mui/material/Box";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MapsAddLocation from "../tools/MapsAddLocation";

const ActionIconComponent = ({
  setShowMenu,
  mapName,
  setShowFilter,
  openMenu,
  handleClick,
  anchorEl,
  handleClose,
  mapRender,
  open,
  distance,
  setChecked,
  checked,
  setLayerSchool,
  setLayerHouseHold,
  setLayerArea,
  setLayerRank,
  setLayerCase,
  loadApiLayers,
  loadApiLayerHouseHold,
  setLoadApiLayers,
  setLoadApiLayerHouseHold,
  setErrLocation,
  setLoadLocation,
  setOpen,
  draw,
  setDistance,
  mapRight = null,
  setConfirm = null,
}) => {
  const [fs, setFS] = useState(false);
  const eventListenerRefMove = useRef(null);
  const eventListenerRefClick = useRef(null);
  const [isGpsActive, setIsGpsActive] = useState(false);
  const handleToggle = (value, layer_name) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
      if (value === "School") {
        if (!loadApiLayers.apiSchool) {
          setLoadApiLayers((prevState) => ({
            ...prevState,
            apiSchool: true,
          }));
        }
        setLayerSchool("visible");
        mapRender.setLayoutProperty(layer_name[0], "visibility", "visible");
        if (mapRight) {
          mapRight.setLayoutProperty(layer_name[0], "visibility", "visible");
        }
      } else if (value === "HouseHold") {
        if (!loadApiLayerHouseHold.apiHouseHold) {
          setLoadApiLayerHouseHold((prevState) => ({
            ...prevState,
            apiHouseHold: true,
          }));
        }
        setLayerHouseHold("visible");
        mapRender.setLayoutProperty(layer_name[0], "visibility", "visible");
        mapRender.setLayoutProperty(layer_name[1], "visibility", "visible");
        mapRender.setLayoutProperty(layer_name[2], "visibility", "visible");
        // mapRender.setLayoutProperty(layer_name[3], "visibility", "visible");
        if (mapRight) {
          mapRight.setLayoutProperty(layer_name[0], "visibility", "visible");
          mapRight.setLayoutProperty(layer_name[1], "visibility", "visible");
          mapRight.setLayoutProperty(layer_name[2], "visibility", "visible");
        }
      } else if (value === "Rank") {
        setLayerRank("visible");
        mapRender.setLayoutProperty(layer_name[0], "visibility", "visible");
        if (mapRight) {
          mapRight.setLayoutProperty(layer_name[0], "visibility", "visible");
        }
      } else if (value === "Case") {
        setLayerCase("visible");
        mapRender.setLayoutProperty(layer_name[0], "visibility", "visible");
        mapRender.setLayoutProperty(layer_name[1], "visibility", "visible");
        mapRender.setLayoutProperty(layer_name[2], "visibility", "visible");
        mapRender.setLayoutProperty(layer_name[3], "visibility", "visible");

        if (mapRight) {
          mapRight.setLayoutProperty(layer_name[0], "visibility", "visible");
          mapRight.setLayoutProperty(layer_name[1], "visibility", "visible");
          mapRight.setLayoutProperty(layer_name[2], "visibility", "visible");
          mapRight.setLayoutProperty(layer_name[3], "visibility", "visible");
        }
        if (mapName === "MapCluster") {
          mapRender.setLayoutProperty(
            "clusters_village",
            "visibility",
            "visible",
          );

          mapRender.setLayoutProperty(
            "village_cluster_count",
            "visibility",
            "visible",
          );
        }

        document.getElementById("switch-list-rank").disabled = false;
      } else {
        if (value === "A1" && !loadApiLayers.apiA1) {
          if (mapRight) {
            setLoadApiLayers((prevState) => ({
              ...prevState,
              apiA1: true,
              apiA1_R: true,
            }));
            setConfirm(true);
          } else {
            setLoadApiLayers((prevState) => ({
              ...prevState,
              apiA1: true,
            }));
          }
        } else if (value === "A2" && !loadApiLayers.apiA2) {
          if (mapRight) {
            setLoadApiLayers((prevState) => ({
              ...prevState,
              apiA2: true,
              apiA2_R: true,
            }));
            setConfirm(true);
          } else {
            setLoadApiLayers((prevState) => ({
              ...prevState,
              apiA2: true,
            }));
          }
        } else if (value === "B1" && !loadApiLayers.apiB1) {
          if (mapRight) {
            setLoadApiLayers((prevState) => ({
              ...prevState,
              apiB1: true,
              apiB1_R: true,
            }));
            setConfirm(true);
          } else {
            setLoadApiLayers((prevState) => ({
              ...prevState,
              apiB1: true,
            }));
          }
        } else if (value === "B2" && !loadApiLayers.apiB2) {
          if (mapRight) {
            setLoadApiLayers((prevState) => ({
              ...prevState,
              apiB2: true,
              apiB2_R: true,
            }));
            setConfirm(true);
          } else {
            setLoadApiLayers((prevState) => ({
              ...prevState,
              apiB2: true,
            }));
          }
        } else if (value === "NA" && !loadApiLayers.apiNA) {
          if (mapRight) {
            setLoadApiLayers((prevState) => ({
              ...prevState,
              apiNA: true,
              apiNA_R: true,
            }));
            setConfirm(true);
          } else {
            setLoadApiLayers((prevState) => ({
              ...prevState,
              apiNA: true,
            }));
          }
        }
        setLayerArea("visible");
        mapRender.setLayoutProperty(layer_name[0], "visibility", "visible");
        mapRender.setLayoutProperty(layer_name[1], "visibility", "visible");
        if (mapRight) {
          mapRight.setLayoutProperty(layer_name[0], "visibility", "visible");
          mapRight.setLayoutProperty(layer_name[1], "visibility", "visible");
        }
      }
    } else {
      if (value === "School") {
        setLayerSchool("none");
        mapRender.setLayoutProperty(layer_name[0], "visibility", "none");
        if (mapRight) {
          mapRight.setLayoutProperty(layer_name[0], "visibility", "none");
        }
        newChecked.splice(currentIndex, 1);
      } else if (value === "HouseHold") {
        setLoadApiLayerHouseHold((prevState) => ({
          ...prevState,
          apiHouseHold: false,
        }));
        setLayerHouseHold("none");
        mapRender.setLayoutProperty(layer_name[0], "visibility", "none");
        mapRender.setLayoutProperty(layer_name[1], "visibility", "none");
        mapRender.setLayoutProperty(layer_name[2], "visibility", "none");

        if (mapRight) {
          mapRight.setLayoutProperty(layer_name[0], "visibility", "none");
          mapRight.setLayoutProperty(layer_name[1], "visibility", "none");
          mapRight.setLayoutProperty(layer_name[2], "visibility", "none");
        }
        newChecked.splice(currentIndex, 1);
        // mapRender.setLayoutProperty(layer_name[3], "visibility", "none");
      } else if (value === "Rank") {
        setLayerRank("none");
        mapRender.setLayoutProperty(layer_name[0], "visibility", "none");

        if (mapRight) {
          mapRight.setLayoutProperty(layer_name[0], "visibility", "none");
        }
        newChecked.splice(currentIndex, 1);
      } else if (value === "Case") {
        setLayerCase("none");
        mapRender.setLayoutProperty(layer_name[0], "visibility", "none");
        mapRender.setLayoutProperty(layer_name[1], "visibility", "none");
        mapRender.setLayoutProperty(layer_name[2], "visibility", "none");
        mapRender.setLayoutProperty(layer_name[3], "visibility", "none");

        if (mapRight) {
          mapRight.setLayoutProperty(layer_name[0], "visibility", "none");
          mapRight.setLayoutProperty(layer_name[1], "visibility", "none");
          mapRight.setLayoutProperty(layer_name[2], "visibility", "none");
          mapRight.setLayoutProperty(layer_name[3], "visibility", "none");
        }
        if (mapName === "MapCluster") {
          mapRender.setLayoutProperty("clusters_village", "visibility", "none");

          mapRender.setLayoutProperty(
            "village_cluster_count",
            "visibility",
            "none",
          );
        }
        if (newChecked.indexOf("Case") !== -1) {
          newChecked.splice(newChecked.indexOf("Case"), 1);
        }
        if (newChecked.indexOf("Rank") !== -1) {
          newChecked.splice(newChecked.indexOf("Rank"), 1);
        }
        setLayerRank("none");
        mapRender.setLayoutProperty("village_point_top", "visibility", "none");

        if (mapRight) {
          mapRight.setLayoutProperty("village_point_top", "visibility", "none");
        }
        document.getElementById("switch-list-rank").disabled = true;
      } else {
        setLayerArea("none");
        mapRender.setLayoutProperty(layer_name[0], "visibility", "none");
        mapRender.setLayoutProperty(layer_name[1], "visibility", "none");

        if (mapRight) {
          mapRight.setLayoutProperty(layer_name[0], "visibility", "none");
          mapRight.setLayoutProperty(layer_name[1], "visibility", "none");
        }

        newChecked.splice(currentIndex, 1);
      }
    }
    setChecked(newChecked);
  };

  const GpsOnClick = () => {
    if (isGpsActive) {
      MapsAddLocation(mapRender);
      if (mapRight) {
        MapsAddLocation(mapRight);
      }
      setIsGpsActive(false);
    } else {
      if (navigator.geolocation) {
        setLoadLocation(true);
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLoadLocation(false);
            MapsAddLocation(mapRender, position, true);
            if (mapRight) {
              MapsAddLocation(mapRight, position, true);
            }
            setIsGpsActive(true);
            setErrLocation(false);
          },
          () => {
            setLoadLocation(false);
            setErrLocation(true);
          },
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }
  };

  const fullScreen = () => {
    let elem = document.getElementById("MalariaMaps");

    try {
      if (fs) {
        document.exitFullscreen();
        setFS(false);
      } else {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
        setFS(true);
      }
    } catch (error) {
      return console.log("error", error);
    }
  };
  const ClearLine = () => {
    global.geojson.features = [];
    setDistance(null);
    mapRender.getCanvas().style.cursor = "";
    mapRender.getSource("geojson").setData(global.geojson);
    mapRender.off("mousemove", eventListenerRefMove.current);
    mapRender.off("click", eventListenerRefClick.current);
    setOpen(false);
  };
  const DrawMeasure = () => {
    if (open) {
      ClearLine();
    } else {
      const handleMouseClick = (e) => {
        const features = mapRender.queryRenderedFeatures(e.point, {
          layers: ["measure-points"],
        });
        if (global.geojson.features.length > 1) global.geojson.features.pop();

        if (features.length) {
          const id = features[0].properties.id;
          global.geojson.features = global.geojson.features.filter(
            (point) => point.properties.id !== id,
          );
        } else {
          const point = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [e.lngLat.lng, e.lngLat.lat],
            },
            properties: {
              id: String(new Date().getTime()),
            },
          };

          global.geojson.features.push(point);
        }

        if (global.geojson.features.length > 1) {
          global.linestring.geometry.coordinates = global.geojson.features.map(
            (point) => point.geometry.coordinates,
          );

          global.geojson.features.push(global.linestring);
          const distance = turf.length(global.linestring);
          setDistance(`${parseFloat(distance.toFixed(2))} km`);
        }

        mapRender.getSource("geojson").setData(global.geojson);
      };

      eventListenerRefClick.current = handleMouseClick;
      mapRender.on("click", eventListenerRefClick.current);

      const handleMouseMove = (e) => {
        const features = mapRender.queryRenderedFeatures(e.point, {
          layers: ["measure-points"],
        });

        mapRender.getCanvas().style.cursor = features.length
          ? "pointer"
          : "crosshair";
      };

      eventListenerRefMove.current = handleMouseMove;
      mapRender.on("mousemove", eventListenerRefMove.current);
      setOpen(true);
    }
  };

  const DrawPolygon = () => {
    if (open) {
      draw.trash();
      setDistance(null);
      setOpen(false);
    } else {
      draw.changeMode("draw_polygon");
      setOpen(true);
    }
  };

  const DrawTrash = () => {
    draw.trash();
    setDistance(null);
    ClearLine();
  };
  return (
    <div className="contentMenu">
      <div className="listMenu">
        <Stack spacing={2} className="breadcrumbsMenu">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {BreadCrumbsMenu(setShowMenu, mapName)}
          </Breadcrumbs>
        </Stack>
        <Tooltip title="Filter" TransitionComponent={Zoom}>
          <FilterListOutlinedIcon
            className="btnBasic"
            onClick={() => {
              setShowFilter(true);
            }}
          />
        </Tooltip>
        <Tooltip title="FullScreen" TransitionComponent={Zoom}>
          <FullscreenOutlinedIcon className="btnBasic" onClick={fullScreen} />
        </Tooltip>
        {/* <div className={classes.zoomBox}> */}
        <Tooltip title="Layers" TransitionComponent={Zoom}>
          <LayersOutlinedIcon
            id="basic-button"
            aria-controls={openMenu ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            onClick={handleClick}
            className="btnBasic"
          />
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
        >
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            subheader={<ListSubheader>Layers</ListSubheader>}
          >
            <ListItem>
              <ListItemIcon>
                <SensorOccupiedOutlinedIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-case" primary="Case" />
              <Switch
                edge="end"
                onChange={handleToggle("Case", [
                  "village_point",
                  "village_case_lbl",
                  "village_case_lbl_anime",
                  "village_name_lbl",
                ])}
                checked={checked.indexOf("Case") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-case",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LocationSearchingOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-rank"
                primary={global.rank_top_text}
              />
              <Switch
                id="switch-list-rank"
                edge="end"
                onChange={handleToggle("Rank", ["village_point_top"])}
                checked={checked.indexOf("Rank") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-rank",
                }}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <SchoolOutlinedIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-school" primary="School" />
              <Switch
                edge="end"
                onChange={handleToggle("School", ["school_lbl"])}
                checked={checked.indexOf("School") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-school",
                }}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PolylineOutlinedIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-a1" primary="A1" />
              <Switch
                edge="end"
                onChange={handleToggle("A1", [
                  "poly_a1_outline",
                  "poly_a1_fill",
                ])}
                checked={checked.indexOf("A1") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-polygon_a1",
                }}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PolylineOutlinedIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-a2" primary="A2" />
              <Switch
                edge="end"
                onChange={handleToggle("A2", [
                  "poly_a2_outline",
                  "poly_a2_fill",
                ])}
                checked={checked.indexOf("A2") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-polygon_a2",
                }}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PolylineOutlinedIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-b1" primary="B1" />
              <Switch
                edge="end"
                onChange={handleToggle("B1", [
                  "poly_b1_outline",
                  "poly_b1_fill",
                ])}
                checked={checked.indexOf("B1") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-polygon_b1",
                }}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PolylineOutlinedIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-b2" primary="B2" />
              <Switch
                edge="end"
                onChange={handleToggle("B2", [
                  "poly_b2_outline",
                  "poly_b2_fill",
                ])}
                checked={checked.indexOf("B2") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-polygon_b2",
                }}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PolylineOutlinedIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-na" primary="NA" />
              <Switch
                edge="end"
                onChange={handleToggle("NA", [
                  "poly_na_outline",
                  "poly_na_fill",
                ])}
                checked={checked.indexOf("NA") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-polygon_na",
                }}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <HolidayVillageOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-household"
                primary="M-Health Plan"
              />
              <Switch
                edge="end"
                onChange={handleToggle("HouseHold", [
                  "HouseHold_Y_lbl",
                  "HouseHold_N_point",
                  "HouseHold_N_Case_lbl",
                  // "HouseHold_N_Name_lbl",
                ])}
                checked={checked.indexOf("HouseHold") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-HouseHold",
                }}
              />
            </ListItem>
          </List>
        </Menu>

        <Tooltip title="Find My Location" TransitionComponent={Zoom}>
          <GpsFixedOutlinedIcon
            variant="contained"
            className={`btnBasic ${isGpsActive ? "active" : ""}`}
            onClick={GpsOnClick}
          />
        </Tooltip>
        <Tooltip title="Zoom In" TransitionComponent={Zoom}>
          <AddCircleOutlineOutlinedIcon
            variant="contained"
            className="btnBasic"
            // className={classes.btnZoom}
            onClick={() => {
              mapRender.zoomIn();
            }}
          />
        </Tooltip>
        <Tooltip title="Zoom Out" TransitionComponent={Zoom}>
          <RemoveCircleOutlineIcon
            variant="contained"
            className="btnBasic"
            // className={classes.btnZoom}
            onClick={() => {
              mapRender.zoomOut();
            }}
          />
        </Tooltip>
        {/* </div> */}
        <Tooltip title="Measure Distance" TransitionComponent={Zoom}>
          <TimelineIcon
            variant="contained"
            className="btnBasic"
            onClick={() => DrawMeasure()}
            style={{ backgroundColor: open ? "gray" : "white" }}
          />
        </Tooltip>
        <Tooltip title="Measure Polygon" TransitionComponent={Zoom}>
          <PentagonOutlinedIcon
            variant="contained"
            className="btnBasic"
            onClick={() => DrawPolygon()}
            style={{ backgroundColor: open ? "gray" : "white" }}
          />
        </Tooltip>
        <Tooltip title="Delete Measure" TransitionComponent={Zoom}>
          <DeleteOutlineIcon
            variant="contained"
            className="btnBasic"
            onClick={() => DrawTrash()}
          />
        </Tooltip>
      </div>
      {distance && (
        <Box component="section" className="cardDistance">
          {distance}
        </Box>
      )}
    </div>
  );
};

export default ActionIconComponent;
