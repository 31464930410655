export class MapsControlStyle {
  constructor(options) {
    this._options = { ...options };
    this._container = document.createElement("div");
    const styleClasses = this._container.classList;
    styleClasses.add("maplibregl-ctrl", "maplibregl-ctrl-basemaps", "closed");
    this._container.addEventListener("mouseenter", () => {
      styleClasses.remove("closed");
    });
    this._container.addEventListener("mouseleave", () => {
      styleClasses.add("closed");
    });
  }

  onAdd(map) {
    this._map = map;
    const basemapList = this._options.basemap;
    Object.keys(basemapList).forEach((mapId) => {
      const basemapContainer = document.createElement("img");
      const atributes = basemapList[mapId];
      basemapContainer.src = atributes.img;
      basemapContainer.classList.add("basemap", "hidden");
      basemapContainer.dataset.id = mapId;
      basemapContainer.addEventListener("click", () => {
        const activeElement = this._container.querySelector(".active");
        activeElement.classList.remove("active");
        basemapContainer.classList.add("active");
        let style_url = `${process.env.REACT_APP_MAPTILER_URL_START}${mapId}${process.env.REACT_APP_MAPTILER_URL_END}${process.env.REACT_APP_MAPTILER_APIKEY}`;
        map.setStyle(style_url, { diff: false });
        this._options.setNewStyle(true);
      });
      this._container.appendChild(basemapContainer);
      if (this._options.initialBasemap === mapId) {
        basemapContainer.classList.add("active");
      }
    });

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    delete this._map;
  }
}
