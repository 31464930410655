import React, { useState } from "react";
import { formatDateFromAPI } from "../tools/ConvertData";
import { ResponsiveContainer, AreaChart, Area, Brush, YAxis } from "recharts";

const RangeSelectorChart = ({
  data,
  set_minMonthCaption,
  set_maxMonthCaption,
  set_minSelected,
  set_maxSelected,
  indexStart,
  indexEnd,
  setIndexStart,
  setIndexEnd,
}) => {
  const [brushChangeTimeout, setBrushChangeTimeout] = useState(null);

  const handleBrushChange = (event) => {
    // Clear the previous timeout to avoid triggering the previous state
    if (brushChangeTimeout) {
      clearTimeout(brushChangeTimeout);
    }

    // console.log('Brush changed:', event);
    // console.log('Brush changed startIndex:', event.startIndex);
    // console.log('Brush changed startIndex:', event.endIndex);
    setIndexStart(event.startIndex);
    setIndexEnd(event.endIndex);
    // Set a new timeout for 1000 milliseconds (1 second)
    const newTimeout = setTimeout(() => {
      // Your code to handle the brush change after the delay
      // console.log('Brush changed:', event);
      set_minMonthCaption(formatDateFromAPI(data[event.startIndex].date_start));
      set_maxMonthCaption(formatDateFromAPI(data[event.endIndex].date_end));
      set_minSelected(data[event.startIndex].date_start);
      set_maxSelected(data[event.endIndex].date_end);
      setBrushChangeTimeout(null);
    }, 1000);

    // Save the timeout reference in the state
    setBrushChangeTimeout(newTimeout);
  };
  return (
    <div style={{ position: "relative", zIndex: 1 }}>
      <ResponsiveContainer width="100%" height={100}>
        {data && data.length !== 0 ? (
          <AreaChart data={data}>
            <Brush
              dataKey="date_lable"
              height={100}
              startIndex={indexStart}
              endIndex={indexEnd}
              // endIndex={data.length - 1}
              fill="#ffffff"
              travellerWidth={5}
              onChange={handleBrushChange}
            >
              <AreaChart>
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#FF9999"
                  fill="#ff3333"
                />
                <YAxis
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  hide={true}
                />
              </AreaChart>
            </Brush>
          </AreaChart>
        ) : (
          <h1 className="container text-center">No data</h1>
        )}
      </ResponsiveContainer>
    </div>
  );
};
export default RangeSelectorChart;
