import React, { useState } from "react";
import { actionsMenu } from "../tools/Tools";
import ChartBarTop5 from "../tools/ChartBarTop5Compare";
import ChartBar from "../tools/ChartBarSumCompare";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ActionMainComponent from "./ActionMainComponent";
import MarkerInfo from "../tools/MarkerInfo";

const ActionMapCompareComponent = ({
  MapLeft,
  MapRight,
  open,
  setOpen,
  draw,
  distance,
  setDistance,
  setShowFilter,
  setShowMenu,
  layerShow,
  setLayerSchool,
  setLayerHouseHold,
  setLayerArea,
  setLayerRank,
  setLayerCase,
  caseSum,
  caseRightSum,
  yearLeft,
  yearRight,
  top5Left,
  top5Right,
  mapName,
  setLoadApiLayers,
  setLoadApiLayerHouseHold,
  loadApiLayers,
  loadApiLayerHouseHold,
  setSwitchYear,
  switchYear,
  setErrLocation,
  setLoadLocation,
  setConfirm,
  sumA1,
  sumA2,
  sumB1,
  sumB2,
  sumNA,
  sumA1_R,
  sumA2_R,
  sumB1_R,
  sumB2_R,
  sumNA_R,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState(layerShow);
  // const [anchorInfo, setAnchorInfo] = useState(null);
  const [showInfoSum, setShowSum] = useState(true);
  const [showInfo, setShowInfo] = useState(true);
  const [showMarkerInfo, setShowMarkerInfo] = useState(true);
  const openMenu = Boolean(anchorEl);
  // const openInfo = Boolean(anchorInfo);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (actionName) => {
    if (actionName === "caseSum") {
      setShowSum((prevState) => !prevState);
    } else if (actionName === "top5") {
      setShowInfo((prevState) => !prevState);
    } else if (actionName === "year") {
      setSwitchYear((prevState) => !prevState);
    } else if (actionName === "marker") {
      setShowMarkerInfo((prevState) => !prevState);
    }
  };

  return (
    <div>
      <ActionMainComponent
        setShowMenu={setShowMenu}
        mapName={mapName}
        setShowFilter={setShowFilter}
        openMenu={openMenu}
        handleClick={handleClick}
        anchorEl={anchorEl}
        handleClose={handleClose}
        mapRender={MapLeft}
        open={open}
        distance={distance}
        setChecked={setChecked}
        checked={checked}
        setLayerSchool={setLayerSchool}
        setLayerHouseHold={setLayerHouseHold}
        setLayerArea={setLayerArea}
        setLayerRank={setLayerRank}
        setLayerCase={setLayerCase}
        loadApiLayers={loadApiLayers}
        loadApiLayerHouseHold={loadApiLayerHouseHold}
        setLoadApiLayers={setLoadApiLayers}
        setLoadApiLayerHouseHold={setLoadApiLayerHouseHold}
        setErrLocation={setErrLocation}
        setLoadLocation={setLoadLocation}
        setOpen={setOpen}
        draw={draw}
        setDistance={setDistance}
        mapRight={MapRight}
        setConfirm={setConfirm}
      />
      {/* {caseSum && (
        <Card className={classes.cardDetial}>
          {`Y:${yearLeft}=${caseSum} | Y:${yearRight}=${caseRightSum}`}
        </Card>
      )} */}
      <div className="chartPosition">
        {showInfoSum && caseSum && (
          <div className="chartSum">
            <ChartBar
              yearLeft={yearLeft}
              caseSum={caseSum}
              yearRight={yearRight}
              caseRightSum={caseRightSum}
              Map={MapLeft}
            />
          </div>
        )}

        {showInfo && caseSum && (
          <div className="chartTop5">
            <h5 className="cardText">{`Top5`}</h5>
            <div className="cardChartCompare">
              <div className="cardChartTop5Left">
                <ChartBarTop5
                  year={yearLeft}
                  data={top5Left}
                  Map={MapLeft}
                  Side={"left"}
                />
              </div>
              <Divider />
              <div className="cardChartTop5Right">
                <ChartBarTop5
                  year={yearRight}
                  data={top5Right}
                  Map={MapRight}
                  Side={"right"}
                />
              </div>
            </div>
          </div>
        )}
        {showMarkerInfo && caseSum && (
          <div className="markerInfoCompare">
            <MarkerInfo
              checked={checked}
              sumA1={sumA1}
              sumA2={sumA2}
              sumB1={sumB1}
              sumB2={sumB2}
              sumNA={sumNA}
              sumA1_R={sumA1_R}
              sumA2_R={sumA2_R}
              sumB1_R={sumB1_R}
              sumB2_R={sumB2_R}
              sumNA_R={sumNA_R}
              compare={true}
            />
          </div>
        )}
      </div>
      {caseSum && (
        <Box className="speedDial">
          <SpeedDial
            className="speedDialBtn"
            ariaLabel="SpeedDial"
            icon={<SpeedDialIcon />}
            FabProps={{
              size: "medium",
              style: { backgroundColor: global.color_main },
            }}
          >
            {actionsMenu(
              showInfoSum,
              showInfo,
              showMarkerInfo,
              switchYear,
              true,
            ).map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => handleActionClick(action.value)} // onClick added
                // sx={{ bgcolor: action.color, hover: "green" }}
                sx={{
                  color: action.color, // Default color
                  bgcolor: action.colorBG, // Default text color
                  "&:hover": {
                    // Hover style
                    color: action.colorHover,
                    bgcolor: action.colorHoverBG,
                  },
                }}
              />
            ))}
          </SpeedDial>
        </Box>
      )}
    </div>
  );
};

export default ActionMapCompareComponent;
