import axios from "axios";

const getData = async (url) => {
  try {
    // console.log(url);
    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    } else {
      return "error";
    }
  } catch (error) {
    console.error("error", error); // Use console.error for errors
    throw error; // Re-throw the error to propagate it if needed
  }
};

export default getData;

// const getData = async (url) => {
//   console.log(url);
//   var myHeaders = new Headers();
//   var requestOptions = {
//     method: "GET",
//     headers: myHeaders,
//     redirect: "follow",
//   };
//   try {
//     const response = await fetch(url, requestOptions);
//     if (response.status === 200) {
//       let result = await response.json();
//       return result;
//     } else return "error";
//   } catch (error) {
//     return console.log("error", error);
//   }
// };

// export default getData;
