const MapsAddLocation = (map, position = null, active = false) => {
  if (active) {
    global.pointLocation.features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [position.coords.longitude, position.coords.latitude],
      },
      properties: {},
    });

    map.getSource("location-source").setData(global.pointLocation);
    map.flyTo({
      center: [position.coords.longitude, position.coords.latitude],
      zoom: 10,
      essential: true,
    });
  } else {
    global.pointLocation.features = [];
    map.getSource("location-source").setData(global.pointLocation);
    map.flyTo({
      center: [global.longitude, global.latitude],
      zoom: global.zoom_start,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion media query
    });
  }
};

export default MapsAddLocation;
