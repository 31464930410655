import iconAnimation from "./IconAnimation";
import IconLocation from "./IconLocation";
import icon_school from "../../image/marker-icons/school-marker.png";
import icon_rank from "../../image/marker-icons/focus.png";
import icon_location from "../../image/marker-icons/location-marker32.png";
import icon_village from "../../image/marker-icons/village-marker.png";
import icon_house from "../../image/marker-icons/house-marker32.png";
import icon_village_yellow from "../../image/marker-icons/village-marker-yellow.png";

const MapsAddSource = (
  map,
  geojson_school,
  geojson_HouseHold_yes,
  geojson_HouseHold_no,
  geojson_village,
  layerSchool,
  layerHouseHold,
  layerArea,
  layerRank,
  layerCase,
  from,
  geoPolygonA1,
  geoPolygonA2,
  geoPolygonB1,
  geoPolygonB2,
  geoPolygonNA,
  setIndex,
) => {
  const addSource = () => {
    if (map.getImage("location-dot")) {
      map.removeImage("location-dot");
    }
    const locationDot = IconLocation(map);
    map.addImage("location-dot", locationDot, { pixelRatio: 2.5 });

    if (map.getImage("pulsing-dot")) {
      map.removeImage("pulsing-dot");
    }
    const pulsingDot = iconAnimation(map);
    map.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2.5 });

    map.loadImage(icon_school, (error, image) => {
      if (error) throw error;
      if (map.getImage("SchoolIcon")) {
        map.removeImage("SchoolIcon");
      }
      map.addImage("SchoolIcon", image);
    });

    map.loadImage(icon_location, (error, image) => {
      if (error) throw error;
      if (map.getImage("LocationIcon")) {
        map.removeImage("LocationIcon");
      }
      map.addImage("LocationIcon", image);
    });
    map.loadImage(icon_village, (error, image) => {
      if (error) throw error;
      if (map.getImage("VillageIcon")) {
        map.removeImage("VillageIcon");
      }
      map.addImage("VillageIcon", image);
    });
    map.loadImage(icon_village_yellow, (error, image) => {
      if (error) throw error;
      if (map.getImage("VillageYellowIcon")) {
        map.removeImage("VillageYellowIcon");
      }
      map.addImage("VillageYellowIcon", image);
    });

    map.loadImage(icon_house, (error, image) => {
      if (error) throw error;
      if (map.getImage("HouseIcon")) {
        map.removeImage("HouseIcon");
      }
      map.addImage("HouseIcon", image);
    });
    map.loadImage(icon_rank, (error, image) => {
      if (error) throw error;
      if (map.getImage("RankIcon")) {
        map.removeImage("RankIcon");
      }
      map.addImage("RankIcon", image);

      // map.addImage("RankIcon", image);

      if (map.getSource("poly_a1")) {
        map.removeLayer("poly_a1_fill");
        map.removeLayer("poly_a1_outline");
        map.removeSource("poly_a1");
      }
      map.addSource("poly_a1", {
        type: "geojson",
        data: geoPolygonA1,
      });

      map.addLayer({
        id: "poly_a1_fill",
        type: "fill",
        source: "poly_a1",
        layout: {
          visibility: layerArea,
        },
        paint: {
          "fill-color": global.color_a1,
          "fill-opacity": 0.2,
        },
      });

      map.addLayer({
        id: "poly_a1_outline",
        type: "line",
        source: "poly_a1",
        layout: {
          visibility: layerArea,
        },
        paint: {
          "line-color": global.color_a1,
          "line-width": 3,
        },
      });

      if (map.getSource("poly_a2")) {
        map.removeLayer("poly_a2_fill");
        map.removeLayer("poly_a2_outline");
        map.removeSource("poly_a2");
      }
      map.addSource("poly_a2", {
        type: "geojson",
        data: geoPolygonA2,
      });

      map.addLayer({
        id: "poly_a2_fill",
        type: "fill",
        source: "poly_a2",
        layout: {
          visibility: layerArea,
        },
        paint: {
          "fill-color": global.color_a2,
          "fill-opacity": 0.2,
        },
      });

      map.addLayer({
        id: "poly_a2_outline",
        type: "line",
        source: "poly_a2",
        layout: {
          visibility: layerArea,
        },
        paint: {
          "line-color": global.color_a2,
          "line-width": 3,
        },
      });

      if (map.getSource("poly_b1")) {
        map.removeLayer("poly_b1_fill");
        map.removeLayer("poly_b1_outline");
        map.removeSource("poly_b1");
      }
      map.addSource("poly_b1", {
        type: "geojson",
        data: geoPolygonB1,
      });

      map.addLayer({
        id: "poly_b1_fill",
        type: "fill",
        source: "poly_b1",
        layout: {
          visibility: layerArea,
        },
        paint: {
          "fill-color": global.color_b1,
          "fill-opacity": 0.2,
        },
      });

      map.addLayer({
        id: "poly_b1_outline",
        type: "line",
        source: "poly_b1",
        layout: {
          visibility: layerArea,
        },
        paint: {
          "line-color": global.color_b1,
          "line-width": 3,
        },
      });

      if (map.getSource("poly_b2")) {
        map.removeLayer("poly_b2_fill");
        map.removeLayer("poly_b2_outline");
        map.removeSource("poly_b2");
      }
      map.addSource("poly_b2", {
        type: "geojson",
        data: geoPolygonB2,
      });

      map.addLayer({
        id: "poly_b2_fill",
        type: "fill",
        source: "poly_b2",
        layout: {
          visibility: layerArea,
        },
        paint: {
          "fill-color": global.color_b2,
          "fill-opacity": 0.2,
        },
      });

      map.addLayer({
        id: "poly_b2_outline",
        type: "line",
        source: "poly_b2",
        layout: {
          visibility: layerArea,
        },
        paint: {
          "line-color": global.color_b2,
          "line-width": 3,
        },
      });

      if (map.getSource("poly_na")) {
        map.removeLayer("poly_na_fill");
        map.removeLayer("poly_na_outline");
        map.removeSource("poly_na");
      }
      map.addSource("poly_na", {
        type: "geojson",
        data: geoPolygonNA,
      });

      map.addLayer({
        id: "poly_na_fill",
        type: "fill",
        source: "poly_na",
        layout: {
          visibility: layerArea,
        },
        paint: {
          "fill-color": global.color_na,
          "fill-opacity": 0.2,
        },
      });

      map.addLayer({
        id: "poly_na_outline",
        type: "line",
        source: "poly_na",
        layout: {
          visibility: layerArea,
        },
        paint: {
          "line-color": global.color_na,
          "line-width": 3,
        },
      });

      if (map.getSource("school")) {
        map.removeLayer("school_lbl");
        map.removeSource("school");
      }
      map.addSource("school", {
        type: "geojson",
        data: geojson_school,
      });

      map.addLayer({
        id: "school_lbl",
        type: "symbol",
        source: "school",
        layout: {
          "icon-allow-overlap": true,
          "icon-image": "SchoolIcon",
          "icon-size": global.icon_size,
          "text-field": ["get", "school_name"],
          "text-font": ["Kanit"],
          "text-offset": [0, 0.9],
          "text-size": global.lbl_text_size_school,
          "text-anchor": "top",
          "text-optional": true,
          visibility: layerSchool,
        },
        paint: {
          "text-color":
            map.getStyle().name === "Streets" ||
            map.getStyle().name === "Backdrop"
              ? "black"
              : "white",
        },
      });

      map.on("click", "school_lbl", (e) => {
        map.flyTo({
          center: e.features[0].geometry.coordinates,
          zoom: 12,
        });
      });

      if (map.getSource("village")) {
        map.removeLayer("village_point_top");
        map.removeLayer("village_point");
        map.removeLayer("village_case_lbl");
        map.removeLayer("village_case_lbl_anime");
        map.removeLayer("village_name_lbl");
        if (from === "Cluster") {
          map.removeLayer("clusters_village");
          map.removeLayer("village_cluster_count");
        }
        map.removeSource("village");
      }
      if (from === "Cluster") {
        map.addSource("village", {
          type: "geojson",
          data: geojson_village,
          cluster: true,
          clusterRadius: 40,
          clusterProperties: {},
        });
        map.addLayer({
          id: "clusters_village",
          type: "circle",
          source: "village",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#51bbd6",
              100,
              "#f1f075",
              750,
              "#CCFF99",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              100,
              30,
              750,
              40,
            ],
          },
          layout: {
            visibility: layerCase,
          },
        });

        map.addLayer({
          id: "village_cluster_count",
          type: "symbol",
          source: "village",
          filter: ["has", "point_count"],
          layout: {
            "icon-allow-overlap": true,
            "text-field": "{point_count_abbreviated}",
            "text-font": ["Kanit"],
            "text-size": 12,
            visibility: layerCase,
          },
        });

        map.on("click", "village_cluster_count", (e) => {
          map.flyTo({
            center: e.features[0].geometry.coordinates,
            zoom: 11.5,
          });
        });

        // Change the cursor to a pointer when the it enters a feature in the 'symbols' layer.
        map.on("mouseenter", "village_cluster_count", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change it back to a pointer when it leaves.
        map.on("mouseleave", "village_cluster_count", () => {
          map.getCanvas().style.cursor = "";
        });
      } else {
        map.addSource("village", {
          type: "geojson",
          data: geojson_village,
          cluster: false,
        });
      }

      map.addLayer({
        id: "village_point",
        type: "circle",
        source: "village",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": global.color_uncluster,
          "circle-radius": 10,
          // "circle-stroke-width": 1,
        },
        layout: {
          visibility: layerCase,
        },
      });

      map.addLayer({
        id: "village_point_top",
        type: "symbol", // Change layer type to 'symbol' for icons
        source: "village",
        filter: [
          "all",
          ["<=", ["get", "Rank"], global.rank_top],
          ["!", ["has", "point_count"]],
        ],
        layout: {
          "icon-allow-overlap": true,
          "text-optional": true,
          "icon-image": "RankIcon",
          "icon-size": 1, // Adjust icon size as desired
          visibility: layerRank,
        },
      });

      map.addLayer({
        id: "village_case_lbl",
        type: "symbol",
        source: "village",
        filter: ["!=", "cluster", true],
        maxzoom: 9.5,
        layout: {
          "icon-allow-overlap": true,
          "text-optional": true,
          "text-field": [
            "number-format",
            ["get", "case_sum"],
            { "min-fraction-digits": 0, "max-fraction-digits": 2 },
          ],
          // "text-font": ["Kanit"],
          "text-font": ["Arial Unicode MS Bold", "Open Sans Semibold"],
          "text-size": global.lbl_text_size_case,
          visibility: layerCase,
        },
        paint: {
          "text-color":
            map.getStyle().name === "Streets" ||
            map.getStyle().name === "Backdrop"
              ? "black"
              : "white",
        },
      });

      map.addLayer({
        id: "village_case_lbl_anime",
        type: "symbol",
        source: "village",
        filter: ["!=", "cluster", true],
        minzoom: 9.6,
        layout: {
          "icon-allow-overlap": true,
          "text-optional": true,
          "icon-image": "pulsing-dot",
          "text-field": [
            "number-format",
            ["get", "case_sum"],
            { "min-fraction-digits": 0, "max-fraction-digits": 2 },
          ],
          // "text-font": ["Kanit"],
          "text-font": ["Arial Unicode MS Bold", "Open Sans Semibold"],
          "text-size": global.lbl_text_size_case,
          visibility: layerCase,
        },
        paint: {
          "text-color":
            map.getStyle().name === "Streets" ||
            map.getStyle().name === "Backdrop"
              ? "black"
              : "white",
        },
      });

      map.addLayer({
        id: "village_name_lbl",
        type: "symbol",
        source: "village",
        layout: {
          "icon-allow-overlap": true,
          "text-optional": true,
          "text-field": ["get", "village_name"],
          // "text-font": ["Kanit"],
          "text-font": ["Arial Unicode MS Bold", "Open Sans Semibold"],
          "text-offset": [0, 1.5],
          "text-size": global.lbl_text_size_village,
          "text-anchor": "top",
          visibility: layerCase,
        },
        paint: {
          "text-color":
            map.getStyle().name === "Streets" ||
            map.getStyle().name === "Backdrop"
              ? "black"
              : "white",
        },
      });
      if (map.getSource("HouseHold_N")) {
        map.removeLayer("HouseHold_N_Case_lbl");
        // map.removeLayer("HouseHold_N_Name_lbl");
        map.removeLayer("HouseHold_N_point");
        map.removeSource("HouseHold_N");
      }
      map.addSource("HouseHold_N", {
        type: "geojson",
        data: geojson_HouseHold_no,
      });

      // map.addLayer({
      //   id: "HouseHold_N_point",
      //   type: "circle",
      //   source: "HouseHold_N",
      //   filter: ["!", ["has", "point_count"]],
      //   paint: {
      //     "circle-color": global.color_warning,
      //     "circle-radius": 10,
      //     // "circle-stroke-width": 1,
      //   },
      //   layout: {
      //     visibility: layerHouseHold,
      //   },
      // });

      map.addLayer({
        id: "HouseHold_N_point",
        type: "symbol",
        source: "HouseHold_N",
        layout: {
          "icon-allow-overlap": true,
          "icon-image": "VillageYellowIcon",
          "icon-size": 1,
          "text-field": ["get", "village_name"],
          "text-font": ["Arial Unicode MS Bold", "Open Sans Semibold"],
          "text-offset": [0, 1.5],
          "text-size": global.lbl_text_size_village,
          "text-anchor": "top",
          "text-optional": true,
          visibility: layerHouseHold,
        },
        paint: {
          "text-color":
            map.getStyle().name === "Streets" ||
            map.getStyle().name === "Backdrop"
              ? "black"
              : "white",
        },
      });

      map.addLayer({
        id: "HouseHold_N_Case_lbl",
        type: "symbol",
        source: "HouseHold_N",
        filter: ["!=", "cluster", true],
        // maxzoom: 9.5,
        layout: {
          "icon-allow-overlap": true,
          "text-optional": true,
          "text-offset": [0, -0.3],
          "text-field": [
            "number-format",
            ["get", "case_sum"],
            { "min-fraction-digits": 0, "max-fraction-digits": 2 },
          ],
          "text-font": ["Arial Unicode MS Bold", "Open Sans Semibold"],
          "text-size": global.lbl_text_size_case,
          visibility: layerHouseHold,
        },
        paint: {
          "text-color":
            map.getStyle().name === "Streets" ||
            map.getStyle().name === "Backdrop"
              ? "black"
              : "white",
        },
      });

      map.on("click", "HouseHold_N_point", (e) => {
        map.flyTo({
          center: e.features[0].geometry.coordinates,
          zoom: 11.5,
        });
      });
      // map.addLayer({
      //   id: "HouseHold_N_Name_lbl",
      //   type: "symbol",
      //   source: "HouseHold_N",
      //   layout: {
      //     "icon-allow-overlap": true,
      //     "text-optional": true,
      //     "text-field": ["get", "village_name"],
      //     "text-font": ["Kanit"],
      //     "text-offset": [1.5, 1.5],
      //     "text-size": global.lbl_text_size_village,
      //     "text-anchor": "top",
      //     visibility: layerHouseHold,
      //   },
      //   paint: {
      //     "text-color":
      //       map.getStyle().name === "Streets" ||
      //       map.getStyle().name === "Backdrop"
      //         ? "black"
      //         : "white",
      //   },
      // });
      if (map.getSource("HouseHold_Y")) {
        map.removeLayer("HouseHold_Y_lbl");
        map.removeSource("HouseHold_Y");
      }

      map.addSource("HouseHold_Y", {
        type: "geojson",
        data: geojson_HouseHold_yes,
      });

      map.addLayer({
        id: "HouseHold_Y_lbl",
        type: "symbol",
        source: "HouseHold_Y",
        layout: {
          "icon-allow-overlap": true,
          "icon-image": "HouseIcon",
          "icon-size": global.icon_size,
          // "text-field": ["slice", ["get", "group_indexcase_id"], 0, 8],
          "text-field": [
            "concat",
            ["slice", ["get", "group_indexcase_id"], 0, 13],
            "\n",
            ["get", "village_name"],
          ],
          // "text-field": ["get", "group_indexcase_id"],
          // "text-font": ["Kanit"],
          "text-font": ["Arial Unicode MS Bold", "Open Sans Semibold"],
          "text-offset": [0, 1.2],
          "text-size": global.lbl_text_size_village,
          "text-anchor": "top",
          "text-optional": true,
          visibility: layerHouseHold,
        },
        paint: {
          "text-color":
            map.getStyle().name === "Streets" ||
            map.getStyle().name === "Backdrop"
              ? "black"
              : "white",
        },
      });
      map.on("click", "HouseHold_Y_lbl", (e) => {
        const indexId = e.features[0].properties.group_indexcase_id;

        setIndex(indexId);
        // const popupContent = document.createElement("div");
        // popupContent.className = "popup-content";
        // popupContent.innerHTML = `
        //   <p>Do you want to display Index: ${indexId} ?</p>
        //   <div class="popup-buttons">
        //     <button id="yesBtn" class="popup-button yes">Yes</button>
        //     <button id="noBtn" class="popup-button no">No</button>
        //   </div>
        // `;
        // const popup = new maplibregl.Popup({ className: "custom-popup" })
        //   .setLngLat(e.features[0].geometry.coordinates)
        //   .setDOMContent(popupContent)
        //   .addTo(map);

        // popupContent.querySelector("#yesBtn").addEventListener("click", () => {
        //   setIndex(indexId);
        //   popup.remove();
        // });

        // popupContent.querySelector("#noBtn").addEventListener("click", () => {
        //   popup.remove();
        // });

        map.flyTo({
          center: e.features[0].geometry.coordinates,
          zoom: 12,
        });
      });

      if (map.getSource("location-source")) {
        map.removeLayer("location-layer");
        map.removeSource("location-source");
      }

      map.addSource("location-source", {
        type: "geojson",
        data: global.pointLocation,
      });

      map.addLayer({
        id: "location-layer",
        type: "symbol",
        source: "location-source",
        layout: {
          "icon-image": "location-dot",
          "icon-allow-overlap": true,
        },
      });

      if (map.getSource("geojson")) {
        map.removeLayer("measure-points");
        map.removeLayer("measure-lines");
        map.removeSource("geojson");
      }
      map.addSource("geojson", {
        type: "geojson",
        data: global.geojson,
      });

      // Add styles to the map
      map.addLayer({
        id: "measure-points",
        type: "circle",
        source: "geojson",
        paint: {
          "circle-radius": 5,
          "circle-color": "#000",
        },
        filter: ["in", "$type", "Point"],
      });

      map.addLayer({
        id: "measure-lines",
        type: "line",
        source: "geojson",
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#000",
          "line-width": 2.5,
        },
        filter: ["in", "$type", "LineString"],
      });

      // Change the cursor to a pointer when the it enters a feature in the 'symbols' layer.
      map.on("mouseenter", "school_lbl", () => {
        map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      map.on("mouseleave", "school_lbl", () => {
        map.getCanvas().style.cursor = "";
      });

      map.on("click", "village_point", (e) => {
        map.flyTo({
          center: e.features[0].geometry.coordinates,
          zoom: 11.5,
        });
      });

      // Change the cursor to a pointer when the it enters a feature in the 'symbols' layer.
      map.on("mouseenter", "village_point", () => {
        map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      map.on("mouseleave", "village_point", () => {
        map.getCanvas().style.cursor = "";
      });

      const style = document.createElement("style");
      style.textContent = `
        .custom-popup {
          font-family: 'Arial', sans-serif;
          border-radius: 8px;
          background-color: rgba(255, 255, 255, 0.9);
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          padding: 15px;
        }
        .custom-popup .maplibregl-popup-content {
          padding: 0;
          background: none;
        }
        .custom-popup .maplibregl-popup-tip {
          border-top-color: rgba(255, 255, 255, 0.9);
        }
        .popup-content {
          text-align: center;
        }
        .popup-content p {
          margin: 0 0 15px;
          font-size: 16px;
          color: #333;
        }
        .popup-buttons {
          display: flex;
          justify-content: space-around;
        }
        .popup-button {
          padding: 8px 15px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;
          font-size: 14px;
        }
        .popup-button.yes {
          background-color: #4CAF50;
          color: white;
        }
        .popup-button.no {
          background-color: #f44336;
          color: white;
        }
        .popup-button:hover {
          opacity: 0.8;
        }
      `;
      document.head.appendChild(style);
    });
  };

  return addSource;
};

export default MapsAddSource;
