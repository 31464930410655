// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.blob {
  width: 48px;
  height: 48px;
  display: grid;
  background: #fff;
  filter: blur(1.9px) contrast(10);
  padding: 4.8px;
  mix-blend-mode: darken;
}

.blob:before {
  content: "";
  grid-area: 1/1;
  margin: 14.4px 0;
  border-radius: 48px;
  background: #474bff;
}

.blob:after {
  content: "";
  grid-area: 1/1;
  width: 16.8px;
  height: 16.8px;
  margin: auto;
  border-radius: 50%;
  background: #474bff;
  animation: blob-lwysv3mn 1.6s infinite linear;
}

@keyframes blob-lwysv3mn {
  0% {
    transform: translate(14.4px);
  }

  25% {
    transform: translate(0);
  }

  50% {
    transform: translate(-14.4px);
  }

  75% {
    transform: translate(0);
  }

  100% {
    transform: translate(14.4px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/LoadingSpinner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,0CAA0C;EAC1C,kBAAkB;EAClB,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,gCAAgC;EAChC,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,aAAa;EACb,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,6CAA6C;AAC/C;;AAEA;EACE;IACE,4BAA4B;EAC9B;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".loading-spinner {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.spinner {\n  border: 4px solid rgba(255, 255, 255, 0.3);\n  border-radius: 50%;\n  border-top: 4px solid #3498db;\n  width: 50px;\n  height: 50px;\n  animation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.blob {\n  width: 48px;\n  height: 48px;\n  display: grid;\n  background: #fff;\n  filter: blur(1.9px) contrast(10);\n  padding: 4.8px;\n  mix-blend-mode: darken;\n}\n\n.blob:before {\n  content: \"\";\n  grid-area: 1/1;\n  margin: 14.4px 0;\n  border-radius: 48px;\n  background: #474bff;\n}\n\n.blob:after {\n  content: \"\";\n  grid-area: 1/1;\n  width: 16.8px;\n  height: 16.8px;\n  margin: auto;\n  border-radius: 50%;\n  background: #474bff;\n  animation: blob-lwysv3mn 1.6s infinite linear;\n}\n\n@keyframes blob-lwysv3mn {\n  0% {\n    transform: translate(14.4px);\n  }\n\n  25% {\n    transform: translate(0);\n  }\n\n  50% {\n    transform: translate(-14.4px);\n  }\n\n  75% {\n    transform: translate(0);\n  }\n\n  100% {\n    transform: translate(14.4px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
