import React, { useState, useEffect, useCallback } from "react";
import "../../css/App.css";
import "../../css/Style.css";
import {
  getDataVillage,
  getDataFilterType,
  getDataVillageSum,
} from "../../api/DataAPI";
import {
  getTop5Villages,
  convertDataRank,
  convertDataVillageToGeoJSON,
  getDayOfYear,
  getDayAgo,
  formatDateAPI,
  formatDate,
} from "../tools/ConvertData";
import {
  enableDisableButton,
  area,
  dataArea,
  age_group,
  dataAge,
  type,
  dataType,
  orgType,
  dataOrgType,
  currentYear,
  years,
  monthNames,
} from "../../data/FilterData";
import "../../global";
import MultiRangeSlider from "multi-range-slider-react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import FilterMainComponent from "./FilterMainComponent";
import { loadDataApiLayer, loadDataApiHouseHold } from "../tools/LayerData";

const FilterComponent = ({
  setLoading,
  setDataVillage,
  setShowFilter,
  showFilter,
  setShowMenu,
  showMenu,
  dataSchool,
  setDataSchool,
  setGeoPolygonA1,
  setGeoPolygonA2,
  setGeoPolygonB1,
  setGeoPolygonB2,
  setGeoPolygonNA,
  setSumA1,
  setSumA2,
  setSumB1,
  setSumB2,
  setSumNA,
  setCaseSum,
  setYear,
  setTop5,
  setLoadApiLayers,
  loadApiLayers,
  loadApiLayerHouseHold,
  errLocation,
  setErrLocation,
  loadLocation,
  setDataHouseHold,
}) => {
  const [stateGo, setStateGo] = useState(true);
  const [btnSearch, setBtnSearch] = useState(true);
  const [minMonthCaption, set_minMonthCaption] = useState("");
  const [maxMonthCaption, set_maxMonthCaption] = useState("");
  // const [minSelect, set_minSelected] = useState(global.start_date);
  // const [maxSelect, set_maxSelected] = useState(global.end_date);
  const [minSelect, set_minSelected] = useState(
    formatDateAPI(getDayAgo(new Date(), global.start_date)),
  );
  const [maxSelect, set_maxSelected] = useState(
    formatDateAPI(getDayAgo(new Date(), global.end_date)),
  );
  const [minSelect_old, set_minSelected_old] = useState(
    formatDateAPI(getDayAgo(new Date(), global.start_date)),
  );
  const [maxSelect_old, set_maxSelected_old] = useState(
    formatDateAPI(getDayAgo(new Date(), global.end_date)),
  );
  const [minStart_filter, set_minStart_filter] = useState(null);
  const [maxStart_filter, set_maxStart_filter] = useState(null);
  const [minStart, setMinStart] = useState(
    getDayOfYear(getDayAgo(new Date(), global.start_date)),
  );
  const [maxStart] = useState(getDayOfYear(new Date()));
  const [OpenSnackbar, setOpenSnackbar] = useState(false);
  const [FilterLoading, setFilterLoading] = useState(true);
  const [warning, setWarning] = useState(false);
  const [dataFilter, setDataFilter] = useState(null);
  const [dataLineage, setDataLineage] = useState(null);
  const [OrgValue, setOrgValue] = useState(orgType);
  const [TypeValue, setTypeValue] = useState(type);
  const [AgeValues, setAgeValues] = useState(age_group);
  const [AreaValues, setAreaValues] = useState(area);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [YearOld, setYearOld] = useState(currentYear);
  const [yearDays, setYearDays] = useState(
    365 + (selectedYear % 4 === 0 ? 1 : 0),
  );
  const handleClose = (reason) => {
    if (reason === "clickAway") {
      return;
    }
    setOpenSnackbar(false);
    setErrLocation(false);
  };

  const handleConfirm = () => {
    if (
      AgeValues.length !== 0 &&
      dataLineage.length !== 0 &&
      TypeValue.length !== 0 &&
      AreaValues.length !== 0
    ) {
      setBtnSearch(true);
      setShowFilter(!showFilter);
      setStateGo(true);
    }
  };
  const [minCurrent] = useState(0);
  const [maxCurrent] = useState(yearDays - 1);

  const handleYearChange = (event) => {
    let year = event.target.value;
    let min = year + minSelect.substring(4, 8);
    let max = year + maxSelect.substring(4, 8);
    setSelectedYear(year);
    setYearDays(365 + (year % 4 === 0 ? 1 : 0));
    set_minSelected(min);
    set_maxSelected(max);
    set_minSelected_old(min);
    set_maxSelected_old(max);
    setStateGo(false);
  };

  const handleDateChange = (e) => {
    let dd1 = new Date(selectedYear, 0, 1);
    let dd2 = new Date(selectedYear, 0, 1);
    dd1.setDate(e.minValue + 1);
    dd2.setDate(e.maxValue + 1);
    set_minMonthCaption(formatDate(dd1));
    set_maxMonthCaption(formatDate(dd2));
  };

  const DateChanged = (e) => {
    let dd1 = new Date(selectedYear, 0, 1);
    let dd2 = new Date(selectedYear, 0, 1);
    dd1.setDate(e.minValue + 1);
    dd2.setDate(e.maxValue + 1);
    set_minSelected(formatDateAPI(dd1));
    set_maxSelected(formatDateAPI(dd2));
  };

  const loadDataApi = useCallback(
    async (abortControl) => {
      if (dataSchool === null && dataFilter === null) {
        const apiFilterType = await getDataFilterType(
          global.start_date_year,
          maxSelect,
          AgeValues,
          TypeValue,
          AreaValues,
          OrgValue,
        );
        setDataFilter(apiFilterType);
        setOpenSnackbar(true);
      }

      if (dataLineage !== null) {
        if (
          AgeValues.length !== 0 &&
          dataLineage.length !== 0 &&
          TypeValue.length !== 0 &&
          AreaValues.length !== 0 &&
          OrgValue.length !== 0
        ) {
          enableDisableButton(document.getElementById("search"), true);
          if (
            btnSearch === true ||
            minSelect !== minSelect_old ||
            maxSelect !== maxSelect_old
          ) {
            setFilterLoading(true);
            set_minSelected_old(minSelect);
            set_maxSelected_old(maxSelect);

            if (selectedYear !== YearOld) {
              setYearOld(selectedYear);
            }
            try {
              // Fetch data concurrently (if possible)
              const [apiVillage, apiVillageSum] = await Promise.all([
                getDataVillage(
                  minSelect,
                  maxSelect,
                  AgeValues,
                  dataLineage,
                  TypeValue,
                  AreaValues,
                  OrgValue,
                ),
                getDataVillageSum(
                  minSelect,
                  maxSelect,
                  AgeValues,
                  dataLineage,
                  TypeValue,
                  AreaValues,
                  OrgValue,
                ),
              ]);
              setCaseSum(Number(apiVillageSum[0].case_sum));
              setDataVillage(
                convertDataVillageToGeoJSON(convertDataRank(apiVillage)),
              );
              setTop5(getTop5Villages(convertDataRank(apiVillage)));
              setYear(selectedYear);
              setBtnSearch(false);
              if (!loadApiLayerHouseHold.apiHouseHold) {
                setFilterLoading(false);
                setOpenSnackbar(true);
              }
            } catch (err) {
              console.log(err);
            }
          }
          setWarning(false);
        } else {
          setWarning(true);
          enableDisableButton(document.getElementById("search"), false);
        }
      }
      setLoading(false);
    },
    [
      dataSchool,
      dataFilter,
      dataLineage,
      setLoading,
      maxSelect,
      AgeValues,
      TypeValue,
      AreaValues,
      OrgValue,
      btnSearch,
      minSelect,
      minSelect_old,
      maxSelect_old,
      selectedYear,
      YearOld,
      setCaseSum,
      setDataVillage,
      setTop5,
      setYear,
      loadApiLayerHouseHold.apiHouseHold,
    ],
  );

  useEffect(() => {
    const abortControl = new AbortController();
    if (dataFilter !== null && dataLineage === null) {
      loadDataApi(abortControl);
      const lineage = dataFilter.map((obj) => obj.result_code_detail).join(",");
      setDataLineage(lineage.split(","));
    }
  }, [dataFilter, dataLineage, loadDataApi]);

  useEffect(() => {
    const abortControl = new AbortController();
    loadDataApi(abortControl);
    return () => {
      abortControl.abort();
    };
  }, [loadDataApi]);

  const loadDataApiHouseHoldCallback = useCallback(() => {
    const abortControl = new AbortController();
    loadDataApiHouseHold(
      loadApiLayerHouseHold,
      selectedYear,
      YearOld,
      minSelect,
      minStart_filter,
      maxSelect,
      maxStart_filter,
      AgeValues,
      dataLineage,
      TypeValue,
      AreaValues,
      OrgValue,
      setDataHouseHold,
      setFilterLoading,
      setOpenSnackbar,
      set_minStart_filter,
      set_maxStart_filter,
      btnSearch,
    );
    return () => {
      abortControl.abort();
    };
  }, [
    loadApiLayerHouseHold,
    selectedYear,
    YearOld,
    minSelect,
    minStart_filter,
    maxSelect,
    maxStart_filter,
    AgeValues,
    dataLineage,
    TypeValue,
    AreaValues,
    OrgValue,
    setDataHouseHold,
    setFilterLoading,
    setOpenSnackbar,
    set_minStart_filter,
    set_maxStart_filter,
    btnSearch,
  ]);

  useEffect(() => {
    return loadDataApiHouseHoldCallback();
  }, [loadDataApiHouseHoldCallback]);

  const loadDataApiLayerCallback = useCallback(() => {
    const abortControl = new AbortController();
    if (stateGo) {
      loadDataApiLayer(
        loadApiLayers,
        selectedYear,
        YearOld,
        setLoadApiLayers,
        setDataSchool,
        setGeoPolygonA1,
        setGeoPolygonA2,
        setGeoPolygonB1,
        setGeoPolygonB2,
        setGeoPolygonNA,
        setSumA1,
        setSumA2,
        setSumB1,
        setSumB2,
        setSumNA,
        setFilterLoading,
      );
    }
    return () => {
      abortControl.abort();
    };
  }, [
    stateGo,
    loadApiLayers,
    selectedYear,
    YearOld,
    setLoadApiLayers,
    setDataSchool,
    setGeoPolygonA1,
    setGeoPolygonA2,
    setGeoPolygonB1,
    setGeoPolygonB2,
    setGeoPolygonNA,
    setSumA1,
    setSumA2,
    setSumB1,
    setSumB2,
    setSumNA,
  ]);

  useEffect(() => {
    return loadDataApiLayerCallback();
  }, [loadDataApiLayerCallback]);

  const handleToggle = () => {
    setShowFilter(!showFilter);
  };

  if (minStart > maxStart) {
    setMinStart(0);
    set_minSelected(formatDateAPI(new Date(new Date().getFullYear(), 0, 1)));
    // set_minSelected((formatDateAPI(getDayAgo(new Date(), global.end_date))).toString())
  }
  return (
    <div>
      <FilterMainComponent
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showFilter={showFilter}
        handleToggle={handleToggle}
        selectedYear={selectedYear}
        handleYearChange={handleYearChange}
        years={years}
        minMonthCaption={minMonthCaption}
        maxMonthCaption={maxMonthCaption}
        dataType={dataType}
        TypeValue={TypeValue}
        setTypeValue={setTypeValue}
        dataAge={dataAge}
        AgeValues={AgeValues}
        setAgeValues={setAgeValues}
        dataArea={dataArea}
        AreaValues={AreaValues}
        setAreaValues={setAreaValues}
        dataFilter={dataFilter}
        dataLineage={dataLineage}
        setDataLineage={setDataLineage}
        dataOrgType={dataOrgType}
        OrgValue={OrgValue}
        setOrgValue={setOrgValue}
        warning={warning}
        handleConfirm={handleConfirm}
        FilterLoading={FilterLoading}
        handleClose={handleClose}
        OpenSnackbar={OpenSnackbar}
        errLocation={errLocation}
        loadLocation={loadLocation}
      />
      <div className="multi-range-slider-container-new">
        <MultiRangeSlider
          labels={monthNames}
          min={minCurrent}
          max={maxCurrent}
          minValue={minStart - 1}
          maxValue={maxStart - 1}
          step={1}
          minCaption={minMonthCaption}
          maxCaption={maxMonthCaption}
          onInput={handleDateChange}
          onChange={DateChanged}
          baseClassName="multi-range-slider-custom"
        />
      </div>
      <div className="LoadingProgress">
        {FilterLoading && (
          <Box sx={{ width: "100%", zIndex: 5, position: "sticky" }}>
            <LinearProgress />
          </Box>
        )}
      </div>
    </div>
  );
};

export default FilterComponent;
