import "../global";

const startYear = global.year_start;

export const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "",
];
export const monthsOfYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
// export const yearDays = 365 + (new Date().getFullYear() % 4 === 0 ? 1 : 0);

export const currentYear = new Date().getFullYear();

export const years = Array.from(
  { length: currentYear - startYear + 1 },
  (_, index) => startYear + index,
);

export const age_group = ["1", "2", "3", "4", "5"];
export const dataAge = [
  { label: "<5", value: "1" },
  { label: ">=5 ,<15", value: "2" },
  { label: ">=15 , <25", value: "3" },
  { label: ">=25 , <45", value: "4" },
  { label: ">=45", value: "5" },
];

export const type = ["01", "02", "03", "04"];
export const dataType = [
  { label: "คนไทย", value: "01" },
  { label: "ต.1", value: "02" },
  { label: "ต.2", value: "03" },
  { label: "ผู้อพยพ", value: "04" },
];
// export const orgType = ["odpc", "boe", "camp"];
export const orgType = ["odpc"];
export const dataOrgType = [
  { label: "ODPC", value: "odpc", check: true },
  { label: "BOE", value: "boe", check: false },
  { label: "CAMP", value: "camp", check: false },
];
export const area = [
  "A",
  "Bx",
  "By",
  "Bz",
  "Bo",
  "Bf",
  "F",
  "C",
  "D",
  "E",
  "G",
  "NA",
];
export const dataArea = [
  { label: "A", value: "A" },
  { label: "Bx", value: "Bx" },
  { label: "By", value: "By" },
  { label: "Bz", value: "Bz" },
  { label: "Bo", value: "Bo" },
  { label: "Bf", value: "Bf" },
  { label: "C", value: "F" },
  { label: "D", value: "C" },
  { label: "E", value: "D" },
  { label: "F", value: "E" },
  { label: "G", value: "G" },
  { label: "NA", value: "NA" },
];

export const enableDisableButton = (buttonElement, isEnabled) => {
  if (buttonElement) {
    buttonElement.disabled = !isEnabled;
    buttonElement.style.backgroundColor = isEnabled ? "RoyalBlue" : "gray"; // Set colors
  } else {
    console.error("Error: Button element not found!");
  }
};
