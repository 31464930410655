import { useState, useEffect, useCallback } from "react";
import {
  getDataVillage,
  getDataFilterType,
  getDataVillageSum,
  getDataChartDaily,
} from "../../api/DataAPI";
import {
  getTop5Villages,
  convertDataRank,
  convertDataVillageToGeoJSON,
  getDayOfYear,
  getDayAgo,
  formatDate,
  formatDateAPI,
  convertDataToChartCalendar,
  sortDateApi,
  setDateYear,
} from "../tools/ConvertData";
import { CheckApiDown } from "../tools/Tools";
import {
  enableDisableButton,
  area,
  dataArea,
  age_group,
  dataAge,
  type,
  dataType,
  currentYear,
  years,
  orgType,
  dataOrgType,
} from "../../data/FilterData";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import NivoCalendar from "../tools/NivoCalendar";
import FilterMainComponent from "./FilterMainComponent";
import { loadDataApiLayer, loadDataApiHouseHold } from "../tools/LayerData";

const FilterCalendarComponent = ({
  setLoading,
  setDataVillage,
  setShowFilter,
  showFilter,
  setShowMenu,
  showMenu,
  setDataSchool,
  setGeoPolygonA1,
  setGeoPolygonA2,
  setGeoPolygonB1,
  setGeoPolygonB2,
  setGeoPolygonNA,
  setSumA1,
  setSumA2,
  setSumB1,
  setSumB2,
  setSumNA,
  setCaseSum,
  setYear,
  setTop5,
  setLoadApiLayers,
  loadApiLayers,
  loadApiLayerHouseHold,
  setApiCheck,
  setRangeShow,
  setRange,
  range,
  errLocation,
  setErrLocation,
  loadLocation,
  setDataHouseHold,
}) => {
  const [stateGo, setStateGo] = useState(true);
  const [btnSearch, setBtnSearch] = useState(true);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [YearOld, setYearOld] = useState(currentYear);
  const [minMonthCaption, set_minMonthCaption] = useState("");
  const [maxMonthCaption, set_maxMonthCaption] = useState("");
  const [dataCalendar, setDataCalendar] = useState(null);
  const [minDate, setMinDate] = useState(setDateYear(selectedYear, "start"));
  const [maxDate, setMaxDate] = useState(setDateYear(selectedYear, "end"));
  const [selectedRange, setSelectedRange] = useState([
    setDateYear(selectedYear, "start"),
    setDateYear(selectedYear, "end"),
  ]);
  const [minSelect, set_minSelected] = useState(
    formatDateAPI(setDateYear(selectedYear, "start")),
  );
  const [maxSelect, set_maxSelected] = useState(
    formatDateAPI(setDateYear(selectedYear, "end")),
  );
  const [minSelect_old, set_minSelected_old] = useState(
    formatDateAPI(getDayAgo(new Date(), global.start_date)),
  );
  const [maxSelect_old, set_maxSelected_old] = useState(
    formatDateAPI(getDayAgo(new Date(), global.end_date)),
  );
  const [minStart_filter, set_minStart_filter] = useState(null);
  const [maxStart_filter, set_maxStart_filter] = useState(null);
  const [minStart, setMinStart] = useState(
    getDayOfYear(getDayAgo(new Date(), global.start_date)),
  );
  const [maxStart] = useState(getDayOfYear(new Date()));
  const [OpenSnackbar, setOpenSnackbar] = useState(false);
  const [FilterLoading, setFilterLoading] = useState(true);
  const [warning, setWarning] = useState(false);
  const [dataFilter, setDataFilter] = useState(null);
  const [dataLineage, setDataLineage] = useState(null);
  const [OrgValue, setOrgValue] = useState(orgType);
  const [TypeValue, setTypeValue] = useState(type);
  const [AgeValues, setAgeValues] = useState(age_group);
  const [AreaValues, setAreaValues] = useState(area);
  // const [yearDays, setYearDays] = useState(
  //   365 + (selectedYear % 4 === 0 ? 1 : 0),
  // );

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setErrLocation(false);
  };
  const handleYearChange = (event) => {
    let year = event.target.value;
    let min = year + minSelect.substring(4, 8);
    let max = year + maxSelect.substring(4, 8);
    setSelectedYear(year);
    // setYearDays(365 + (year % 4 === 0 ? 1 : 0));
    set_minSelected(min);
    set_maxSelected(max);
    set_minSelected_old(min);
    set_maxSelected_old(max);
    setRangeShow([setDateYear(year, "start"), setDateYear(year, "end")]);
    setStateGo(false);
  };

  const handleConfirm = () => {
    if (
      AgeValues.length !== 0 &&
      dataLineage.length !== 0 &&
      TypeValue.length !== 0 &&
      AreaValues.length !== 0
    ) {
      setBtnSearch(true);
      setShowFilter(!showFilter);
      setStateGo(true);
    }
  };

  const loadApiDaily = useCallback(
    async (lineage) => {
      try {
        let rangeStart = sortDateApi(selectedRange);
        const apiDaily = await getDataChartDaily(
          formatDateAPI(rangeStart[0]),
          formatDateAPI(rangeStart[1]),
          AgeValues,
          lineage,
          TypeValue,
          AreaValues,
          OrgValue,
        );

        setDataCalendar(apiDaily.map(convertDataToChartCalendar));
      } catch (error) {
        console.error("Error loading daily API data:", error);
      }
    },
    [AgeValues, AreaValues, OrgValue, TypeValue, selectedRange],
  );

  const loadDataApi = useCallback(async () => {
    if (dataFilter === null) {
      try {
        const apiFilterType = await getDataFilterType(
          global.start_date_year,
          maxSelect,
          AgeValues,
          TypeValue,
          AreaValues,
          OrgValue,
        );
        CheckApiDown(apiFilterType, setApiCheck);
        setDataFilter(apiFilterType);
        setOpenSnackbar(true);
        setRangeShow(selectedRange); // load first
      } catch (error) {
        console.error("Error loading daily API data:", error);
      }
    }
    if (dataLineage !== null) {
      if (
        AgeValues.length !== 0 &&
        dataLineage.length !== 0 &&
        TypeValue.length !== 0 &&
        AreaValues.length !== 0 &&
        OrgValue.length !== 0
      ) {
        enableDisableButton(document.getElementById("search"), true);
        if (
          btnSearch === true ||
          minSelect !== minSelect_old ||
          maxSelect !== maxSelect_old
        ) {
          setFilterLoading(true);
          set_minSelected_old(minSelect);
          set_maxSelected_old(maxSelect);

          if (selectedYear !== YearOld) {
            setMinDate(setDateYear(selectedYear, "start"));
            setMaxDate(setDateYear(selectedYear, "end"));
            setRange([
              setDateYear(selectedYear, "start"),
              setDateYear(selectedYear, "end"),
            ]);
            // setSelectedRange([
            //   setDateYear(selectedYear, "start"),
            //   setDateYear(selectedYear, "end"),
            // ]);

            setYearOld(selectedYear);
          }
          try {
            // Fetch data concurrently (if possible)
            const [apiVillage, apiVillageSum, apiDaily] = await Promise.all([
              getDataVillage(
                minSelect,
                maxSelect,
                AgeValues,
                dataLineage,
                TypeValue,
                AreaValues,
                OrgValue,
              ),
              getDataVillageSum(
                minSelect,
                maxSelect,
                AgeValues,
                dataLineage,
                TypeValue,
                AreaValues,
                OrgValue,
              ),
              getDataChartDaily(
                minSelect,
                maxSelect,
                AgeValues,
                dataLineage,
                TypeValue,
                AreaValues,
                OrgValue,
              ),
            ]);

            setDataCalendar(apiDaily.map(convertDataToChartCalendar));
            CheckApiDown(apiVillageSum, setApiCheck);
            setCaseSum(Number(apiVillageSum[0].case_sum));
            CheckApiDown(apiVillage, setApiCheck);
            setDataVillage(
              convertDataVillageToGeoJSON(convertDataRank(apiVillage)),
            );
            setTop5(getTop5Villages(convertDataRank(apiVillage)));
            setYear(selectedYear);
            setBtnSearch(false);
            if (!loadApiLayerHouseHold.apiHouseHold) {
              setFilterLoading(false);
              setOpenSnackbar(true);
            }
          } catch (err) {
            console.log(err);
          }
        }
        setWarning(false);
      } else {
        setWarning(true);
        enableDisableButton(document.getElementById("search"), false);
      }
    }
    setLoading(false);
  }, [
    dataFilter,
    dataLineage,
    setLoading,
    maxSelect,
    AgeValues,
    TypeValue,
    AreaValues,
    OrgValue,
    setApiCheck,
    setRangeShow,
    selectedRange,
    btnSearch,
    minSelect,
    minSelect_old,
    maxSelect_old,
    selectedYear,
    YearOld,
    setRange,
    setCaseSum,
    setDataVillage,
    setTop5,
    setYear,
    loadApiLayerHouseHold.apiHouseHold,
  ]);

  useEffect(() => {
    const abortControl = new AbortController();
    if (dataFilter !== null && dataLineage === null) {
      loadDataApi(abortControl);
      const lineage = dataFilter.map((obj) => obj.result_code_detail).join(",");
      setDataLineage(lineage.split(","));
    }
  }, [dataFilter, dataLineage, loadDataApi]);

  useEffect(() => {
    const abortControl = new AbortController();
    loadDataApi(abortControl);

    return () => {
      abortControl.abort();
    };
  }, [loadDataApi]);

  const loadDataApiHouseHoldCallback = useCallback(() => {
    const abortControl = new AbortController();
    loadDataApiHouseHold(
      loadApiLayerHouseHold,
      selectedYear,
      YearOld,
      minSelect,
      minStart_filter,
      maxSelect,
      maxStart_filter,
      AgeValues,
      dataLineage,
      TypeValue,
      AreaValues,
      OrgValue,
      setDataHouseHold,
      setFilterLoading,
      setOpenSnackbar,
      set_minStart_filter,
      set_maxStart_filter,
      btnSearch,
    );
    return () => {
      abortControl.abort();
    };
  }, [
    loadApiLayerHouseHold,
    selectedYear,
    YearOld,
    minSelect,
    minStart_filter,
    maxSelect,
    maxStart_filter,
    AgeValues,
    dataLineage,
    TypeValue,
    AreaValues,
    OrgValue,
    setDataHouseHold,
    setFilterLoading,
    setOpenSnackbar,
    set_minStart_filter,
    set_maxStart_filter,
    btnSearch,
  ]);

  useEffect(() => {
    return loadDataApiHouseHoldCallback();
  }, [loadDataApiHouseHoldCallback]);

  const loadDataApiLayerCallback = useCallback(() => {
    const abortControl = new AbortController();
    if (stateGo) {
      loadDataApiLayer(
        loadApiLayers,
        selectedYear,
        YearOld,
        setLoadApiLayers,
        setDataSchool,
        setGeoPolygonA1,
        setGeoPolygonA2,
        setGeoPolygonB1,
        setGeoPolygonB2,
        setGeoPolygonNA,
        setSumA1,
        setSumA2,
        setSumB1,
        setSumB2,
        setSumNA,
        setFilterLoading,
      );
    }
    return () => {
      abortControl.abort();
    };
  }, [
    stateGo,
    loadApiLayers,
    selectedYear,
    YearOld,
    setLoadApiLayers,
    setDataSchool,
    setGeoPolygonA1,
    setGeoPolygonA2,
    setGeoPolygonB1,
    setGeoPolygonB2,
    setGeoPolygonNA,
    setSumA1,
    setSumA2,
    setSumB1,
    setSumB2,
    setSumNA,
  ]);

  useEffect(() => {
    return loadDataApiLayerCallback();
  }, [loadDataApiLayerCallback]);

  const handleToggle = () => {
    setShowFilter(!showFilter);
  };
  if (minStart > maxStart) {
    setMinStart(0);
    // set_minSelected(formatDateAPI(new Date(new Date().getFullYear(), 0, 1)));
    // set_minSelected((formatDateAPI(getDayAgo(new Date(), end_date))).toString())
  }
  useEffect(() => {
    let sortRange = sortDateApi(selectedRange);
    set_minSelected(formatDateAPI(sortRange[0]));
    set_maxSelected(formatDateAPI(sortRange[1]));

    set_minMonthCaption(formatDate(sortRange[0]));
    set_maxMonthCaption(formatDate(sortRange[1]));
  }, [loadApiDaily, selectedRange]);
  return (
    <div>
      <FilterMainComponent
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showFilter={showFilter}
        handleToggle={handleToggle}
        selectedYear={selectedYear}
        handleYearChange={handleYearChange}
        years={years}
        minMonthCaption={minMonthCaption}
        maxMonthCaption={maxMonthCaption}
        dataType={dataType}
        TypeValue={TypeValue}
        setTypeValue={setTypeValue}
        dataAge={dataAge}
        AgeValues={AgeValues}
        setAgeValues={setAgeValues}
        dataArea={dataArea}
        AreaValues={AreaValues}
        setAreaValues={setAreaValues}
        dataFilter={dataFilter}
        dataLineage={dataLineage}
        setDataLineage={setDataLineage}
        dataOrgType={dataOrgType}
        OrgValue={OrgValue}
        setOrgValue={setOrgValue}
        warning={warning}
        handleConfirm={handleConfirm}
        FilterLoading={FilterLoading}
        handleClose={handleClose}
        OpenSnackbar={OpenSnackbar}
        errLocation={errLocation}
        loadLocation={loadLocation}
      />
      {dataCalendar && (
        <div className="divCalendar">
          <NivoCalendar
            data={dataCalendar}
            minDate={minDate}
            maxDate={maxDate}
            year={selectedYear}
            set_minSelected={set_minSelected}
            set_maxSelected={set_maxSelected}
            setSelectedRange={setSelectedRange}
            setRangeShow={setRangeShow}
            setRange={setRange}
            range={range}
          />
        </div>
      )}
      <div className="LoadingProgress">
        {FilterLoading && (
          <Box sx={{ width: "100%", zIndex: 5, position: "sticky" }}>
            <LinearProgress />
          </Box>
        )}
      </div>
    </div>
  );
};

export default FilterCalendarComponent;
